import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  filterGetGrades,
  filterGetProfessions,
  filterGetTeams,
  filterSelectGrade,
  filterSelectGradeClear,
  filterSelectProfession,
  filterSelectProfessionClear,
  filterSelectTeam,
  filterSelectTeamClear,
} from "../../actions/reports/SelectPersonPanelAction";
import connect from "react-redux/es/connect/connect";
import {Collapse} from "reactstrap";

class SelectFilter extends React.Component {

  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  selectItem(event) {
    let id = event.currentTarget.value;
    let name = document.getElementById(event.currentTarget.value).getAttribute('name');

    this.props.select(id, name);
  }

  getItems() {
    let result = [<option key={-1} disabled value={-1}> -- фильтр не назначен -- </option>];

    result.push(this.props.list.map((el) => {
      return <option key={el.id} value={el.id} name={el.name} id={el.id}>
        {el.name}
      </option>
    }));

    return result;
  }

  render() {
    return (
      <div className={this.props.className}>
        <small>{this.props.filterName}</small>
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <button type="button" className="btn cl-btn-def-1" onClick={this.props.clear}>
              <FontAwesomeIcon icon={['far', "trash-alt"]}/>
            </button>
          </div>
          <select className="custom-select custom-select-sm cl-def-1"
                  value={this.props.selected ? this.props.selected.id : -1}
                  onChange={this.selectItem}>
            {this.getItems()}
          </select>
        </div>
      </div>
    )
  }
}

class SelectPersonFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.getFilterPills = this.getFilterPills.bind(this);
    this.refreshFilterItems = this.refreshFilterItems.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    if (!this.state.isOpen) {
      this.refreshFilterItems();
    }
  }

  getFilterPills() {
    const filters = [
      {selected: this.props.filter.team.selected, key: "_filter_11", type: "Команда: "},
      {selected: this.props.filter.profession.selected, key: "_filter_22", type: "Профессия: "},
      {selected: this.props.filter.grade.selected, key: "_filter_33", type: "Опыт: "},
    ];

    return filters.map((filter) => {
      if (!filter.selected) {
        return null;
      }

      return <span className="badge badge-pill badge-info" key={filter.key} style={{marginLeft: 5, marginRight: 5}}>
        {filter.type + " " + filter.selected.name}
      </span>
    })
  }

  refreshFilterItems() {
    this.props.filterGetTeamsAction();
    this.props.filterGetGradesAction();
    this.props.filterGetProfessionsAction();
  }

  componentDidMount() {
    this.refreshFilterItems();
  }

  render() {
    const {filter} = this.props;
    const filters = [
      {
        key: "_filter_2",
        filterName: "Фильтр по профессии",
        list: filter.profession.list,
        selected: filter.profession.selected,
        isFetching: filter.profession.isFetching,
        select: this.props.filterSelectProfAction,
        clear: this.props.filterSelectProfClearAction,
      },
      {
        key: "_filter_3",
        filterName: "Фильтр по опыту",
        list: filter.grade.list,
        selected: filter.grade.selected,
        isFetching: filter.grade.isFetching,
        select: this.props.filterSelectGradeAction,
        clear: this.props.filterSelectGradeClearAction,
      },
      {
        key: "_filter_1",
        filterName: "Фильтр по команде",
        list: filter.team.list,
        selected: filter.team.selected,
        isFetching: filter.team.isFetching,
        select: this.props.filterSelectTeamAction,
        clear: this.props.filterSelectTeamClearAction,
      },
    ];


    return (
      <div className="filter-panel">
        <div onClick={this.toggle}>
          <FontAwesomeIcon icon={`${this.state.isOpen ? "caret-up" : "caret-down"}`}/> Фильтры
          {this.getFilterPills()}
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {
            filters.map((filter) => {
              return <div className="container" key={filter.key}>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <SelectFilter className={"filter-element"} key={filter.key}
                                  filterName={filter.filterName}
                                  list={filter.list}
                                  isFetching={filter.isFetching}
                                  selected={filter.selected}
                                  getList={filter.getList}
                                  select={filter.select}
                                  clear={filter.clear}
                    />
                  </div>
                </div>
              </div>
            })
          }
        </Collapse>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    filter: store.select_person_panel.filter,
  }
};

const mapDispatchToProps = dispatch => {
  return {

    filterGetProfessionsAction: () => dispatch(filterGetProfessions()),
    filterGetTeamsAction: () => dispatch(filterGetTeams()),
    filterGetGradesAction: () => dispatch(filterGetGrades()),

    filterSelectProfAction: (id, name) => dispatch(filterSelectProfession(id, name)),
    filterSelectTeamAction: (id, name) => dispatch(filterSelectTeam(id, name)),
    filterSelectGradeAction: (id, name) => dispatch(filterSelectGrade(id, name)),

    filterSelectProfClearAction: () => dispatch(filterSelectProfessionClear()),
    filterSelectTeamClearAction: () => dispatch(filterSelectTeamClear()),
    filterSelectGradeClearAction: () => dispatch(filterSelectGradeClear()),

  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPersonFilters);