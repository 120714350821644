import {
  REPORT_BY_PERSON_ERROR,
  REPORT_BY_PERSON_REQUEST,
  REPORT_BY_PERSON_SUCCESS,
  REPORT_BY_PERSON_TOGGLE_PERSON,
  REPORT_ERROR,
  REPORT_ERROR_CLEAR,
  REPORT_IS_BUILT
} from "../../actions/reports/CompetenceMapAction";

export const initialState = {
  isBuilt: false,
  isFetching: false,

  categories: [],
  series: [],
  data: [],

  selected: [],
  error: null,
  message: null,
};

export function competenceMapReducer(state = initialState, action) {

  switch (action.type) {
    case REPORT_BY_PERSON_REQUEST:
      return {...state, error: false, isFetching: true,};
    case REPORT_BY_PERSON_SUCCESS:
      return {
        ...state, error: false, isFetching: false,
        categories: action.payload.categories,
        series: action.payload.series,
        data: action.payload.data,
      };
    case REPORT_BY_PERSON_ERROR:
      return {...state, error: action.payload, isFetching: false,};

    case REPORT_ERROR:
      return {...state, error: action.payload, isFetching: false,};
    case REPORT_ERROR_CLEAR:
      return {...state, error: null};

    case REPORT_IS_BUILT:
      return {...state, isBuilt: action.payload};

    case REPORT_BY_PERSON_TOGGLE_PERSON:
      return {
        ...state, selected: (function () {
          return (
            state.selected.includes(action.payload) ? [] : [action.payload]
          );
        })(),
      };

    default:
      return state;
  }

}