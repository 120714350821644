import React, {Component} from "react";
import Spinner from "../commons/Spinner";

import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {changeEmail, changePassword, clearError, loginAction, logoutAction} from "../actions/LoginAction";
import {COOKIE_PERSON_ID, COOKIE_TOKEN, COOKIE_USER_NAME, getCookie, post} from "../commons/Commons";
import {CHECK_AUTH_URL} from "../commons/Routing";
import {DEFAULT_SERVER_ERROR_MESSAGE} from "../reducers/MainReducer";
import {authLogin, setRedirectAfterLogin} from "../actions/AuthAction";
import {withCookies} from "react-cookie";
import {setMainError} from "../actions/MainAction";

class Login extends Component {

  constructor(props) {
    super(props);

    this.loginSuccess = this.loginSuccess.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.showModalErrorHandler = this.showModalErrorHandler.bind(this);
  }

  showModalErrorHandler(msg) {
    msg = msg || DEFAULT_SERVER_ERROR_MESSAGE;
    this.props.setMainErrorAction(true, msg)
  }

  keyPressHandler(e) {
    if (e.key === 'Enter') {
      this.loginHandler()
    }
  };

  loginHandler() {
    this.props.loginAction(this.props.login.email, this.props.login.password, this.loginSuccess);
  };

  loginSuccess() {
    if (this.props.auth.redirect) {
      this.props.history.push(this.props.auth.redirect);
    }
    else {
      this.props.history.push("/");
    }
  };

  checkLogin() {
    let t = getCookie(COOKIE_TOKEN);
    let p = getCookie(COOKIE_PERSON_ID);
    let e = getCookie(COOKIE_USER_NAME);

    if (!(t && p)) {
      this.props.logoutAction();
    }
    else {
      post(CHECK_AUTH_URL, {person_id: p})
        .done((d) => {
          if (d.status === 'ok') {
            //
            this.props.authLoginAction({email: e, id: p});
            //
            let redirect = this.props.auth.redirect;
            if (redirect) {
              //
              this.props.clearRedirectAfterLoginAction();
              this.props.history.push(redirect);
            }
          }
          else {
            this.props.logoutAction();
          }
        })
        .fail((jqXHR) => {
          if (jqXHR.status > 0) {
            this.props.logoutAction();
          }
          else {
            this.showModalErrorHandler(jqXHR.responseText || DEFAULT_SERVER_ERROR_MESSAGE);
          }
        })
    }
  }

  componentDidMount() {
    this.checkLogin();
  }

  render() {

    const {login} = this.props;

    return (
      <div>
        <div className="row justify-content-center">
          <form className="login-form col-12 col-md-4">
            {/*email*/}
            <div className="form-group">
              <label>Имя пользователя</label>
              <input className={`form-control ${login.error.message || login.error.email ? "is-invalid" : ""}`}
                     name="email"
                     autoComplete="username"
                     disabled={login.isFetching}
                     value={login.email}
                     onKeyPress={this.keyPressHandler}
                     onClick={this.props.loginClearErrorAction}
                     onChange={(e) => this.props.loginChangeEmailAction(e.currentTarget.value)}
              />
            </div>
            <div id="error-email" className="alert alert-danger" hidden={!login.error.email}>
              {login.error.email}
            </div>
            {/*password*/}
            <div className="form-group">
              <label>Пароль</label>
              <input className={`form-control ${login.error.message || login.error.password ? "is-invalid" : ""}`}
                     type="password"
                     name="password"
                     autoComplete="current-password"
                     disabled={login.isFetching}
                     value={login.password}
                     onKeyPress={this.keyPressHandler}
                     onClick={this.props.loginClearErrorAction}
                     onChange={(e) => this.props.loginChangePasswordAction(e.currentTarget.value)}
              />
            </div>
            <div id="error-password" className="alert alert-danger" hidden={!login.error.password}>
              {login.error.password}
            </div>
            <div className="d-flex justify-content-end">
              {
                login.isFetching ?
                  <Spinner/> :
                  <button
                    type="button"
                    className="btn btn-info cl-btn-def-1"
                    onClick={this.loginHandler}>
                    Войти
                  </button>
              }
            </div>
          </form>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4">
            <div id="error-info" className="login-form alert alert-danger" hidden={!login.error.message}>
              {login.error.message}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    auth: store.auth,

    login: store.login,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setMainErrorAction: (show, message) => dispatch(setMainError(show, message)),

    loginAction: (e, p, loginCallback) => dispatch(loginAction(e, p, loginCallback)),

    loginClearErrorAction: () => dispatch(clearError()),
    loginChangeEmailAction: (v) => dispatch(changeEmail(v)),
    loginChangePasswordAction: (v) => dispatch(changePassword(v)),

    authLoginAction: (d) => dispatch(authLogin(d)),
    logoutAction: () => dispatch(logoutAction()),

    clearRedirectAfterLoginAction: () => dispatch(setRedirectAfterLogin(null)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withCookies(Login)));