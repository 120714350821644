import {post} from "../commons/Commons";
import {CORP_INVITE_CHECK_EMAIL, CORP_INVITE_PERSON_URL, CORP_PERSON_REMOVE_FROM_COMPANY, CORP_PERSONS_LIST_URL} from "../commons/Routing";

export const GET_PERSONS_REQUEST = "GET_PERSONS_REQUEST";
export const GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS";
export const GET_PERSONS_ERROR = "GET_PERSONS_ERROR";

export const INVITE_CHANGE_EMAIL = "INVITE_CHANGE_EMAIL";
export const INVITE_TOGGLE_FORM = "INVITE_TOGGLE_FORM";

export const INVITE_CHANGE_PERSON_ID = "INVITE_CHANGE_PERSON_ID";

export const INVITE_CHECK_EMAIL_REQUEST = "INVITE_CHECK_EMAIL_REQUEST";
export const INVITE_CHECK_EMAIL_SUCCESS = "INVITE_CHECK_EMAIL_SUCCESS";
export const INVITE_CHECK_EMAIL_ERROR = "INVITE_CHECK_EMAIL_ERROR";

export const INVITE_PERSON_REQUEST = "INVITE_PERSON_REQUEST";
export const INVITE_PERSON_SUCCESS = "INVITE_PERSON_SUCCESS";
export const INVITE_PERSON_ERROR = "INVITE_PERSON_ERROR";

export const REMOVE_FROM_COMPANY_REQUEST = 'REMOVE_FROM_COMPANY_REQUEST';
export const REMOVE_FROM_COMPANY_SUCCESS = 'REMOVE_FROM_COMPANY_SUCCESS';
export const REMOVE_FROM_COMPANY_ERROR = 'REMOVE_FROM_COMPANY_ERROR';

export const REMOVE_FROM_COMPANY_TOGGLE = 'REMOVE_FROM_COMPANY_TOGGLE';

const DEFAULT_PAGE_LIMIT = 15;

export function inviteCheckEmail(email) {
  return dispatch => {
    dispatch({
      type: INVITE_CHECK_EMAIL_REQUEST,
      payload: null
    });

    post(CORP_INVITE_CHECK_EMAIL, {email: email})
      .done((d) => {
        if (d.status === 'ok') {
          dispatch({
            type: INVITE_CHANGE_PERSON_ID,
            payload: d.data
          });

          dispatch({
            type: INVITE_CHECK_EMAIL_SUCCESS,
            payload: null
          })
        } else {
          dispatch({
            type: INVITE_CHECK_EMAIL_ERROR,
            payload: d.message
          });
          dispatch({
            type: INVITE_CHANGE_PERSON_ID,
            payload: null
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: INVITE_CHECK_EMAIL_ERROR,
          payload: error || 'Ошибка на стороне сервера'
        });
        dispatch({
          type: INVITE_CHANGE_PERSON_ID,
          payload: null
        });
      })
  }
}

export function inviteChangeEmail(val) {
  return {
    type: INVITE_CHANGE_EMAIL,
    payload: val,
  }
}

export function toggleInviteForm() {
  return {
    type: INVITE_TOGGLE_FORM,
    payload: null,
  }
}

export function toggleRemovePerson(data) {
  return {
    type: REMOVE_FROM_COMPANY_TOGGLE,
    payload: data,
  }
}

export function getPersons(page) {
  return (dispatch, getStore) => {

    dispatch({
      type: GET_PERSONS_REQUEST,
      payload: null,
    });

    page = page || getStore().person.person.page;

    post(CORP_PERSONS_LIST_URL, {page: page, limit: DEFAULT_PAGE_LIMIT})
      .done((d) => {
        dispatch({
          type: GET_PERSONS_SUCCESS,
          payload: d.data,
        })
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: GET_PERSONS_ERROR,
          payload: error,
        })
      })
  }
}

export function invitePerson() {
  return (dispatch, getStore) => {

    dispatch({
      type: INVITE_PERSON_REQUEST,
      payload: null
    });

    post(CORP_INVITE_PERSON_URL, {email: getStore().person.invite.email})
      .done((d) => {
        if (d.status === 'ok') {

          dispatch(getPersons());

          dispatch({
            type: INVITE_PERSON_SUCCESS,
            payload: d.message,
          })
        } else {
          dispatch({
            type: INVITE_PERSON_ERROR,
            payload: d.message,
          })
        }
      })
      .fail((jqXHR) => {

        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: INVITE_PERSON_ERROR,
          payload: error,
        })
      })
  }
}

export function inviteChangePersonId(val) {
  return {
    type: INVITE_CHANGE_PERSON_ID,
    payload: val,
  }
}

export function removePersonFromCompany() {

  return (dispatch, getStore) => {

    dispatch({
      type: REMOVE_FROM_COMPANY_REQUEST,
      payload: null
    });

    post(CORP_PERSON_REMOVE_FROM_COMPANY, {person_id: getStore().person.remove.person_id})
      .done((d) => {
        if (d.status === 'ok') {

          dispatch(getPersons());

          dispatch({
            type: REMOVE_FROM_COMPANY_SUCCESS,
            payload: d.message,
          })
        } else {
          dispatch({
            type: REMOVE_FROM_COMPANY_ERROR,
            payload: d.message,
          })
        }
      })
      .fail((jqXHR) => {

        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: REMOVE_FROM_COMPANY_ERROR,
          payload: error,
        })
      })
  }
}