import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Spinner extends React.Component {

  render() {
    return (
      <span>
        <FontAwesomeIcon icon="spinner" className="spin cl-def-1"/>
      </span>
    )
  }
}


export default Spinner;