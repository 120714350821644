import {
  REPORT_BY_TEAM_GROUP_REQUEST,
  REPORT_BY_TEAM_GROUP_SUCCESS,
  REPORT_BY_TEAM_GROUP_ERROR,
  REPORT_BY_TEAM_GROUP_IS_BUILT,
  REPORT_BY_TEAM_GROUP_TOGGLE_PERSON, REPORT_BY_TEAM_GROUP_PUSH_PERSONS,
} from "../../actions/reports/TeamCompetenceAction";

export const initialState = {
  isBuilt: false,
  isFetching: false,

  selected: [],

  categories: [],
  series: [],
  data: [],

  error: null,
  message: null
};


export function teamCompetenceReducer(state = initialState, action) {

  switch (action.type) {
    case REPORT_BY_TEAM_GROUP_REQUEST:
      return {...state, isFetching: true, isBuilt: false, error: null,};
    case REPORT_BY_TEAM_GROUP_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        series: action.payload.series,
        data: action.payload.data,
        isFetching: false,
        isBuilt: true,
        error: null,
      };
    case REPORT_BY_TEAM_GROUP_ERROR:
      return {...state, isFetching: false, isBuilt: false, error: action.payload};

    case REPORT_BY_TEAM_GROUP_IS_BUILT:
      return {...state, isBuilt: action.payload, error: null,};

    case REPORT_BY_TEAM_GROUP_TOGGLE_PERSON:
      return {
        ...state, selected: (function () {
          return (
            state.selected.includes(action.payload) ?
              (function () {
                return state.selected.filter((el) => el !== action.payload)
              })() :
              (function () {
                let n = state.selected.slice();
                n.push(action.payload);
                return n
              })()
          );
        })(),
      };

    case REPORT_BY_TEAM_GROUP_PUSH_PERSONS:
      return {
        ...state, selected: action.payload
      };

    default:
      return state;
  }
}