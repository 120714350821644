import {get} from "../commons/Commons";
import {CORP_INVITES_LIST_URL} from "../commons/Routing";

export const GET_INVITES_REQUEST = "GET_INVITES_REQUEST";
export const GET_INVITES_SUCCESS = "GET_INVITES_SUCCESS";
export const GET_INVITES_ERROR = "GET_INVITES_ERROR";

export function getInvites() {

  return dispatch => {

    dispatch({
      type: GET_INVITES_REQUEST,
      payload: null,
    });


    get(CORP_INVITES_LIST_URL)
      .done((d) => {
        dispatch({
          type: GET_INVITES_SUCCESS,
          payload: d.results,
        });
      })
      .fail((jqXHR) => {

        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: GET_INVITES_ERROR,
          payload: error,
        });
      })

  }
}