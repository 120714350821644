import {
  BILLING_REPORTS_ERROR,
  BILLING_REPORTS_REQUEST,
  BILLING_REPORTS_SUCCESS,
  MAIN_PROFESSIONS_LIST_SUCCESS,
  MAIN_GRADES_LIST_SUCCESS,
  SET_ERROR, MAIN_COMPETENCES_LIST_SUCCESS,
} from "../actions/MainAction";

export const DEFAULT_SERVER_ERROR_MESSAGE = "Ошибка при подключении к серверу";

const grade_required = [
  {
    id: 0,
    name: 'EMPTY',
    description: 'Не указано',
  },
  {
    id: 1,
    name: 'REQUIRED',
    description: 'Обязательно',
  },
  {
    id: 2,
    name: 'DESIRABLE',
    description: 'Желательно',
  },
];

export const initialState = {
  global_error: {
    show: false,
    message: null
  },
  notification: {
    invites: 0,
  },

  billing: {
    reports: {
      list: [],
      error: null,
      is_fetching: false,
      is_loaded: false
    }
  },

  professions: {
    list: [],
    isFetching: false
  },

  grades: {
    list: [],
    isFetching: false
  },

  competences: {
    list: [],
    isFetching: false
  },

  grade_required: grade_required
};

export function mainReducer(state = initialState, action) {

  switch (action.type) {
    case MAIN_COMPETENCES_LIST_SUCCESS:
      return {...state, competences: {...state.competences, list: action.payload}};

    case MAIN_PROFESSIONS_LIST_SUCCESS:
      return {...state, professions: {...state.professions, list: action.payload}};

    case MAIN_GRADES_LIST_SUCCESS:
      return {...state, grades: {...state.grades, list: action.payload}};

    case SET_ERROR:
      return {...state, global_error: action.payload};

    case BILLING_REPORTS_REQUEST:
      return {
        ...state, billing: {...state.billing, reports: {...state.billing.reports, is_fetching: true, error: null}}
      };
    case BILLING_REPORTS_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing, reports: {...state.billing.reports, is_fetching: false, error: null, list: action.payload, is_loaded: true }
        }
      };
    case BILLING_REPORTS_ERROR:
      return {
        ...state,
        billing: {...state.billing, reports: {...state.billing.reports, is_fetching: false, error: action.payload, is_loaded: false}}
      };
    default:
      return state;
  }
}