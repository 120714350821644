import {COOKIE_PERSON_ID, COOKIE_TOKEN, COOKIE_USER_NAME, COOKIE_COMPANY_ID, post, removeCookie, setCookie} from "../commons/Commons";
import {LOGIN_URL} from "../commons/Routing";
import {DEFAULT_SERVER_ERROR_MESSAGE} from "../reducers/MainReducer";
import {AUTH_LOGOUT, authLogin} from "./AuthAction";

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGIN_CLEAR_ERROR = 'LOGIN_CLEAR_ERROR';
export const LOGIN_CHANGE_EMAIL = 'LOGIN_CHANGE_EMAIL';
export const LOGIN_CHANGE_PASSWORD = 'LOGIN_CHANGE_PASSWORD';

export function clearError() {
  return {
    type: LOGIN_CLEAR_ERROR,
    payload: null,
  }
}

export function changeEmail(val) {
  return {
    type: LOGIN_CHANGE_EMAIL,
    payload: val,
  }
}

export function changePassword(val) {
  return {
    type: LOGIN_CHANGE_PASSWORD,
    payload: val,
  }
}

export function loginAction(email, password, redirectOnSuccess) {

  return dispatch => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: null,
    });

    post(LOGIN_URL, {login: email, email: email, password: password,}, null, null, false)
      .done((d) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: null,
        });

        setCookie(COOKIE_TOKEN, d.token);
        setCookie(COOKIE_PERSON_ID, d.user.person);
        setCookie(COOKIE_USER_NAME, d.user.email);
        setCookie(COOKIE_COMPANY_ID, d.user.company_id);

        dispatch(authLogin({email: d.user.email, id: d.user.person}));

        redirectOnSuccess();
      })
      .fail((jqXHR) => {

        let errors = {
          password: null,
          email: null,
          message: null,
        };

        if (jqXHR.statusCode().status === 0) {
          errors.message = DEFAULT_SERVER_ERROR_MESSAGE;
        }

        if (jqXHR.responseJSON && jqXHR.responseJSON.email) {
          errors.email = jqXHR.responseJSON.email.join("\n");
        }

        if (jqXHR.responseJSON && jqXHR.responseJSON.password) {
          errors.password = jqXHR.responseJSON.password.join("\n");
        }

        if (jqXHR.responseJSON && jqXHR.responseJSON.non_field_errors) {
          errors.message = jqXHR.responseJSON.non_field_errors.join("\n");
        }
        else if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          errors.message = Array.isArray(jqXHR.responseJSON.detail) ? jqXHR.responseJSON.detail.join("\n") : jqXHR.responseJSON.detail;
        }
        else if (!jqXHR.responseJSON) {
          errors.message = (jqXHR.responseText || "").substr(0, 150);
        }

        dispatch({
          type: LOGIN_ERROR,
          payload: errors,
        });

      })
  }
}

export function logoutAction() {

  return dispatch => {

    (function () {
      removeCookie(COOKIE_TOKEN);
      removeCookie(COOKIE_PERSON_ID);
      removeCookie(COOKIE_USER_NAME);
      removeCookie(COOKIE_COMPANY_ID);
    })();

    //
    dispatch({
      type: AUTH_LOGOUT,
      payload: null
    });

    //
    dispatch({
      type: LOGOUT_REQUEST,
      payload: null,
    });

  }

}