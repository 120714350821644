import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

let fillChartSeries = function (series, assessment) {
  switch (assessment) {
    case 0.0:
      series["20"].push(0);
      series["40"].push(0);
      series["60"].push(0);
      series["80"].push(0);
      series["100"].push(0);
      break;
    case 0.2:
      series["20"].push(2);
      series["40"].push(0);
      series["60"].push(0);
      series["80"].push(0);
      series["100"].push(0);
      break;
    case 0.4:
      series["20"].push(2);
      series["40"].push(2);
      series["60"].push(0);
      series["80"].push(0);
      series["100"].push(0);
      break;
    case 0.6:
      series["20"].push(2);
      series["40"].push(2);
      series["60"].push(2);
      series["80"].push(0);
      series["100"].push(0);
      break;
    case 0.8:
      series["20"].push(2);
      series["40"].push(2);
      series["60"].push(2);
      series["80"].push(2);
      series["100"].push(0);
      break;
    case 1:
      series["20"].push(2);
      series["40"].push(2);
      series["60"].push(2);
      series["80"].push(2);
      series["100"].push(2);
      break;

    default:
      series["20"].push(0);
      series["40"].push(0);
      series["60"].push(0);
      series["80"].push(0);
      series["100"].push(0);
      break;
  }

  return series;
};


class SkillsChart extends React.Component {
  constructor(props) {
    super(props);

    SkillsChart.getDefOptions = SkillsChart.getDefOptions.bind(this);
    this.getChartOptions = this.getChartOptions.bind(this);
  }

  static getDefOptions() {
    return {
      chart: {
        type: 'bar',
        height: 55
      },
      title: {
        text: null,
      },
      xAxis: {
        labels: {
          style: {
            "fontSize": "14",
            "fontFamily": "Rubik",
            "fontWeight": '500',
            color: "#2e3192",
            width: '150px',
            whiteSpace: 'normal'//set to normal
          },
          useHTML: true,//set to true
          step: 1,
          formatter: function () {//use formatter
            return '<div align="left" style="word-wrap: break-word; word-break: break-word; width:150px">' + this.value + '</div>';
          }
        }
      },
      yAxis: {
        min: 0,
        max: 10,
        title: {
          text: null,
        },
        labels: {
          enabled: false,
          step: 2,
        }
      },
      legend: {
        reversed: true,
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      tooltip: {
        enabled: false
      },
      series: [{
        name: '100',
        data: []
      }, {
        name: '80',
        data: []
      }, {
        name: '60',
        data: []
      }, {
        name: '40',
        data: []
      }, {
        name: '20',
        data: []
      }],
      credits: {enabled: false},
      exporting: {enabled: false},
      colors: ['green', 'lime', 'yellow', 'orange', 'red']
    }
  }

  getChartOptions() {
    let opts = SkillsChart.getDefOptions();

    let categories = [this.props.name + "  " + this.props.percent + "%"];
    let series = {
      20: [],
      40: [],
      60: [],
      80: [],
      100: [],
    };

    fillChartSeries(series, this.props.assessment);

    opts.xAxis.categories = categories;
    opts.series = [
      {
        name: '100',
        data: series["100"]
      }, {
        name: '80',
        data: series["80"]
      }, {
        name: '60',
        data: series["60"]
      }, {
        name: '40',
        data: series["40"]
      }, {
        name: '20',
        data: series["20"]
      }
    ];

    return opts;
  };

  render() {
    return (
      <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} oneToOne={true}/>
    )
  }
}

export default SkillsChart;