import React from "react";
import {Collapse, Table} from "reactstrap";

import "./SelectPersonPanel.css"
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getPersons} from "../../actions/reports/SelectPersonPanelAction";
import PaginationNav from "../../components/PaginationNav";
import ProgressLine from "../../commons/ProgressLine";
import SelectPersonFilters from "./SelectPersonFilters";

class PersonTeamRow extends React.Component {
  render() {
    if (!this.props.data.id) {
      return null;
    }

    return (
      <span style={{margin: "0 5px 0 5px"}}>
        {this.props.data.name + ";"}
      </span>
    )
  }
}

class SelectPersonPanel extends React.Component {

  constructor(props) {
    super(props);

    this.getPersonsRows = this.getPersonsRows.bind(this);
    this.togglePerson = this.togglePerson.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);

    this.only_one = [undefined, null].includes(props.only_one) ? false : props.only_one;
  }

  togglePerson(id) {
    if (id) {
      this.props.togglePersonHandler(id)
    }
  }

  onClickHandler(e) {
    this.togglePerson(e.currentTarget.id)
  }

  getPersonsRows() {
    return this.props.panel.list.map((el) => {
      return <tr key={el.id} id={el.id}
                 onClick={el.profession_id ? this.onClickHandler : null}
                 className={`${!el.profession_id ? "text-black-50" : ""} ${this.props.selected.includes(el.id) ? "bg-light" : ""}`}
      >
        <td>
          {
            (this.props.selected.includes(el.id)) ?
              (this.only_one ? <FontAwesomeIcon icon={['far', "dot-circle"]}/> : <FontAwesomeIcon icon={['far', "check-square"]}/>) :
              (this.only_one ? <FontAwesomeIcon icon={['far', "circle"]}/> : <FontAwesomeIcon icon={['far', "square"]}/>)
          }
        </td>
        <td>{"" + el.last_name + " " + el.first_name}</td>
        <td>{el.email}</td>
        <td>{el.profession_name}</td>
        <td>{el.grade_name}</td>
        <td>
          {
            el.teams.map(function (_el) {
              return <PersonTeamRow key={_el.id} data={_el}/>
            })
          }
        </td>
      </tr>
    })
  }

  render() {

    const {panel} = this.props;

    return (
      <Collapse isOpen={this.props.isOpen}>
        <div className="main-panel">
          <h5 className="text-center">
            Отметьте сотрудников для отчета
          </h5>

          {
            panel.error ?
              <div className="bg-danger text-white">{panel.error || "Ошибка при формировании отчета"}</div>
              : null
          }

          {/**/}
          <SelectPersonFilters/>

          {/**/}
          <ProgressLine height={"2px"} hidden={!panel.isFetching}/>

          <Table size="sm" borderless hover responsive>
            <thead>
            <tr>
              <th/>
              <th>Фамилия Имя</th>
              <th>email</th>
              <th>Профессия</th>
              <th>Опыт</th>
              <th>Команды</th>
            </tr>
            </thead>
            <tbody>
            {this.getPersonsRows()}
            </tbody>
          </Table>

          {/**/}
          <PaginationNav count={panel.count} page={panel.page} getPage={this.props.getPersonsAction}/>
        </div>
      </Collapse>
    )
  }

}

const mapStateToProps = store => {
  return {
    panel: store.select_person_panel,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getPersonsAction: (page) => dispatch(getPersons(page)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPersonPanel);