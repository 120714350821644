import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";

import './TeamPanel.css'
import {addTeamOpen, getTeams} from "../../actions/TeamPanelAction";
import connect from "react-redux/es/connect/connect";
import PersonInfoModalForm from "../PersonInfoModalForm";
import AddMemberDialog from "./AddMemberDialog";
import RemoveTeamDialog from "./RemoveTeamDialog";
import AddTeamDialog from "./AddEditTeamDialog";
import TeamComponent from "./TeamComponent";
import ProgressLine from "../../commons/ProgressLine";

class TeamPanel extends React.Component {

  constructor(props) {
    super(props);

    this.loadTeamTable = this.loadTeamTable.bind(this);
  }

  loadTeamTable() {
    let result = [];
    for (const t of this.props.team.list) {
      result.push(<TeamComponent key={t.id} teamId={t.id}/>)
    }
    return result;
  }

  componentDidMount() {
    this.props.getTeamsAction();
  }

  render() {
    const {team} = this.props;

    return (
      <div className="dashboard-panel">
        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>Команды</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button title="Добавить команду" className="cl-btn-def-1" onClick={this.props.addTeamOpenAction}>
                <FontAwesomeIcon icon="plus"/> Добавить команду
              </Button>
            </div>
          </div>
        </div>
        <ProgressLine height={"2px"} hidden={!team.isFetching}/>
        <div className="bg-danger text-white" hidden={!team.error}>{team.error}</div>

        {/**/}
        <PersonInfoModalForm/>

        {/**/}
        <AddTeamDialog/>

        {/**/}
        <RemoveTeamDialog/>

        {/**/}
        <AddMemberDialog/>

        {/**/}
        {this.loadTeamTable()}

      </div>
    )
  }
}

// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    team: store.team,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTeamsAction: () => dispatch(getTeams()),
    addTeamOpenAction: (id) => dispatch(addTeamOpen(id)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamPanel);
