import {
  GET_PERSONS_ERROR,
  GET_PERSONS_REQUEST,
  GET_PERSONS_SUCCESS,
  INVITE_CHANGE_EMAIL,
  INVITE_CHANGE_PERSON_ID,
  INVITE_CHECK_EMAIL_ERROR,
  INVITE_CHECK_EMAIL_REQUEST,
  INVITE_CHECK_EMAIL_SUCCESS,
  INVITE_PERSON_ERROR,
  INVITE_PERSON_REQUEST,
  INVITE_PERSON_SUCCESS,
  INVITE_TOGGLE_FORM,
  REMOVE_FROM_COMPANY_ERROR,
  REMOVE_FROM_COMPANY_REQUEST,
  REMOVE_FROM_COMPANY_SUCCESS, REMOVE_FROM_COMPANY_TOGGLE
} from "../actions/PersonPanelAction";

export const initialState = {
  error: null,
  message: null,
  person: {
    list: [],
    count: 0,
    page: 1,
    isFetching: false,
  },
  remove: {
    toggle: false,
    name: null,
    person_id: null
  },
  invite: {
    person_id: "",
    email: "",
    toggle: false,
    isFetching: false,
    check: {
      status: true,
      message: null
    },
  }
};

export function personPanelReducer(state = initialState, action) {

  switch (action.type) {
    case GET_PERSONS_REQUEST:
      return {...state, person: {...state.person, isFetching: true}, error: null};
    case GET_PERSONS_SUCCESS:
      return {
        ...state, error: null,
        person: {...state.person, isFetching: false, list: action.payload.list, count: action.payload.count, page: action.payload.page,},
      };
    case GET_PERSONS_ERROR:
      return {...state, error: null, person: {...state.person, isFetching: false}};
    case REMOVE_FROM_COMPANY_TOGGLE:
      return {
        ...state, error: null, remove: {
          ...state.remove, toggle: !state.remove.toggle,
          person_id: action.payload ? action.payload.person_id : null,
          name: action.payload ? action.payload.name : null,
        }
      };

    case INVITE_CHANGE_PERSON_ID:
      return {...state, error: null, message: null, invite: {...state.invite, isFetching: false, person_id: action.payload}};
    case INVITE_CHANGE_EMAIL:
      return {...state, error: null, message: null, invite: {...state.invite, email: action.payload, isFetching: false}};
    case INVITE_TOGGLE_FORM:
      return {...state, error: null, message: null, invite: {...state.invite, toggle: !state.invite.toggle, email: "", isFetching: false}};

    case INVITE_PERSON_REQUEST:
      return {...state, error: null, message: null, invite: {...state.invite, isFetching: true}};
    case INVITE_PERSON_SUCCESS:
      return {...state, error: null, message: action.payload, invite: {...state.invite, isFetching: false, person_id: "", email: ""}};
    case INVITE_PERSON_ERROR:
      return {...state, error: action.payload, message: null, invite: {...state.invite, isFetching: false}};

    case INVITE_CHECK_EMAIL_REQUEST:
      return {...state, invite: {...state.invite, check: {...state.invite.check, status: true, message: null}}};
    case INVITE_CHECK_EMAIL_SUCCESS:
      return {...state, invite: {...state.invite, check: {...state.invite.check, status: true, message: null}}};
    case INVITE_CHECK_EMAIL_ERROR:
      return {...state, invite: {...state.invite, check: {...state.invite.check, status: false, message: action.payload}}};

    case REMOVE_FROM_COMPANY_REQUEST:
      return {...state, error: null, message: null, person: {...state.person, isFetching: true}};
    case REMOVE_FROM_COMPANY_SUCCESS:
      return {...state, error: null, message: action.payload, person: {...state.person, isFetching: false}};
    case REMOVE_FROM_COMPANY_ERROR:
      return {...state, error: action.payload, message: null, person: {...state.person, isFetching: false}};

    default:
      return state;
  }
}