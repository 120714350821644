import React from "react";
import {Button, ButtonGroup} from "reactstrap";

class PaginationNav extends React.Component {

  constructor(props) {
    super(props);

    this.getButtons = this.getButtons.bind(this);
    this.pageButtonClickHandler = this.pageButtonClickHandler.bind(this);
  }

  pageButtonClickHandler(event) {
    this.props.getPage(event.target.attributes.getNamedItem('page').value)
  }

  getButtons() {
    let res = [];
    res.push(
      <Button key={-1} page={this.props.page - 1}
              className={"cl-btn-def-1"}
              outline={true}
              color="secondary"
              disabled={this.props.page === 1}
              onClick={this.pageButtonClickHandler}
      >
        {"<<"}
      </Button>
    );

    for (let i = 1; i <= this.props.count; i++) {
      res.push(
        <Button key={i} page={i}
                className={"cl-btn-def-1"}
                outline={true}
                color="secondary"
                active={i === this.props.page}
                onClick={this.pageButtonClickHandler}
        >
          {i}
        </Button>
      )
    }

    res.push(
      <Button key={-2} page={this.props.page + 1}
              className={"cl-btn-def-1"}
              outline={true}
              color="secondary"
              disabled={this.props.page === this.props.count}
              onClick={this.pageButtonClickHandler}
      >
        {">>"}
      </Button>
    );

    return res;
  }

  render() {
    return (
      <div className="pagination-panel">
        <ButtonGroup size={"sm"} className="justify-content-center">
          {
            this.getButtons()
          }
        </ButtonGroup>
      </div>
    )
  }
}


export default PaginationNav;