import {get, post} from "../commons/Commons";
import {
  CORP_PERSON_BILLING_REPORTS,
  PROFESSIONS_LIST_URL,
  CORP_GRADES_URL, CORP_COMPETENCES_URL,
} from "../commons/Routing";

export const SET_ERROR = "SET_ERROR";
export const BILLING_REPORTS_REQUEST = 'BILLING_REPORTS_REQUEST';
export const BILLING_REPORTS_SUCCESS = 'BILLING_REPORTS_SUCCESS';
export const BILLING_REPORTS_ERROR = 'BILLING_REPORTS_ERROR';

export const MAIN_PROFESSIONS_LIST_SUCCESS = 'MAIN_PROFESSIONS_LIST_SUCCESS';
export const MAIN_GRADES_LIST_SUCCESS = 'MAIN_GRADES_LIST_SUCCESS';
export const MAIN_COMPETENCES_LIST_SUCCESS = 'MAIN_COMPETENCES_LIST_SUCCESS';

export function loadMainCompetences() {
  return dispatch => {
    get(CORP_COMPETENCES_URL)
      .done((data) => {
        dispatch({
          type: MAIN_COMPETENCES_LIST_SUCCESS,
          payload: data.results
        })
      })
  }
}

export function setMainError(show, message) {
  return {
    type: SET_ERROR,
    payload: {show: show, message: message},
  }
}

export function getBillingReports() {
  return dispatch => {
    dispatch({
      type: BILLING_REPORTS_REQUEST,
      payload: null
    });

    post(CORP_PERSON_BILLING_REPORTS)
      .done((d) => {
        if (d.status === 'ok') {

          let reps = new Object();
          d.data.forEach((e, i) => {
            reps[e.method_name] = {name: e.name, id: e.id}
          });

          dispatch({
            type: BILLING_REPORTS_SUCCESS,
            payload: reps
          })
        } else {
          dispatch({
            type: BILLING_REPORTS_ERROR,
            payload: d.message
          })
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: BILLING_REPORTS_ERROR,
          payload: error,
        });
      })
  }
}

export function loadMainProfessions() {
  return dispatch => {
    get(PROFESSIONS_LIST_URL)
      .done((data) => {
        dispatch({
          type: MAIN_PROFESSIONS_LIST_SUCCESS,
          payload: data.results
        })
      })
  }
}

export function loadMainGrades() {
  return dispatch => {
    get(CORP_GRADES_URL)
      .done((data) => {
        dispatch({
          type: MAIN_GRADES_LIST_SUCCESS,
          payload: data.results
        })
      })
  }
}