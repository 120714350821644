import React from "react";
import {toLocalDateTimeString} from "../commons/Commons";
import {getInvites} from "../actions/InvitePanelAction";
import connect from "react-redux/es/connect/connect";
import ProgressLine from "../commons/ProgressLine";

class InviteTableRow extends React.Component {
  render() {
    return (
      <tr className="border-bottom">
        <td>{this.props.data.email}</td>
        <td>{this.props.data.person ? (this.props.data.person.last_name ? this.props.data.person.last_name + " " + this.props.data.person.first_name : "Не зарегистрирован") : ""}</td>
        <td>{toLocalDateTimeString(this.props.data.create_time)}</td>
        <td>{this.props.data.status}</td>
      </tr>
    )
  }
}

class InvitePanel extends React.Component {

  componentDidMount() {
    this.props.getInvitesAction();
  };

  loadInviteTable() {
    let result = [];
    for (const r of this.props.invite.list) {
      result.push(<InviteTableRow key={r.id} data={r}/>);
    }
    return result;
  };

  render() {

    const {invite} = this.props;

    return (
      <div className="dashboard-panel">
        <h2>Приглашения</h2>

        {/**/}
        <ProgressLine height={"2px"} hidden={!invite.isFetching}/>

        {
          invite.error ?
            <div className="bg-danger text-white">{invite.error || "Ошибка получения данных"}</div>
            : null
        }

        {/**/}
        <div className="table-responsive-sm" id="inviteTable">
          <table className="table table-borderless table-sm panel-table">
            <thead className="border-bottom">
            <tr>
              <th>Email</th>
              <th>Фамилия Имя</th>
              <th>Дата приглашения</th>
              <th className="font-weight-bold">Статус</th>
            </tr>
            </thead>
            <tbody>
            {this.loadInviteTable()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}


// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    invite: store.invite,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getInvitesAction: () => dispatch(getInvites()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitePanel);
