import {
  GET_VACANCIES_ERROR,
  GET_VACANCIES_REQUEST,
  GET_VACANCIES_SUCCESS,
} from "../actions/VacancyPanelAction";

export const initialState = {
  error: null,
  message: null,

  isFetching: false,

  list: [],
  count: 0,
  page: 1,

  remove: {
    isOpen: false,
    vacancy_id: null
  },
};

export function vacancyPanelReducer(state = initialState, action) {

  switch (action.type) {

    case GET_VACANCIES_REQUEST:
      return {...state, isFetching: true, error: null};

    case GET_VACANCIES_SUCCESS:
      return {...state,
        isFetching: false,
        error: null,
        list: action.payload.list,
        page: action.payload.page,
        count: action.payload.count,
      };

    case GET_VACANCIES_ERROR:
      return {...state, isFetching: false, error: action.payload};

    default:
      return state;
  }
}