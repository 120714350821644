import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Spinner from "../commons/Spinner";
import connect from "react-redux/es/connect/connect";
import {getPersons, inviteChangeEmail, inviteCheckEmail, invitePerson, removePersonFromCompany, toggleInviteForm, toggleRemovePerson} from "../actions/PersonPanelAction";
import {Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";

import './PersonPanel.css'
import PersonInfoModalForm from "./PersonInfoModalForm";
import {openPersonInfoModal} from "../actions/PersonInfoModalAction";
import PaginationNav from "../components/PaginationNav";
import ProgressLine from "../commons/ProgressLine";

class PersonTeamRow extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.data.name}
      </div>
    )
  }
}

class PersonTableRow extends React.PureComponent {

  constructor(props) {
    super(props);
    this.removeFromCompanyButton = this.removeFromCompanyButton.bind(this);
  }

  removeFromCompanyButton(e) {
    this.props.toggleRemovePerson({
      person_id: this.props.data.id,
      name: this.props.data.email,
    });
  }

  render() {
    return (
      <tr className="border-bottom">
        <td className="align-middle">
          <button
            style={{margin: "0 5px 0 5px", padding: 0}}
            className="btn btn-link cl-def-1"
            onClick={() => this.props.personInfoAction(this.props.data.id)}>
            {this.props.data.last_name + " " + this.props.data.first_name}
          </button>

          {
            !this.props.data.profession_name ? <FontAwesomeIcon icon="info-circle"/> : null
          }

        </td>
        <td className="align-middle">{this.props.data.region}</td>
        <td className="align-middle">{this.props.data.mobile_number}</td>
        <td className="align-middle">{this.props.data.email}</td>
        <td className="align-middle">{this.props.data.profession_name}</td>
        <td className="align-middle">{this.props.data.grade_name}</td>
        <td className="align-middle">
          {
            this.props.data.teams.map(function (el) {
              return <PersonTeamRow key={el.id} data={el}/>
            })
          }
        </td>
        <td className="align-middle" style={{width: 100}}>
          <button title="Удалить из компании" className="btn btn-link cl-def-1" onClick={this.removeFromCompanyButton}>
            <FontAwesomeIcon icon={['far', "trash-alt"]}/>
          </button>
        </td>
      </tr>
    )
  }
}

const PersonTableHeader = () => {
  return (
    <tr className="border-bottom">
      <th scope="col">Фамилия Имя</th>
      <th scope="col">Регион</th>
      <th scope="col">Телефон</th>
      <th scope="col">email</th>
      <th scope="col">Профессия</th>
      <th scope="col">Опыт</th>
      <th scope="col">Команды</th>
      <th scope="col" style={{width: 100}}/>
    </tr>
  )
};

class InvitePersonModalForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.changeEmailInput = this.changeEmailInput.bind(this);
    this.successHandler = this.successHandler.bind(this);
  }

  changeEmailInput(event) {

    let val = event.currentTarget.value;

    this.props.changeEmail(val);
    this.props.checkEmail(val);
  }

  successHandler() {
    this.props.successHandler();
    this.props.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}
             fade={false}
             backdrop="static">
        <ModalHeader toggle={this.props.toggle}>
          Отправить приглашение пользователю
        </ModalHeader>
        <ModalBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Email</InputGroupAddon>
            <Input className={`${!this.props.check.status ? "border-danger" : "border-success"}`}
                   type="email"
                   placeholder="mr.andrei.ivanov@email.ru"
                   value={this.props.email}
                   onChange={this.changeEmailInput}
            />
          </InputGroup>
          {
            this.props.check.status ?
              null :
              <p>
                <small className="text-danger">{this.props.check.message}</small>
              </p>
          }
        </ModalBody>
        <ModalFooter>
          <Button className={"cl-btn-def-1"} outline onClick={this.props.toggle}>
            Отмена
          </Button>
          <Button className={"cl-btn-def-1"} outline onClick={this.successHandler} disabled={!(this.props.email && this.props.check.status)}>
            Отправить
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

class RemovePersonFromTeamDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.successHandler = this.successHandler.bind(this);
  }

  successHandler() {
    this.props.successHandler();
    this.props.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}
             fade={false}
             backdrop="static">
        <ModalHeader toggle={this.props.toggle}>
          Удалить из компании
        </ModalHeader>
        <ModalBody>
          Пользователь <b>{this.props.personName}</b> будет удален из компании
        </ModalBody>
        <ModalFooter>
          <Button className={"cl-btn-def-1"} outline onClick={this.successHandler}>
            Да
          </Button>
          <Button className={"cl-btn-def-1"} outline onClick={this.props.toggle}>
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

class PersonPanel extends React.PureComponent {

  loadPersonTable() {
    let result = [];
    for (const r of this.props.panel.person.list) {
      result.push(<PersonTableRow
        key={r.id}
        data={r}
        personInfoAction={this.props.openPersonInfoModalAction}
        toggleRemovePerson={this.props.toggleRemovePersonAction}
      />);
    }
    return result;
  };

  componentDidMount() {
    this.props.getPersonsAction();
  }

  render() {

    const {panel} = this.props;

    return (
      <div className="dashboard-panel">

        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>Сотрудники</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button className={"col cl-btn-def-1"} color="secondary" outline onClick={this.props.toggleInviteFormAction}>
                <FontAwesomeIcon icon="user-plus"/> Добавить сотрудника
              </Button>
            </div>
          </div>
        </div>

        {/* modal form */}
        <InvitePersonModalForm
          isFetching={panel.invite.isFetching}
          isOpen={panel.invite.toggle}
          toggle={this.props.toggleInviteFormAction}
          email={panel.invite.email}
          changeEmail={this.props.inviteChangeEmailAction}
          checkEmail={this.props.inviteCheckEmailAction}
          check={panel.invite.check}
          successHandler={this.props.invitePersonAction}
        />

        {/**/}
        <PersonInfoModalForm/>

        <RemovePersonFromTeamDialog
          isOpen={panel.remove.toggle}
          toggle={this.props.toggleRemovePersonAction}
          personName={panel.remove.name}
          successHandler={this.props.removeFromCompanyAction}
        />

        {/**/}
        <div className="info-panel">
          {panel.invite.isFetching ? <Spinner/> : null}
          {panel.error ? <div className="bg-danger text-white info-panel-text">{panel.error}</div> : null}
          {panel.message ? <div className="bg-success text-white info-panel-text">{panel.message}</div> : null}
        </div>

        {/* main table */}
        <div className="">
          {/**/}
          <ProgressLine height={"2px"} hidden={!panel.person.isFetching}/>

          {/**/}
          <Table className="panel-table" size="sm" responsive={true} borderless={true} hover={true}>
            <thead className="">
            <PersonTableHeader/>
            </thead>
            <tbody>
            {this.loadPersonTable()}
            </tbody>
          </Table>

          {/**/}
          <PaginationNav page={panel.person.page} count={panel.person.count} getPage={this.props.getPersonsAction}/>
        </div>
      </div>
    )
  }
}

// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    panel: store.person,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getPersonsAction: (page) => dispatch(getPersons(page)),
    invitePersonAction: () => dispatch(invitePerson()),

    toggleRemovePersonAction: (id) => dispatch(toggleRemovePerson(id)),
    toggleInviteFormAction: () => dispatch(toggleInviteForm()),
    inviteChangeEmailAction: (val) => dispatch(inviteChangeEmail(val)),

    inviteCheckEmailAction: (e) => dispatch(inviteCheckEmail(e)),

    openPersonInfoModalAction: (id) => dispatch(openPersonInfoModal(id)),
    removeFromCompanyAction: (d) => dispatch(removePersonFromCompany(d)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonPanel);
