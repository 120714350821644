import React from "react";
import './CompetenceRecommendation.css'
import {post} from "../../../commons/Commons";
import {CORP_PERSON_GER_RECS} from "../../../commons/Routing";
import Spinner from "../../../commons/Spinner";

const content_types = {
  article: 'Статья',
  book: 'Книга',
  certification: 'Сертификация',
  course: 'Курс',
  event: 'Мероприятие',
  practice: 'Практика',
  video: 'Видео',
};

class ContentRecommendation extends React.Component {

  render() {
    return (
      <div className="row content-recs">
        <div className="col-4 col-md-3">
          <a href={this.props.data.url} target={"_blank"}>
            <img className="rec-img" src={this.props.data.images['100_130']} aria-hidden={true} alt={"image"}/>
          </a>
        </div>
        <div className="col-8 col-md-9">
          <div className="rec-type">{content_types[this.props.data.content_type]}</div>
          <div className="rec-name">{this.props.data.content_name}</div>
          <div className="rec-author">{this.props.data.authors.length ? this.props.data.authors.join(',') : ""}</div>
        </div>
      </div>
    )
  }
}

class CompetenceRecommendation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      content: null,
      error: null,
      message: null,
      empty: true
    };

    this.loadCompetenceRecs = this.loadCompetenceRecs.bind(this);
    this.getCompetenceRecs = this.getCompetenceRecs.bind(this);
  }

  componentDidMount() {
    this.loadCompetenceRecs();
  }

  loadCompetenceRecs() {
    this.setState({isFetching: true});
    post(CORP_PERSON_GER_RECS, {person_id: this.props.person_id, competence_id: this.props.competence_id})
      .done((d) => {
        this.setState({isFetching: false});
        if (d.status === 'ok') {

          let empty = true;
          for (let type in content_types) {
            if (d.data.content && d.data.content[type] && d.data.content[type].length) {
              empty = false;
            }
          }

          this.setState({empty: empty});
          this.setState({content: d.data.content});
        }
      })
      .fail((jqXHR) => {
        this.setState({isFetching: false});
      })
  }

  getCompetenceRecs() {

    if (this.state.empty) {
      return []
    }

    let result = [];

    for (let type in content_types) {
      if (this.state.content && this.state.content[type] && this.state.content[type].length) {
        let content = this.state.content[type][0];
        result.push(<ContentRecommendation key={content.content_id} data={content}/>)
      }
    }

    return result;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-12 col-xl-6">
            <div className="container">
              {/**/}
              {this.state.isFetching ? <Spinner/> : null}
              {/**/}
              {
                !this.state.empty ? <React.Fragment>
                  <h5 className="recs-header">{this.props.competence_name}</h5>
                  {this.getCompetenceRecs()}
                </React.Fragment> : null
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CompetenceRecommendation;