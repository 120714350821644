import {
  GET_TEAMS_ERROR,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  TEAMS_ADD_MEMBER_CHANGE_EMAIL,
  TEAMS_ADD_MEMBER_CHECK_ERROR,
  TEAMS_ADD_MEMBER_CHECK_REQUEST,
  TEAMS_ADD_MEMBER_CHECK_SUCCESS,
  TEAMS_ADD_MEMBER_CLOSE,
  TEAMS_ADD_MEMBER_ERROR,
  TEAMS_ADD_MEMBER_OPEN,
  TEAMS_ADD_MEMBER_REQUEST,
  TEAMS_ADD_MEMBER_SUCCESS,
  TEAMS_ADD_TEAM_CHANGE_DESC,
  TEAMS_ADD_TEAM_CHANGE_NAME,
  TEAMS_ADD_TEAM_CLOSE,
  TEAMS_ADD_TEAM_ERROR,
  TEAMS_ADD_TEAM_FETCH,
  TEAMS_ADD_TEAM_OPEN,
  TEAMS_ADD_TEAM_REQUEST,
  TEAMS_ADD_TEAM_SUCCESS,
  TEAMS_FOR_UPDATE,
  TEAMS_REMOVE_CLOSE,
  TEAMS_REMOVE_ERROR,
  TEAMS_REMOVE_OPEN,
  TEAMS_REMOVE_REQUEST,
  TEAMS_REMOVE_SUCCESS
} from "../actions/TeamPanelAction";

export const initialState = {
  list: [],
  isFetching: false,
  limit: 10,
  offset: 0,
  error: null,
  message: null,

  forUpdate: [],

  addTeam: {
    show: false,
    teamId: null,
    name: "",
    description: "",
    error: null,
    isFetching: false
  },

  addMember: {
    show: false,
    teamId: null,
    personEmail: "",
    personId: null,
    personCheckMessage: null,
    error: null,
    isFetching: false
  },

  removeTeam: {
    show: false,
    teamId: null,
    name: null,
    error: null,
    isFetching: false
  }
};

export function teamPanelReducer(state = initialState, action) {

  switch (action.type) {
    case GET_TEAMS_REQUEST:
      return {...state, isFetching: true, error: null};
    case GET_TEAMS_SUCCESS:
      return {...state, isFetching: false, error: null, list: action.payload};
    case GET_TEAMS_ERROR:
      return {...state, isFetching: false, error: action.payload};

    case TEAMS_ADD_TEAM_OPEN:
      return {...state, addTeam: {...state.addTeam, show: true, teamId: action.payload}};
    case TEAMS_ADD_TEAM_CLOSE:
      return {...state, addTeam: {...state.addTeam, show: false, name: "", description: ""}};
    case TEAMS_ADD_TEAM_FETCH:
      return {...state, addTeam: {...state.addTeam, name: action.payload.name, description: action.payload.description}};

    case TEAMS_ADD_TEAM_REQUEST:
      return {...state, addTeam: {...state.addTeam, isFetching: true}, error: null};
    case TEAMS_ADD_TEAM_SUCCESS:
      return {...state, addTeam: {...state.addTeam, isFetching: false, show: false, name: "", description: ""}, error: null};
    case TEAMS_ADD_TEAM_ERROR:
      return {...state, addTeam: {...state.addTeam, isFetching: false}, error: action.payload};

    case TEAMS_ADD_TEAM_CHANGE_NAME:
      return {...state, addTeam: {...state.addTeam, name: action.payload}};
    case TEAMS_ADD_TEAM_CHANGE_DESC:
      return {...state, addTeam: {...state.addTeam, description: action.payload}};

    case TEAMS_ADD_MEMBER_OPEN:
      return {...state, addMember: {...state.addMember, show: true, teamId: action.payload, personEmail: "", personId: null, personCheckMessage: null, error: null}};
    case TEAMS_ADD_MEMBER_CLOSE:
      return {...state, addMember: {...state.addMember, show: false, teamId: null, personEmail: "", personId: null, personCheckMessage: null, error: null}};

    case TEAMS_ADD_MEMBER_CHANGE_EMAIL:
      return {...state, addMember: {...state.addMember, personEmail: action.payload}};
    case TEAMS_ADD_MEMBER_CHECK_REQUEST:
      return {...state, addMember: {...state.addMember, isFetching: true, personId: null, personCheckMessage: null, error: null}};
    case TEAMS_ADD_MEMBER_CHECK_SUCCESS:
      return {...state, addMember: {...state.addMember, isFetching: false, personId: action.payload, personCheckMessage: null, error: null}};
    case TEAMS_ADD_MEMBER_CHECK_ERROR:
      return {...state, addMember: {...state.addMember, isFetching: false, personId: null, personCheckMessage: action.payload}};

    case TEAMS_ADD_MEMBER_REQUEST:
      return {...state, addMember: {...state.addMember, isFetching: true, personCheckMessage: ""}};
    case TEAMS_ADD_MEMBER_SUCCESS:
      return {...state, addMember: {...state.addMember, show: false, isFetching: false, error: null, personId: null, personCheckMessage: null}};
    case TEAMS_ADD_MEMBER_ERROR:
      return {...state, addMember: {...state.addMember, isFetching: false, error: action.payload}};


    case TEAMS_REMOVE_OPEN:
      return {...state, removeTeam: {...state.removeTeam, show: true, teamId: action.payload.teamId, name: action.payload.name}, error: null};
    case TEAMS_REMOVE_CLOSE:
      return {...state, removeTeam: {...state.removeTeam, show: false, teamId: null, name: null}};
    case TEAMS_REMOVE_REQUEST:
      return {...state, removeTeam: {...state.removeTeam, isFetching: true}, error: null};
    case TEAMS_REMOVE_SUCCESS:
      return {...state, removeTeam: {...state.removeTeam, isFetching: false, show: false, teamId: null, name: null}, error: null};
    case TEAMS_REMOVE_ERROR:
      return {...state, removeTeam: {...state.removeTeam, isFetching: false, show: false, teamId: null, name: null}, error: action.payload};

    case TEAMS_FOR_UPDATE:
      return {...state, forUpdate: action.payload};

    default:
      return state;
  }
}