import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {addMember, addMemberChangeEmail, addMemberCheckPerson, addMemberClose} from "../../actions/TeamPanelAction";
import ProgressLine from "../../commons/ProgressLine";

class AddMemberDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.changeEmail = this.changeEmail.bind(this);
  }

  changeEmail(e) {
    this.props.addMemberChangeEmailAction(e.currentTarget.value);
    this.props.addMemberCheckEmailAction();
  }

  render() {
    const {addMember} = this.props;

    return (
      <Modal isOpen={addMember.show} toggle={this.props.addMemberCloseAction} fade={false}>
        <ModalHeader toggle={this.props.addMemberCloseAction}>
          Добавить сотрудника в команду
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">E-mail{" "}</div>
                </div>
                <input type="email" className="form-control" placeholder="user@email.com"
                       value={` ${addMember.personEmail}`}
                       onChange={this.changeEmail}/>
              </div>
            </div>
          </div>
          <ProgressLine height={"2px"} hidden={!addMember.isFetching}/>

          <small className={addMember.personId ? "text-success" : "text-danger"}>
            {addMember.personCheckMessage}
          </small>
          {
            addMember.error ? <p><small className={"text-danger"}>{addMember.error}</small></p> : null
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cl-btn-def-1" onClick={this.props.addMemberCloseAction}>
            Отмена
          </Button>
          <Button className="cl-btn-def-1" disabled={!addMember.personId} onClick={this.props.addMemberAction}>
            Добавить
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}


// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    addMember: store.team.addMember,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addMemberChangeEmailAction: (v) => dispatch(addMemberChangeEmail(v)),
    addMemberCheckEmailAction: () => dispatch(addMemberCheckPerson()),
    addMemberCloseAction: () => dispatch(addMemberClose()),
    addMemberAction: () => dispatch(addMember()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMemberDialog);
