import {Cookies} from "react-cookie"
import $ from "jquery"
import {MAIN_DOMAIN} from "../commons/Routing";

const cookies = new Cookies();

export const COOKIE_TOKEN = 'cl_t';
export const COOKIE_PERSON_ID = 'cl_p';
export const COOKIE_USER_NAME = 'cl_un';
export const COOKIE_COMPANY_ID = 'cl_c';

export const removeCookie = (name) => {
  return cookies.remove(name, {domain: MAIN_DOMAIN})
};

export const getCookie = (name) => {
  return cookies.get(name)
};

export const setCookie = (name, val) => {
  let d = new Date();
  d.setDate(d.getDate() + 14);
  return cookies.set(name, val, {expires: d, domain: MAIN_DOMAIN})
};

const authHeader = () => {
  return {
    "Authorization": "JWT " + getCookie(COOKIE_TOKEN)
  }
};

/**
 *
 * @param {string} datetime_string.
 * @param {boolean} with_time. default true
 * @return {string}
 */
export const toLocalDateTimeString = (datetime_string, with_time) => {

  with_time = [undefined, null].includes(with_time) ? true : with_time;

  let d = new Date(datetime_string);
  let result = d.toLocaleDateString();
  if (with_time) {
    result += " : " + d.toLocaleTimeString()
  }

  return result;
};

/**
 * Returns jquery AJAX instance with type===POST
 * @param {string} url
 * @param {Object} data. default null
 * @param {string} contentType. default "application/json; charset=utf-8"
 * @param {string} dataType. default "json"
 * @param {boolean} withAuthHeader. default true
 * @returns {Object} AJAX(type=POST) object
 */
export const post = (url, data, contentType, dataType, withAuthHeader) => {

  contentType = contentType || "application/json; charset=utf-8";
  dataType = dataType || "json";
  withAuthHeader = [undefined, null].includes(withAuthHeader) ? true : withAuthHeader;

  return $.ajax({
    type: "POST",
    url: url,
    data: JSON.stringify(data),
    contentType: contentType,
    dataType: dataType,
    headers: withAuthHeader ? authHeader() : {}
  })
};

/**
 * Returns jquery AJAX instance with type===GET
 * @param {string} url
 * @param {string} data. default null
 * @param {string} contentType. default "application/json; charset=utf-8"
 * @param {string} dataType. default "json"
 * @param {boolean} withAuthHeader. default true
 * @returns {Object} AJAX(type=GET) object
 */
export const get = (url, data, contentType, dataType, withAuthHeader) => {

  contentType = contentType || "application/json; charset=utf-8";
  dataType = dataType || "json";
  withAuthHeader = withAuthHeader || true;

  let params = {
    type: "GET",
    url: url,
    contentType: contentType,
    dataType: dataType,
    headers: withAuthHeader ? authHeader() : {},
  };

  if (data) {
    params['data'] = data;
  }

  return $.ajax(params)
};

/**
 * Returns jquery AJAX instance with type===PUT
 * @param {string} url
 * @param {Object} data. default null
 * @param {string} contentType. default "application/json; charset=utf-8"
 * @param {string} dataType. default "json"
 * @param {boolean} withAuthHeader. default true
 * @returns {Object} AJAX(type=PUT) object
 */
export const put = (url, data, contentType, dataType, withAuthHeader) => {

  contentType = contentType || "application/json; charset=utf-8";
  dataType = dataType || "json";
  withAuthHeader = withAuthHeader || true;

  return $.ajax({
    type: "PUT",
    url: url,
    data: JSON.stringify(data),
    contentType: contentType,
    dataType: dataType,
    headers: withAuthHeader ? authHeader() : {}
  })
};

/**
 * Returns jquery AJAX instance with type===PUT
 * @param {string} url
 * @param {Object} data. default null
 * @param {string} contentType. default "application/json; charset=utf-8"
 * @param {string} dataType. default "json"
 * @param {boolean} withAuthHeader. default true
 * @returns {Object} AJAX(type=PUT) object
 */
export const patch = (url, data, contentType, dataType, withAuthHeader) => {

  contentType = contentType || "application/json; charset=utf-8";
  dataType = dataType || "json";
  withAuthHeader = withAuthHeader || true;

  return $.ajax({
    type: "PATCH",
    url: url,
    data: JSON.stringify(data),
    contentType: contentType,
    dataType: dataType,
    headers: withAuthHeader ? authHeader() : {}
  })
};

/**
 * Возвращает 0, 2, 4, 6, 8, 10
 *
 * @param {float} val. Данные в процентах
 * @return {float}
 */
export function round2(val) {
  if (Math.round(val / 10) % 2 === 0) {
    return Math.round(val / 10);
  } else {
    return Math.round(val / 10) - 1;
  }
}

