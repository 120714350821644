import React from "react";
import connect from "react-redux/es/connect/connect";
import {getReport, reportIsBuiltClear, togglePerson} from "../../../actions/reports/TeamCompetenceAction";
import {Table, Button} from "reactstrap";
import SelectPersonPanel from "../SelectPersonPanel";
import {openPanel} from "../../../actions/reports/SelectPersonPanelAction";
import '../report.css';
import ProgressLine from "../../../commons/ProgressLine";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AssessmentChart from "../AssessmentChart";
import {withRouter} from "react-router";
import {pushPersonIds as pushPersonIdsWeakLink} from "../../../actions/reports/WeakLinkAction";
import {Link} from "react-router-dom";
import {BILLING_SERVICE_REPORT_TEAM_COMPETENCE} from "../../../commons/Settings";
import {setMainError} from "../../../actions/MainAction";


class TeamCompetenceTableHeader extends React.PureComponent {
  render() {
    return (
      <thead className="">
      <tr>
        <th scope={"col"} className="col-max-250">Навыки</th>
        {
          this.props.data.map(function (el) {
            return <th className="col-min-150"
                       scope={"col"}
                       key={el.person_id}>
              <Link to={"/reports/competence_map/" + el.person_id} target="_blank">
                {el.last_name + " " + el.first_name}
              </Link>
              <br/>
              <small>{el.profession.name}</small>
              <br/>
              <small>{el.grade.name}</small>
            </th>
          })
        }
      </tr>
      </thead>
    )
  }
}

class TeamCompetenceRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getPersonAssessments = this.getPersonAssessments.bind(this);
    this.getLevelPrefix = this.getLevelPrefix.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  getPersonAssessments() {
    let skill_id = this.props.data.id;
    return this.props.assessments.map((el) => {
      let ass = el.assessments.find(_e => _e.skill_id === skill_id);

      return <td className="col-min-150" key={skill_id.slice(-12) + "_" + el.person_id.slice(-12)}>
        <AssessmentChart percent={(ass && ![undefined, null].includes(ass.assessment)) ? 100 * ass.assessment : -1}/>
      </td>
    })
  }

  getLevelPrefix() {
    let pref = "";
    for (let i = 0; i < this.props.data.level; i++) {
      pref += "\u00A0\u00A0"
    }

    return pref;
  }

  render() {
    return (
      <React.Fragment>
        <tr onClick={this.toggle} hidden={!!!this.props.parentIsOpen}>
          <td className="col-max-250">
            <span>{this.getLevelPrefix()}</span>
            {
              !!!this.props.data.skills.length || (this.props.data.level > 2)
                ? null :
                <FontAwesomeIcon icon={['far', `${this.state.isOpen ? "minus-square" : "plus-square"}`]}/>
            }
            <span>{"  "}</span>
            {this.props.data.name}
          </td>
          {this.getPersonAssessments()}
        </tr>

        {
          !!this.props.data.skills.length && (this.props.data.level > 2) && this.state.isOpen
            ? null :
            <React.Fragment>
              {
                this.props.data.skills.map((ch) => {
                  return <TeamCompetenceRow
                    key={ch.id}
                    data={ch}
                    assessments={this.props.assessments}
                    parentIsOpen={this.state.isOpen && this.props.parentIsOpen}
                  />;
                })
              }
            </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

class TeamCompetenceTable extends React.PureComponent {

  render() {
    return (
      <Table size={"sm"} hover responsive>
        <TeamCompetenceTableHeader data={this.props.data}/>
        <tbody>
        {
          this.props.categories.map((el) => {
            return <TeamCompetenceRow key={el.id} data={el} assessments={this.props.data} parentIsOpen={true}/>;
          })
        }
        </tbody>
      </Table>
    )
  }
}

class TeamCompetence extends React.Component {

  constructor(props) {
    super(props);

    this.successSelectPerson = this.successSelectPerson.bind(this);
    this.buildNewReport = this.buildNewReport.bind(this);
    this.buildTeamCompetenceReport = this.buildTeamCompetenceReport.bind(this);
    this.buildWeakLinkReport = this.buildWeakLinkReport.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
  }

  successSelectPerson() {
    this.props.getReportByTeamAction()
  }


  checkPermission() {
    if (!(BILLING_SERVICE_REPORT_TEAM_COMPETENCE in this.props.reports.list)) {
      this.props.showMainErrorAction('Отчет недоступен. Обратитесь в поддержку.');
      this.props.history.goBack();
    }

    if (this.props.location && this.props.location.state && this.props.location.state.build) {
      this.successSelectPerson();
    } else if (!this.props.report.isBuilt) {
      this.buildNewReport();
    }
  }

  componentDidMount() {

    setTimeout(this.checkPermission, 1);
  }

  buildNewReport() {
    this.props.reportIsBuiltClearAction();
    this.props.openSelectPersonPanelAction();
  }

  buildWeakLinkReport() {
    this.props.pushPersonIdsWeakLinkAction(this.props.report.selected);
    this.props.history.push("/reports/weak_link", {build: true});
  }

  buildTeamCompetenceReport() {

  }

  render() {

    const {report, reports} = this.props;

    return (
      <React.Fragment>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>{reports.list[BILLING_SERVICE_REPORT_TEAM_COMPETENCE] ? reports.list[BILLING_SERVICE_REPORT_TEAM_COMPETENCE].name : ""}</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button className="col cl-btn-def-1"
                      outline
                      onClick={report.isBuilt ? this.buildNewReport : this.successSelectPerson}
                      disabled={report.isBuilt ? false : !report.selected.length || report.isFetching}>
                {report.isBuilt ? "Построить новый отчет" : "Построить отчет"}
              </Button>
            </div>
          </div>
        </div>

        {/* select person for report panel */}
        <SelectPersonPanel
          togglePersonHandler={this.props.togglePersonAction}
          selected={this.props.report.selected}
          only_one={false}
          isOpen={!report.isBuilt}
        />

        <div className="rep-result">
          <ProgressLine height={"2px"} hidden={!report.isFetching}/>

          {
            report.error ?
              <div className="bg-danger text-white">{report.error || "Ошибка при формировании отчета"}</div>
              : null
          }

          {/**/}
          {
            report.isBuilt ? <TeamCompetenceTable categories={report.categories} data={report.data}/> : null
          }
        </div>


      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    report: store.report_by_team_group,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showMainErrorAction: (message) => dispatch(setMainError(true, message)),
    getReportByTeamAction: () => dispatch(getReport()),

    togglePersonAction: (person_id) => dispatch(togglePerson(person_id)),

    openSelectPersonPanelAction: () => dispatch(openPanel()),

    reportIsBuiltClearAction: () => dispatch(reportIsBuiltClear()),
    pushPersonIdsWeakLinkAction: (pids) => dispatch(pushPersonIdsWeakLink(pids))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamCompetence));