import {get, post} from "../commons/Commons";
import {CORP_GRADES_URL, CORP_PERSON_CORP_INFO_URL, CORP_PERSON_SET_GRADE_URL, CORP_PERSON_SET_PROF_URL, PROFESSIONS_LIST_URL} from "../commons/Routing";
import {getPersons} from "./PersonPanelAction";

export const GET_PERSON_INFO_MODAL_REQUEST = "GET_PERSON_INFO_MODAL_REQUEST";
export const GET_PERSON_INFO_MODAL_SUCCESS = "GET_PERSON_INFO_MODAL_SUCCESS";
export const GET_PERSON_INFO_MODAL_ERROR = "GET_PERSON_INFO_MODAL_ERROR";

export const PERSON_INFO_MODAL_OPEN = "GET_PERSON_INFO_MODAL_OPEN";
export const PERSON_INFO_MODAL_TOGGLE = "GET_PERSON_INFO_MODAL_TOGGLE";

export const PIM_PROFESSION_TOGGLE = "PIM_PROFESSION_TOGGLE";
export const PIM_GRADE_TOGGLE = "PIM_GRADE_TOGGLE";

export const PIM_GET_PROFESSION_REQUEST = "PIM_GET_PROFESSION_REQUEST";
export const PIM_GET_PROFESSION_SUCCESS = "PIM_GET_PROFESSION_SUCCESS";
export const PIM_GET_PROFESSION_ERROR = "PIM_GET_PROFESSION_ERROR";

export const PIM_GET_GRADE_REQUEST = "PIM_GET_GRADE_REQUEST";
export const PIM_GET_GRADE_SUCCESS = "PIM_GET_GRADE_SUCCESS";
export const PIM_GET_GRADE_ERROR = "PIM_GET_GRADE_ERROR";

export const PIM_SET_PROFESSION_REQUEST = "PIM_SET_PROFESSION_REQUEST";
export const PIM_SET_PROFESSION_SUCCESS = "PIM_SET_PROFESSION_SUCCESS";
export const PIM_SET_PROFESSION_ERROR = "PIM_SET_PROFESSION_ERROR";

export const PIM_SET_GRADE_REQUEST = "PIM_SET_GRADE_REQUEST";
export const PIM_SET_GRADE_SUCCESS = "PIM_SET_GRADE_SUCCESS";
export const PIM_SET_GRADE_ERROR = "PIM_SET_GRADE_ERROR";

export function togglePersonInfoProfession() {
  return {
    type: PIM_PROFESSION_TOGGLE,
    payload: null
  }
}

export function togglePersonInfoGrade() {
  return {
    type: PIM_GRADE_TOGGLE,
    payload: null
  }
}

export function setNewProfession(person_id, prof_id) {
  return dispatch => {
    dispatch({
      type: PIM_SET_PROFESSION_REQUEST,
      payload: null
    });

    post(CORP_PERSON_SET_PROF_URL, {person_id: person_id, profession_id: prof_id})
      .done((d) => {
        if (d.status === 'ok') {
          dispatch({
            type: PIM_SET_PROFESSION_SUCCESS,
            payload: d.data
          });

          //
          dispatch(getPersons());
        }
        else {
          dispatch({
            type: PIM_SET_PROFESSION_ERROR,
            payload: d.message
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: PIM_SET_PROFESSION_ERROR,
          payload: error
        });
      })
  }
}

export function setNewGrade(person_id, grade_id) {
  return dispatch => {
    dispatch({
      type: PIM_SET_GRADE_REQUEST,
      payload: null
    });

    post(CORP_PERSON_SET_GRADE_URL, {person_id: person_id, grade_id: grade_id})
      .done((d) => {
        if (d.status === 'ok') {
          dispatch({
            type: PIM_SET_GRADE_SUCCESS,
            payload: d.data
          });

          //
          dispatch(getPersons());
        }
        else {
          dispatch({
            type: PIM_SET_GRADE_ERROR,
            payload: d.message
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: PIM_SET_GRADE_ERROR,
          payload: error
        });
      })
  }
}

export function getPersonInfoGrades() {
  return dispatch => {
    dispatch({
      type: PIM_GET_GRADE_REQUEST,
      payload: null,
    });

    get(CORP_GRADES_URL)
      .done((d) => {
        dispatch({
          type: PIM_GET_GRADE_SUCCESS,
          payload: d.results,
        });
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: PIM_GET_GRADE_ERROR,
          payload: error,
        });
      })
  }
}

export function getPersonInfoProfessions() {
  return dispatch => {
    dispatch({
      type: PIM_GET_PROFESSION_REQUEST,
      payload: null,
    });

    get(PROFESSIONS_LIST_URL)
      .done((d) => {
        dispatch({
          type: PIM_GET_PROFESSION_SUCCESS,
          payload: d.results,
        });
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: PIM_GET_PROFESSION_ERROR,
          payload: error,
        });
      })
  }
}

export function openPersonInfoModal(personId) {
  return dispatch => {
    dispatch({
      type: PERSON_INFO_MODAL_OPEN,
      payload: personId,
    });

    dispatch(getPersonInfoModal(personId));
  }
}

export function togglePersonInfoModal() {
  return {
    type: PERSON_INFO_MODAL_TOGGLE,
    payload: null,
  }
}

export function getPersonInfoModal(personId) {

  return dispatch => {

    dispatch({
      type: GET_PERSON_INFO_MODAL_REQUEST,
      payload: null,
    });

    post(CORP_PERSON_CORP_INFO_URL, {person_id: personId})
      .done((d) => {
        if (d.status === 'ok') {
          dispatch({
            type: GET_PERSON_INFO_MODAL_SUCCESS,
            payload: d.data,
          });
        }
        else {
          dispatch({
            type: GET_PERSON_INFO_MODAL_ERROR,
            payload: d.message,
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: GET_PERSON_INFO_MODAL_ERROR,
          payload: error,
        });
      })
  }
}