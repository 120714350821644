import React from "react";
import connect from "react-redux/es/connect/connect";
import {getExperts} from "../actions/ExpertPanelAction";
import ProgressLine from "../commons/ProgressLine";


class ExpertTableRow extends React.Component {
  render() {
    return (
      <tr className="border-bottom">
        <td className="align-middle">{this.props.data.last_name + " " + this.props.data.first_name}</td>
        <td className="align-middle">{this.props.data.profession}</td>
        <td className="align-middle"/>
      </tr>
    )
  }
}

class ExpertPanel extends React.Component {

  loadExperts() {
    let result = [];
    for (const r of this.props.expert.list) {
      result.push(<ExpertTableRow key={r.id} data={r}/>)
    }
    return result;
  };

  componentDidMount() {
    this.props.getExpertsAction();
  };

  render() {

    const {expert} = this.props;

    return (
      <div className="dashboard-panel">
        <h2>Эксперты</h2>

        {/**/}
        <ProgressLine height={"2px"} hidden={!expert.isFetching}/>

        {/**/}
        <div className="table-responsive-sm" id="expertTable">
          <table className="table table-borderless table-sm panel-table">
            <thead>
            <tr className="border-bottom">
              <th scope="col">Фамилия Имя</th>
              <th scope="col">Должность</th>
              <th scope="col">Команды</th>
            </tr>
            </thead>
            <tbody>
            {this.loadExperts()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}


// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    expert: store.expert,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getExpertsAction: () => dispatch(getExperts()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertPanel);
