import React from "react";
import {Button} from "reactstrap";
import {connect} from "react-redux";
import {getReportByPerson, reportError, reportErrorClear, reportIsBuilt, reportIsBuiltClear, togglePerson,} from "../../../actions/reports/CompetenceMapAction";
import Spinner from "../../../commons/Spinner";
import '../report.css'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/highcharts-more'
import SelectPersonPanel from "../SelectPersonPanel";
import {openPanel} from "../../../actions/reports/SelectPersonPanelAction";
import CompetenceRecommendation from "./CompetenceRecommendation";
import CompetenceSkillsChart from "./CompetenceSkillsChart";
import {BILLING_SERVICE_REPORT_BY_PERSON} from "../../../commons/Settings";
import {setMainError} from "../../../actions/MainAction";

//magic
HighchartsMore(Highcharts);

class CompetenceMap extends React.Component {

  constructor(props) {
    super(props);

    this.successSelectPerson = this.successSelectPerson.bind(this);
    this.buildNewReport = this.buildNewReport.bind(this);
    this.getRecommendations = this.getRecommendations.bind(this);
    this.getCompetenceSkillCharts = this.getCompetenceSkillCharts.bind(this);
    this.checkPermission = this.checkPermission.bind(this);

    this.param_person_id = (props.match && props.match.params) ? props.match.params.person_id : null;
  }

  getChartCategories() {

    if (this.props.report.categories) {
      return this.props.report.categories.map((cat) => {
        return cat.name
      })
    }

    return [];
  }

  getChartSeries() {
    if (this.props.report.series) {
      return this.props.report.series;
    }

    return [];
  }

  getChartOptions() {
    return {
      chart: {
        polar: true,
        backgroundColor: '#ffffff',
        type: 'line',
        marginTop: 50,
        marginBottom: 100
      },

      colors: ["#1c9dd9", "#ff8562", "#2b908f", "#f45b5b", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#91e8e1"],

      title: {
        text: '',
        style: {
          fontFamily: 'Rubik',
          fontSize: '1rem',
          color: '#2e3192',
          fontWeight: '300'
        }
      },

      subtitle: {
        text: '',
        style: {
          fontFamily: 'Rubik',
          fontSize: '2rem',
          color: '#2e3192',
          fontWeight: '300'

        }
      },

      pane: {
        size: '100%'
      },

      xAxis: {
        categories: this.getChartCategories(),
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {distance: 20},
        gridLineColor: '#ccc'
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 10,
        gridLineColor: '#ccc'
      },

      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>'
      },

      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 15,
        y: 0,

        itemStyle: {
          fontSize: '14px',
          lineHeight: '1',
          fontWeight: '300',
          fontFamily: "Rubik",
          letterSpacing: '0.5px'
        },

        title: {
          text: '',
          style: {
            fontStyle: 'italic',
            fontSize: '10px',
            color: '#666'
          }
        }
      },

      navigation: {buttonOptions: {enabled: false}},
      credits: {enabled: false},
      plotOptions: {series: {marker: {enabled: false}}},

      series: this.getChartSeries(),

      responsive: {
        rules: [{
          condition: {maxWidth: 900},
          chartOptions: {
            chart: {style: {backgroundColor: '#000000'}},
            xAxis: {labels: {style: {display: 'none'}}},
            yAxis: {labels: {style: {display: 'none'}}},
            legend: {title: {text: ''}},
            pane: {size: '100%'}
          }
        }]
      }
    };
  }

  successSelectPerson(person_id) {
    if (this.props.report.selected.length || person_id) {
      this.props.reportIsBuiltAction();

      this.props.reportErrorClearAction();
      this.props.getReportByPersonAction();
    } else {
      this.props.reportErrorAction("Необходимо указать пользователей")
    }
  }

  checkPermission() {
    if (!(BILLING_SERVICE_REPORT_BY_PERSON in this.props.reports.list)) {
      this.props.showMainErrorAction('Отчет Компетенции сотрудника недоступен. Обратитесь в поддержку.');
      this.props.history.goBack();
    }

    let param_person_id = this.param_person_id;

    if (param_person_id) {
      this.props.togglePersonAction(param_person_id);
      this.successSelectPerson(param_person_id);
    } else if (!this.props.report.isBuilt) {
      this.buildNewReport();
    }
  }

  componentDidMount() {

    setTimeout(this.checkPermission, 1);

  }

  buildNewReport() {
    this.props.reportIsBuiltClearAction();
    this.props.openSelectPersonPanelAction();
  }

  getRecommendations() {
    if (this.props.report.categories && this.props.report.selected.length) {
      return this.props.report.categories.map((cat) => {
        return <CompetenceRecommendation
          key={cat.id}
          competence_id={cat.id}
          competence_name={cat.name}
          person_id={this.props.report.selected[0]}
        />
      })
    }
  }

  getCompetenceSkillCharts() {
    if (this.props.report.data
      && this.props.report.data.assessments) {

      return this.props.report.data.assessments.map((comp) => {
        return <CompetenceSkillsChart
          key={comp.id}
          data={comp}
        />
      })
    }
  }

  render() {
    const {
      report, reports
    } = this.props;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>{reports.list[BILLING_SERVICE_REPORT_BY_PERSON].name}</h2>
          </div>
          <div className="col-12 col-md-6" hidden={this.param_person_id}>
            <div className="float-md-right">
              {
                report.isBuilt ?
                  <Button className="col cl-btn-def-1"
                          outline
                          onClick={this.buildNewReport}>
                    Построить новый отчет
                  </Button> :
                  <Button className="col cl-btn-def-1"
                          outline
                          onClick={this.successSelectPerson}
                          disabled={!report.selected.length}>
                    Построить отчет
                  </Button>
              }
            </div>
          </div>
        </div>

        {/* select person for report panel */}
        <SelectPersonPanel
          togglePersonHandler={this.props.togglePersonAction}
          selected={this.props.report.selected}
          isOpen={!report.isBuilt}
          only_one={true}
        />

        {/* error */}
        <div className="rep-result">
          {report.isFetching ? <h3><Spinner/></h3> : null}
          {
            report.error ?
              <div className="bg-danger text-white">{report.error || "Ошибка при формировании отчета"}</div>
              : null
          }
          <div>
            {
              report.data && report.data.last_name ?
                <h4 className="text-center">{report.data.last_name + " " + report.data.first_name}</h4> :
                null
            }
            {
              report.data.profession && report.data.profession.name ?
                <h5 className="text-center text-secondary">
                  {`${report.data.profession.name} ${report.data.grade && report.data.grade.name ? "(" +report.data.grade.name +")" : ""}`}
                </h5> :
                null
            }

            {/*main chart*/}
            <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} oneToOne={true}/>

            {/*competences charts*/}
            {this.getCompetenceSkillCharts()}

            {/*recs*/}
            {this.getRecommendations()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    report: store.report_by_person,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showMainErrorAction: (message) => dispatch(setMainError(true, message)),
    getReportByPersonAction: () => dispatch(getReportByPerson()),

    reportErrorAction: (msg) => dispatch(reportError(msg)),
    reportErrorClearAction: () => dispatch(reportErrorClear()),

    openSelectPersonPanelAction: () => dispatch(openPanel()),

    reportIsBuiltAction: () => dispatch(reportIsBuilt()),
    reportIsBuiltClearAction: () => dispatch(reportIsBuiltClear()),


    togglePersonAction: (person_id) => dispatch(togglePerson(person_id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetenceMap);