export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_REDIRECT_AFTER_LOGIN = "SET_REDIRECT_AFTER_LOGIN";

export function setRedirectAfterLogin(path) {
  return {
    type: SET_REDIRECT_AFTER_LOGIN,
    payload: path
  }
}

export function authLogin(user_data) {
  return {
    type: AUTH_LOGIN,
    payload: user_data
  }
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
    payload: null
  }
}
