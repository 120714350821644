import {LOGIN_CHANGE_EMAIL, LOGIN_CHANGE_PASSWORD, LOGIN_CLEAR_ERROR, LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST} from "../actions/LoginAction";


export const initialState = {
  email: "",
  password: "",
  isFetching: false,
  error: {
    message: null,
    email: null,
    password: null,
  },
};

export function loginReducer(state = initialState, action) {

  switch (action.type) {
    case LOGOUT_REQUEST:
      return {...state, isFetching: false, error: {message: null, email: null, password: null}};

    case LOGIN_REQUEST:
      return {...state, isFetching: true, error: {message: null, email: null, password: null}};
    case LOGIN_SUCCESS:
      return {...state, isFetching: false, error: {message: null, email: null, password: null}};
    case LOGIN_ERROR:
      return {...state, isFetching: false, error: {...action.payload}};

    case LOGIN_CLEAR_ERROR:
      return {...state, isFetching: false, error: {message: null, email: null, password: null}};

    case LOGIN_CHANGE_EMAIL:
      return {...state, email: action.payload, isFetching: false};
    case LOGIN_CHANGE_PASSWORD:
      return {...state, password: action.payload, isFetching: false};

    default:
      return state
  }
}