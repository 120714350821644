import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {removeTeam, removeTeamClose} from "../../actions/TeamPanelAction";
import {connect} from "react-redux";


class RemoveTeamDialog extends React.Component {
  render() {
    const {removeTeam} = this.props;

    return (
      <Modal isOpen={removeTeam.show} toggle={this.props.removeTeamCloseAction} size="md" backdrop="static" fade={false}>
        <ModalHeader toggle={this.props.removeTeamCloseAction}>
          Подтверждение удаления
        </ModalHeader>
        <ModalBody>
          <div className="text-danger">
            {`Вы точно хотите удалить команду ${removeTeam.name}?`}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cl-btn-def-1" onClick={this.props.removeTeamCloseAction}>
            Отмена
          </Button>
          <Button className="cl-btn-def-1" onClick={this.props.removeTeamAction}>
            Да
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}


// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    removeTeam: store.team.removeTeam,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    removeTeamCloseAction: () => dispatch(removeTeamClose()),
    removeTeamAction: () => dispatch(removeTeam())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveTeamDialog);