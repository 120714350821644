import {combineReducers} from "redux";
import {authReducer} from "./AuthReducer";
import {loginReducer} from "./LoginReducer";
import {mainReducer} from "./MainReducer";
import {personPanelReducer} from "./PersonReducer";
import {competenceMapReducer} from "./reports/CompetenceMapReducer";
import {teamCompetenceMapReducer} from "./reports/TeamCompetenceMapReducer";
import {expertPanelReducer} from "./ExpertPanelReducer";
import {invitePanelReducer} from "./InvitePanelReducer";
import {teamPanelReducer} from "./TeamPanelReducer";
import {vacancyPanelReducer} from "./VacancyPanelReducer";
import {vacancyEditFormReducer} from "./VacancyEditFormReducer";
import {personInfoModalReducer} from "./PersonInfoModalReducer";
import {selectPersonPanelReducer} from "./reports/SelectPersonPanelReducer";
import {teamCompetenceReducer} from "./reports/TeamCompetenceReducer";
import {adaptationReducer} from "./reports/AdaptationReducer";
import {weakLinkReducer} from "./reports/WeakLinkReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  main: mainReducer,

  report_by_person: competenceMapReducer,
  report_by_team: teamCompetenceMapReducer,
  report_by_team_group: teamCompetenceReducer,
  report_adaptation: adaptationReducer,
  report_weak_link: weakLinkReducer,

  person: personPanelReducer,
  expert: expertPanelReducer,
  invite: invitePanelReducer,
  team: teamPanelReducer,
  vacancy: vacancyPanelReducer,
  vacancy_edit: vacancyEditFormReducer,

  person_info_modal: personInfoModalReducer,

  select_person_panel: selectPersonPanelReducer,
});

