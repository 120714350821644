import {get, patch, post} from "../commons/Commons";
import {
  CORP_TEAM_INVITE_PERSON_URL,
  CORP_TEAMS_CHECK_MEMBER_URL,
  CORP_TEAMS_NEW_URL,
  CORP_TEAMS_REMOVE_MEMBER_URL,
  CORP_TEAMS_REMOVE_URL,
  CORP_TEAMS_URL,
  getCorpTeamUrl
} from "../commons/Routing";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";

export const TEAMS_REMOVE_OPEN = 'TEAMS_REMOVE_OPEN';
export const TEAMS_REMOVE_CLOSE = 'TEAMS_REMOVE_CLOSE';
export const TEAMS_REMOVE_REQUEST = 'TEAMS_REMOVE_REQUEST';
export const TEAMS_REMOVE_SUCCESS = 'TEAMS_REMOVE_SUCCESS';
export const TEAMS_REMOVE_ERROR = 'TEAMS_REMOVE_ERROR';

export const TEAMS_ADD_TEAM_OPEN = 'TEAMS_ADD_TEAM_OPEN';
export const TEAMS_ADD_TEAM_CLOSE = 'TEAMS_ADD_TEAM_CLOSE';
export const TEAMS_ADD_TEAM_FETCH = 'TEAMS_ADD_TEAM_FETCH';
export const TEAMS_ADD_TEAM_REQUEST = 'TEAMS_ADD_TEAM_REQUEST';
export const TEAMS_ADD_TEAM_SUCCESS = 'TEAMS_ADD_TEAM_SUCCESS';
export const TEAMS_ADD_TEAM_ERROR = 'TEAMS_ADD_TEAM_ERROR';
export const TEAMS_ADD_TEAM_CHANGE_NAME = 'TEAMS_ADD_TEAM_CHANGE_NAME';
export const TEAMS_ADD_TEAM_CHANGE_DESC = 'TEAMS_ADD_TEAM_CHANGE_DESC';

export const TEAMS_ADD_MEMBER_OPEN = 'TEAMS_ADD_MEMBER_OPEN';
export const TEAMS_ADD_MEMBER_CLOSE = 'TEAMS_ADD_MEMBER_CLOSE';
export const TEAMS_ADD_MEMBER_CHANGE_EMAIL = 'TEAMS_ADD_MEMBER_CHANGE_EMAIL';

export const TEAMS_ADD_MEMBER_CHECK_REQUEST = 'TEAMS_ADD_MEMBER_CHECK_REQUEST';
export const TEAMS_ADD_MEMBER_CHECK_SUCCESS = 'TEAMS_ADD_MEMBER_CHECK_SUCCESS';
export const TEAMS_ADD_MEMBER_CHECK_ERROR = 'TEAMS_ADD_MEMBER_CHECK_ERROR';

export const TEAMS_ADD_MEMBER_REQUEST = 'TEAMS_ADD_MEMBER_REQUEST';
export const TEAMS_ADD_MEMBER_SUCCESS = 'TEAMS_ADD_MEMBER_SUCCESS';
export const TEAMS_ADD_MEMBER_ERROR = 'TEAMS_ADD_MEMBER_ERROR';

export const TEAMS_REMOVE_MEMBER_REQUEST = 'TEAMS_REMOVE_MEMBER_REQUEST';
export const TEAMS_REMOVE_MEMBER_SUCCESS = 'TEAMS_REMOVE_MEMBER_SUCCESS';
export const TEAMS_REMOVE_MEMBER_ERROR = 'TEAMS_REMOVE_MEMBER_ERROR';

export const TEAMS_FOR_UPDATE = 'TEAMS_FOR_UPDATE';

export function addMemberOpen(teamId) {
  return {
    type: TEAMS_ADD_MEMBER_OPEN,
    payload: teamId
  }
}

export function addMemberClose() {
  return {
    type: TEAMS_ADD_MEMBER_CLOSE,
    payload: null
  }
}

export function addMemberChangeEmail(v) {
  return {
    type: TEAMS_ADD_MEMBER_CHANGE_EMAIL,
    payload: v
  }
}

export function addMemberCheckPerson() {
  return (dispatch, getStore) => {
    dispatch({
      type: TEAMS_ADD_MEMBER_CHECK_REQUEST,
      payload: null,
    });

    let data = {
      team_id: getStore().team.addMember.teamId,
      email: getStore().team.addMember.personEmail,
    };

    post(CORP_TEAMS_CHECK_MEMBER_URL, data)
      .done((result) => {
        if (result.status === 'ok') {
          dispatch({
            type: TEAMS_ADD_MEMBER_CHECK_SUCCESS,
            payload: result.data.person_id,
          });
        } else {
          dispatch({
            type: TEAMS_ADD_MEMBER_CHECK_ERROR,
            payload: result.message
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: TEAMS_ADD_MEMBER_CHECK_ERROR,
          payload: error,
        });
      })
  }
}

export function addMember() {
  return (dispatch, getStore) => {
    dispatch({
      type: TEAMS_ADD_MEMBER_REQUEST,
      payload: null,
    });

    let data = {
      email: getStore().team.addMember.personEmail,
      team_id: getStore().team.addMember.teamId,
      person_id: getStore().team.addMember.personId
    };

    post(CORP_TEAM_INVITE_PERSON_URL, data)
      .done((result) => {
        if (result.status === 'ok') {
          dispatch({
            type: TEAMS_ADD_MEMBER_SUCCESS,
            payload: null,
          });

          dispatch(forUpdateAdd(getStore().team.addMember.teamId));
        } else {
          dispatch({
            type: TEAMS_ADD_MEMBER_ERROR,
            payload: result.message,
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: TEAMS_ADD_MEMBER_ERROR,
          payload: error,
        });
      })
  }
}

export function removeTeam() {
  return (dispatch, getStore) => {
    dispatch({
      type: TEAMS_REMOVE_REQUEST,
      payload: null,
    });


    post(CORP_TEAMS_REMOVE_URL, {team_id: getStore().team.removeTeam.teamId})
      .done((result) => {
        if (result.status === 'ok') {
          dispatch({
            type: TEAMS_REMOVE_SUCCESS,
            payload: null,
          });

          dispatch(getTeams());
        } else {
          dispatch({
            type: TEAMS_REMOVE_ERROR,
            payload: result.message,
          });
        }
      })
      .fail((jqXHR) => {
        dispatch({
          type: TEAMS_REMOVE_ERROR,
          payload: jqXHR.responseText,
        });
      })
  }
}

export function removeTeamOpen(id, name) {
  return {
    type: TEAMS_REMOVE_OPEN,
    payload: {teamId: id, name: name}
  }
}

export function removeTeamClose() {
  return {
    type: TEAMS_REMOVE_CLOSE,
    payload: null
  }
}

export function addTeamOpen(id) {

  let _id = (id instanceof Object) ? null : id;

  return dispatch => {
    dispatch({
      type: TEAMS_ADD_TEAM_OPEN,
      payload: _id
    });

    if (_id) {
      get(getCorpTeamUrl(_id))
        .done((result) => {
          dispatch({
            type: TEAMS_ADD_TEAM_FETCH,
            payload: result
          });
        })
        .fail((jqXHR) => {
          dispatch({
            type: TEAMS_ADD_TEAM_ERROR,
            payload: _id
          });
        })
    }
  };
}

export function addTeamClose() {
  return {
    type: TEAMS_ADD_TEAM_CLOSE,
    payload: null
  }
}

export function addTeamSave() {
  return (dispatch, getStore) => {
    dispatch({
      type: TEAMS_ADD_TEAM_REQUEST,
      payload: null,
    });

    let data = {
      name: getStore().team.addTeam.name,
      description: getStore().team.addTeam.description
    };

    let _id = getStore().team.addTeam.teamId;
    let _url = CORP_TEAMS_NEW_URL;
    if (_id) {
      _url = getCorpTeamUrl(_id);
      data['id'] = _id;

      patch(_url, data)
        .done((result) => {
          dispatch({
            type: TEAMS_ADD_TEAM_SUCCESS,
            payload: null,
          });

          //
          dispatch(forUpdateAdd(_id));
        })
        .fail((jqXHR) => {
          dispatch({
            type: TEAMS_ADD_TEAM_ERROR,
            payload: jqXHR.responseText,
          });
        })
    } else {
      post(_url, data)
        .done((result) => {

          if (result.status === 'ok') {
            dispatch({
              type: TEAMS_ADD_TEAM_SUCCESS,
              payload: null,
            });

            //
            dispatch(getTeams());
          } else {
            dispatch({
              type: TEAMS_ADD_TEAM_ERROR,
              payload: result.message,
            });
          }
        })
        .fail((jqXHR) => {
          dispatch({
            type: TEAMS_ADD_TEAM_ERROR,
            payload: jqXHR.responseText,
          });
        })
    }
  }
}

export function addTeamChangeName(v) {
  return {
    type: TEAMS_ADD_TEAM_CHANGE_NAME,
    payload: v
  }
}

export function addTeamChangeDesc(v) {
  return {
    type: TEAMS_ADD_TEAM_CHANGE_DESC,
    payload: v
  }
}

export function getTeams() {
  return dispatch => {

    dispatch({
      type: GET_TEAMS_REQUEST,
      payload: null,
    });

    get(CORP_TEAMS_URL)
      .done((d) => {

        dispatch({
          type: GET_TEAMS_SUCCESS,
          payload: d.results,
        });

        d.results.forEach(el => dispatch(forUpdateAdd(el.id)))

      })
      .fail((jqXHR) => {
        dispatch({
          type: GET_TEAMS_ERROR,
          payload: jqXHR.responseText,
        })
      })

  }
}

export function removeTeamMember(teamId, personId) {
  return dispatch => {

    dispatch({
      type: TEAMS_REMOVE_MEMBER_REQUEST,
      payload: null
    });

    let data = {
      team_id: teamId,
      person_id: personId
    };
    post(CORP_TEAMS_REMOVE_MEMBER_URL, data)
      .done((result) => {
        dispatch({
          type: TEAMS_REMOVE_MEMBER_SUCCESS,
          payload: null
        });

        //
        dispatch(forUpdateAdd(teamId));
      })
      .fail((jqXHR) => {
        dispatch({
          type: TEAMS_REMOVE_MEMBER_ERROR,
          payload: jqXHR.responseText
        })
      })
  }
}

export function forUpdateAdd(id) {
  return (dispatch, getStore) => {
    let _result = getStore().team.forUpdate || [];

    _result.push(id);
    dispatch({
      type: TEAMS_FOR_UPDATE,
      payload: _result,
    });
  }
}

export function forUpdateDel(id) {
  return (dispatch, getStore) => {
    let _r = getStore().team.forUpdate || [];
    let _result = _r.filter(el => el !== id);

    dispatch({
      type: TEAMS_FOR_UPDATE,
      payload: _result,
    });
  }
}