import React from "react";
import SkillsChart from "../SkillsChart";
import {round2} from "../../../commons/Commons";
import '../report.css';

class TeamSkillsCharts extends React.Component {

  constructor(props) {
    super(props);
    this.getSkillsCharts = this.getSkillsCharts.bind(this);
  }

  getSkillsCharts() {
    if (!this.props.data.assessments) {
      return null;
    }

    return this.props.data.assessments.map((ass) => {
      return <SkillsChart
        key={ass.id}
        assessment={round2(ass.assessment_percent) / 10}
        name={ass.name}
        percent={Math.round(ass.assessment_percent)}
      />
    })
  }

  render() {
    return (
      <div className="row justify-content-center comp-charts">
        <div className="col-12 col-xl-6">
          <div className="container">
            <div className="comp-chart-header">
              <div className="row">
                <div className="col-12 col-md-6 align-middle float-md-left">
                  <h5>{this.props.data.last_name + " " + this.props.data.first_name}</h5>
                </div>
                <div className="col-12 col-md-6 align-middle text-md-right">
                  <h6>{this.props.data.profession.name + " (" + this.props.data.grade.name + ")"}</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col border-bottom">
                {
                  this.getSkillsCharts()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default TeamSkillsCharts;