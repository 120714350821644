import {post} from "../../commons/Commons";
import {CORP_REPORT_WEAK_LINK_URL} from "../../commons/Routing";

export const REPORT_WEAK_LINK_REQUEST = "REPORT_WEAK_LINK_REQUEST";
export const REPORT_WEAK_LINK_SUCCESS = "REPORT_WEAK_LINK_SUCCESS";
export const REPORT_WEAK_LINK_ERROR = "REPORT_WEAK_LINK_ERROR";

export const REPORT_WEAK_LINK_IS_BUILT = "REPORT_WEAK_LINK_IS_BUILT";
export const REPORT_WEAK_LINK_TOGGLE_PERSON = "REPORT_WEAK_LINK_TOGGLE_PERSON";

export const REPORT_WEAK_LINK_PUSH_PERSONS = "REPORT_WEAK_LINK_PUSH_PERSONS";


export function togglePerson(person_id) {
  return {
    type: REPORT_WEAK_LINK_TOGGLE_PERSON,
    payload: person_id
  }
}

export function reportIsBuilt() {
  return {
    type: REPORT_WEAK_LINK_IS_BUILT,
    payload: true,
  }
}

export function reportIsBuiltClear() {
  return {
    type: REPORT_WEAK_LINK_IS_BUILT,
    payload: false,
  }
}

export function getReport(persons) {

  return (dispatch, getStore) => {
    dispatch({
      type: REPORT_WEAK_LINK_REQUEST,
      payload: null,
    });

    persons = persons || getStore().report_weak_link.selected || [];

    post(CORP_REPORT_WEAK_LINK_URL, {persons: persons})
      .done((d) => {

        if (d.status === 'ok') {
          dispatch({
            type: REPORT_WEAK_LINK_SUCCESS,
            payload: d.data,
          });
        } else {
          dispatch({
            type: REPORT_WEAK_LINK_ERROR,
            payload: d.message,
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: REPORT_WEAK_LINK_ERROR,
          payload: error,
        });
      })
  }

}

export function pushPersonIds(pids) {
  return {
    type: REPORT_WEAK_LINK_PUSH_PERSONS,
    payload: pids
  }
}