import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {addTeamChangeDesc, addTeamChangeName, addTeamClose, addTeamSave} from "../../actions/TeamPanelAction";

/**
 * Класс для добавления или редавктирования сущности Team
 */
class AddEditTeamDialog extends React.Component {

  changeName = (e) => this.props.addTeamChangeNameAction(e.currentTarget.value);
  changeDescription = (e) => this.props.addTeamChangeDescAction(e.currentTarget.value);

  render() {
    const {addTeam} = this.props;

    return (
      <Modal isOpen={addTeam.show} toggle={this.props.addTeamCloseAction} size="md" backdrop="static" fade={false}>
        <ModalHeader toggle={this.props.addTeamCloseAction}>
          {`${addTeam.teamId ? "Изменить" : "Добавить"} команду`}
        </ModalHeader>
        <ModalBody>
          <div className="form-group" hidden={!addTeam.teamId}>
            <label className="">Team Id</label>
            <input className="form-control" value={addTeam.teamId}/>
          </div>
          <div className="form-group">
            <label className="">Наименование</label>
            <input className="form-control" value={addTeam.name} onChange={this.changeName}/>
          </div>
          <div className="form-group">
            <label className="">Описание</label>
            <input className="form-control" value={addTeam.description} onChange={this.changeDescription}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cl-btn-def-1" onClick={this.props.addTeamCloseAction}>
            Отмена
          </Button>
          <Button className="cl-btn-def-1" onClick={this.props.addTeamSaveAction}>
            {addTeam.teamId ? "Сохранить" : "Добавить"}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}


// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    addTeam: store.team.addTeam,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {

    addTeamCloseAction: () => dispatch(addTeamClose()),

    addTeamSaveAction: () => dispatch(addTeamSave()),
    addTeamChangeNameAction: (v) => dispatch(addTeamChangeName(v)),
    addTeamChangeDescAction: (v) => dispatch(addTeamChangeDesc(v)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditTeamDialog);
