import {get, patch, post} from "../commons/Commons";
import {CORP_VACANCY_URL, getCorpVacancyUrl, getProfCompsUrl} from "../commons/Routing";
import {getVacancies} from "./VacancyPanelAction";


export const VACANCY_EDIT_DATA_REQUEST = "VACANCY_EDIT_DATA_REQUEST";
export const VACANCY_EDIT_DATA_SUCCESS = "VACANCY_EDIT_DATA_SUCCESS";
export const VACANCY_EDIT_DATA_ERROR = "VACANCY_EDIT_DATA_ERROR";

export const VACANCY_EDIT_TOGGLE = "VACANCY_EDIT_TOGGLE";
export const VACANCY_EDIT_TOGGLE_PROFESSION = "VACANCY_EDIT_TOGGLE_PROFESSION";
export const VACANCY_EDIT_TOGGLE_SKILL = "VACANCY_EDIT_TOGGLE_SKILL";
export const VACANCY_EDIT_TOGGLE_SKILL_GRADE = "VACANCY_EDIT_TOGGLE_SKILL_GRADE";

export const VACANCY_EDIT_CHANGE_FIELD = "VACANCY_EDIT_CHANGE_FIELD";
export const VACANCY_EDIT_CHANGE_NAME = "VACANCY_EDIT_CHANGE_NAME";
export const VACANCY_EDIT_CHANGE_DESC = "VACANCY_EDIT_CHANGE_DESC";
export const VACANCY_EDIT_CHANGE_FROM = "VACANCY_EDIT_CHANGE_FROM";
export const VACANCY_EDIT_CHANGE_TO = "VACANCY_EDIT_CHANGE_TO";
export const VACANCY_EDIT_CHANGE_SKILLS = "VACANCY_EDIT_CHANGE_SKILLS";

export const VACANCY_EDIT_CLOSE = "VACANCY_EDIT_CLOSE";
export const VACANCY_EDIT_SAVE = "VACANCY_EDIT_SAVE";
export const VACANCY_EDIT_SELECT_PROFESSION = "VACANCY_EDIT_SELECT_PROFESSION";
export const VACANCY_EDIT_SELECT_SKILL_GRADE = "VACANCY_EDIT_SELECT_SKILL_GRADE";

export const VACANCY_EDIT_ADD_SKILL_TOGGLE = "VACANCY_EDIT_ADD_SKILL_TOGGLE";
export const VACANCY_EDIT_ADD_SKILL_TOGGLE_SELECT = "VACANCY_EDIT_ADD_SKILL_TOGGLE_SELECT";
export const VACANCY_EDIT_ADD_SKILL_SELECT = "VACANCY_EDIT_ADD_SKILL_SELECT";
export const VACANCY_EDIT_ADD_SKILL_ADD = "VACANCY_EDIT_ADD_SKILL_ADD";

export const VACANCY_EDIT_PROF_COMPS = "VACANCY_EDIT_PROF_COMPS";

export function removeSkill(skill_id) {
  return (dispatch, getStore) => {
    let form_data = getStore().vacancy_edit;
    let skills = {...form_data.skills};

    delete skills[skill_id];

    dispatch({
      type: VACANCY_EDIT_CHANGE_SKILLS,
      payload: skills
    });
  }
}

export function addSkillSelectToggle() {
  return {
    type: VACANCY_EDIT_ADD_SKILL_TOGGLE_SELECT,
    payload: null
  }
}

export function addSkillToggle() {
  return {
    type: VACANCY_EDIT_ADD_SKILL_TOGGLE,
    payload: null
  }
}

export function addSkillSelect(skill_id, skill_name) {
  return {
    type: VACANCY_EDIT_ADD_SKILL_SELECT,
    payload: {skill_id: skill_id, skill_name: skill_name}
  }
}

export function addSkillAdd() {
  return (dispatch, getStore) => {
    let form_data = getStore().vacancy_edit;
    let skills = {...form_data.skills};

    skills[form_data.addSkill.skill_id] = {
      id: form_data.addSkill.skill_id,
      name: form_data.addSkill.skill_name,
      grade_isOpen: true,
      grade_id: null,
      grade_name: null
    };

    dispatch({
      type: VACANCY_EDIT_CHANGE_SKILLS,
      payload: skills
    });

    dispatch({
      type: VACANCY_EDIT_ADD_SKILL_ADD,
      payload: null
    })
  }
}

export function toggle(vacancy_id) {
  return (dispatch, getStore) => {

    let isOpen = getStore().vacancy_edit.isOpen;

    let vacancy_edit = getStore().vacancy_edit;

    if (vacancy_id && !isOpen) {
      //
      dispatch({
        type: VACANCY_EDIT_DATA_REQUEST,
        payload: null,
      });

      //get vac data
      get(getCorpVacancyUrl(vacancy_id))
        .done((data) => {

          let _skills = {};
          let skills = data.skills || [];

          skills.forEach(function (skill_data, index) {
            _skills[skill_data.skill.id] = {
              id: skill_data.skill.id,
              name: skill_data.skill.name,
              required: skill_data.required,
              grade_id: skill_data.grade.id,
              grade_name: skill_data.grade.name,

              grade_isOpen: false,
            }
          });

          let _data = {...data, skills: _skills};

          dispatch({
            type: VACANCY_EDIT_DATA_SUCCESS,
            payload: _data,
          });

          get(getProfCompsUrl(data.profession.id))
            .done((data) => {
              let _prof_comps = [];

              let comps = data.results || [];
              comps.forEach(function (comp_data) {
                _prof_comps.push({
                  id: comp_data.skill.id,
                  name: comp_data.skill.name,
                });
              });

              dispatch({
                type: VACANCY_EDIT_PROF_COMPS,
                payload: _prof_comps,
              })
            });
        })
        .fail((jqXHR) => {
          let error = jqXHR.responseText;
          if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
            error = jqXHR.responseJSON.detail;
          }

          dispatch({
            type: VACANCY_EDIT_DATA_ERROR,
            payload: error,
          });
        })
    }

    dispatch({
      type: VACANCY_EDIT_TOGGLE,
      payload: null,
    })
  };
}

export function changeName(value) {
  return {
    type: VACANCY_EDIT_CHANGE_NAME,
    payload: value
  }
}

export function changeDesc(value) {
  return {
    type: VACANCY_EDIT_CHANGE_DESC,
    payload: value
  }
}

export function changeFrom(value) {
  return {
    type: VACANCY_EDIT_CHANGE_FROM,
    payload: value
  }
}

export function changeTo(value) {
  return {
    type: VACANCY_EDIT_CHANGE_TO,
    payload: value
  }
}

export function save() {
  return (dispatch, getStore) => {

    let form_data = getStore().vacancy_edit;

    if (!!!(form_data.name && form_data.profession.id && form_data.salary_from && form_data.salary_to)) {
      dispatch({
        type: VACANCY_EDIT_DATA_ERROR,
        payload: 'Заполните все обязательные поля'
      });

      return;
    }

    if (form_data.salary_from > form_data.salary_to) {
      dispatch({
        type: VACANCY_EDIT_DATA_ERROR,
        payload: 'Уровень зп От должен быть меньше чем Уровень зп До'
      });

      return;
    }

    let data = {
      "name": form_data.name,
      "short_description": form_data.short_description || '',
      "profession": form_data.profession.id,
      "salary_from": form_data.salary_from,
      "salary_to": form_data.salary_to,
      "skills": form_data.skills,
    };

    if (form_data.id) {
      data["id"] = form_data.id;

      patch(getCorpVacancyUrl(form_data.id), data)
        .done((d) => {
          dispatch(close());
          dispatch(getVacancies());
        })
        .fail((jqXHR) => {
          dispatch(close());
          dispatch(getVacancies());
        });
    } else {
      post(CORP_VACANCY_URL, data)
        .done((d) => {
          dispatch(close());
          dispatch(getVacancies());
        })
        .fail((jqXHR) => {
          dispatch(close());
          dispatch(getVacancies());
        });
    }
  }
}

export function close() {
  return {
    type: VACANCY_EDIT_CLOSE,
    payload: null,
  }
}

export function toggleProfession() {
  return {
    type: VACANCY_EDIT_TOGGLE_PROFESSION,
    payload: null,
  }
}

export function selectProfession(id, name) {
  return (dispatch) => {
    dispatch({
      type: VACANCY_EDIT_SELECT_PROFESSION,
      payload: {
        id: id,
        name: name,
      }
    });

    get(getProfCompsUrl(id))
      .done((data) => {
        let _prof_comps = [];

        let comps = data.results || [];
        comps.forEach(function (comp_data) {
          _prof_comps.push({
            id: comp_data.skill.id,
            name: comp_data.skill.name,
          });
        });

        dispatch({
          type: VACANCY_EDIT_PROF_COMPS,
          payload: _prof_comps,
        })
      });

    dispatch(toggleProfession())
  }
}

export function toggleGradeSkill(owner_id) {
  return (dispatch, getStore) => {
    let form_data = getStore().vacancy_edit;
    let skills = {...form_data.skills};

    skills[owner_id] = {
      ...form_data.skills[owner_id],
      grade_isOpen: !form_data.skills[owner_id].grade_isOpen
    };

    dispatch({
      type: VACANCY_EDIT_TOGGLE_SKILL_GRADE,
      payload: skills
    })
  }
}

export function selectGradeSkill(owner_id, grade_id, name) {
  return (dispatch, getStore) => {
    let form_data = getStore().vacancy_edit;
    let skills = {...form_data.skills};

    skills[owner_id] = {
      ...form_data.skills[owner_id],
      grade_isOpen: false,
      grade_id: grade_id,
      grade_name: name
    };

    dispatch({
      type: VACANCY_EDIT_SELECT_SKILL_GRADE,
      payload: skills
    });

    dispatch(toggleGradeSkill(owner_id));

  }
}

export function changeGradeRequiredSkill(owner_id, required) {
  return (dispatch, getStore) => {
    let form_data = getStore().vacancy_edit;
    let skills = {...form_data.skills};

    skills[owner_id] = {
      ...form_data.skills[owner_id],
      required: required,
    };

    dispatch({
      type: VACANCY_EDIT_SELECT_SKILL_GRADE,
      payload: skills
    });
  }
}

export function error(error_msg) {
  return {
    type: VACANCY_EDIT_DATA_ERROR,
    payload: error_msg
  }
}