export const MAIN_DOMAIN = process.env.REACT_APP_CORPLK_DOMAIN || 'localhost';
export const API_HOST = process.env.REACT_APP_CORPLK_API_HOST || 'http://127.0.0.1:8000';

export const LOGIN_URL = API_HOST + "/api/corp/login/";
export const CHECK_AUTH_URL = API_HOST + "/api/auth/check";

export const CORP_INVITE_PERSON_URL = API_HOST + "/api/corp/persons/company_invite";
export const CORP_TEAM_INVITE_PERSON_URL = API_HOST + "/api/corp/persons/team_invite";
export const CORP_INVITE_CHECK_EMAIL = API_HOST + "/api/corp/persons/check_person_email";

// данные корп персоны
export const CORP_INFO_URL = API_HOST + "/api/corp/persons/info";

// данные для обычной персоны
export const CORP_PERSON_INFO_URL = API_HOST + "/api/corp/persons/person_info";

// данные о персоне для модальной формы
export const CORP_PERSON_CORP_INFO_URL = API_HOST + "/api/corp/persons/person_corp_info";

export const CORP_PERSON_SET_PROF_URL = API_HOST + "/api/corp/persons/set_profession";
export const CORP_PERSON_SET_GRADE_URL = API_HOST + "/api/corp/persons/set_grade";

export const CORP_PERSON_GER_RECS = API_HOST + "/api/corp/persons/person_corp_recs";
export const CORP_PERSON_BILLING_REPORTS = API_HOST + '/api/corp/persons/billing_reports';
export const CORP_PERSON_REMOVE_FROM_COMPANY = API_HOST + '/api/corp/persons/remove_from_company';

export const CORP_PERSONS_LIST_URL = API_HOST + "/api/corp/persons/person_list";
export const CORP_EXPERT_LIST_URL = API_HOST + "/api/corp/persons/expert_list";

export const CORP_INVITES_LIST_URL = API_HOST + "/api/corp/invites";

export const CORP_GRADES_URL = API_HOST + "/api/corp/grades";
export const CORP_COMPETENCES_URL = API_HOST + "/api/corp/competences";

export const CORP_TEAMS_URL = API_HOST + "/api/corp/teams";
export const CORP_TEAMS_NEW_URL = API_HOST + "/api/corp/teams/new";
export const CORP_TEAMS_REMOVE_URL = API_HOST + "/api/corp/teams/remove";
export const CORP_TEAMS_MEMBERS_URL = API_HOST + "/api/corp/teams/members";

export const CORP_TEAMS_REMOVE_MEMBER_URL = API_HOST + "/api/corp/teams/remove_member";
export const CORP_TEAMS_CHECK_MEMBER_URL = API_HOST + "/api/corp/teams/check_member";

export const CORP_REPORT_BY_PERSON_URL = API_HOST + "/api/corp/reports/by_person";
export const CORP_REPORT_BY_TEAM_URL = API_HOST + "/api/corp/reports/by_team";
export const CORP_REPORT_BY_TEAM_GROUP_URL = API_HOST + "/api/corp/reports/team_competence";
export const CORP_REPORT_ADAPT_URL = API_HOST + "/api/corp/reports/adaptation";
export const CORP_REPORT_WEAK_LINK_URL = API_HOST + "/api/corp/reports/weak_link";

export const PROFESSIONS_LIST_URL = API_HOST + "/api/professions";
export const getCorpTeamUrl = (_id) => API_HOST + "/api/corp/teams/" + _id;

export const CORP_VACANCY_URL = API_HOST + "/api/corp/vacancies";
export const getCorpVacancyUrl = (_id) => CORP_VACANCY_URL + '/' + _id;
export const CORP_REPORT_CANDIDATES_SELECTION_URL = API_HOST + "/api/corp/reports/candidates_selection";
export const getProfCompsUrl = (_id) => API_HOST + "/api/prof_comps/" + _id;