import React from "react";
import SelectPersonPanel from "../SelectPersonPanel";
import {getReport, pushPersonIds as pushPersonIdsWeakLink, reportIsBuiltClear, togglePerson} from "../../../actions/reports/WeakLinkAction";
import {openPanel} from "../../../actions/reports/SelectPersonPanelAction";
import ProgressLine from "../../../commons/ProgressLine";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {pushPersonIds as pushPersonIdsTeamCompetence} from "../../../actions/reports/TeamCompetenceAction";
import '../report.css'
import {Table, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AssessmentChart from "../AssessmentChart";
import {BILLING_SERVICE_REPORT_WEAK_LINK} from "../../../commons/Settings";
import {setMainError} from "../../../actions/MainAction";

class WeakLinkTableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getPersonAssessments = this.getPersonAssessments.bind(this);
    this.getLevelPrefix = this.getLevelPrefix.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  getPersonAssessments() {
    let skill_id = this.props.data.id;

    return this.props.assessments.map((el, index) => {
      let ass = el.assessments.find(_e => _e.skill_id === skill_id);

      return <td className={`col-min-150 col-p${index}`} key={skill_id.slice(-12) + "_" + el.person_id.slice(-12)}>
        <AssessmentChart percent={(ass && ![undefined, null].includes(ass.assessment)) ? 100 * ass.assessment : -1}/>
      </td>;
    });
  }

  getLevelPrefix() {
    let pref = "";
    for (let i = 0; i < this.props.data.level; i++) {
      pref += "\u00A0\u00A0"
    }

    return pref;
  }

  render() {
    return (
      <React.Fragment>
        <tr onClick={this.toggle} hidden={!!!this.props.parentIsOpen}>
          <td className="col-max-250">
            <span>{this.getLevelPrefix()}</span>
            {
              !!!this.props.data.skills.length || (this.props.data.level > 2)
                ? null :
                <FontAwesomeIcon icon={['far', `${this.state.isOpen ? "minus-square" : "plus-square"}`]}/>
            }
            <span>{"  "}</span>
            {this.props.data.name}
          </td>
          {this.getPersonAssessments()}
        </tr>

        {
          !!this.props.data.skills.length && (this.props.data.level > 2) && this.state.isOpen
            ? null :
            <React.Fragment>
              {
                this.props.data.skills.map((ch) => {
                  return <WeakLinkTableRow
                    key={ch.id}
                    data={ch}
                    assessments={this.props.assessments}
                    parentIsOpen={this.state.isOpen && this.props.parentIsOpen}
                  />;
                })
              }
            </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

class WeakLinkTableHeader extends React.PureComponent {
  render() {
    return (
      <thead className="">
      <tr>
        {/**/}
        <th scope={"col"} className="col-max-250">{" "}</th>
        {/*named cols*/}
        {
          this.props.data.map(function (el, index) {
            return <th scope={"col"}
                       key={el.person_id}
                       className={`col-min-150 col-p${index}`}
            >
              <Link to={"/reports/competence_map/" + el.person_id} target="_blank">
                {el.last_name + " " + el.first_name}
              </Link>
              <br/>
              <small>{el.profession.name}</small>
              <br/>
              <small>{el.grade.name}</small>
            </th>
          })
        }
      </tr>
      </thead>
    )
  }
}

class WeakLinkTable extends React.PureComponent {

  constructor(props) {
    super(props);
    this.fillPersonsWeight = this.fillPersonsWeight.bind(this);
    this.fillPersonsComps = this.fillPersonsComps.bind(this);
  }

  fillPersonsWeight() {
    return this.props.data.map((el, index) => {
      return <td className={`col-min-150 col-p${index}`} key={"_" + el.person_id.slice(-12)}>
        <AssessmentChart percent={this.props.persons_weights[el.person_id]}/>
      </td>
    })
  }

  fillPersonsComps() {
    return this.props.data.map((el, index) => {
      return <td className={`col-min-150 col-p${index}`} key={"_" + el.person_id.slice(-12)}>
        {this.props.persons_comps[el.person_id]}
      </td>
    })
  }

  render() {
    return (
      <Table size={"sm"} hover responsive>
        <WeakLinkTableHeader
          data={this.props.data}
        />
        <tbody>

        <tr>
          <td className="col-max-250 font-weight-bold">Количество уникальных навыков</td>
          {this.fillPersonsComps()}
        </tr>

        <tr>
          <td className="col-max-250 font-weight-bold">Вес сотрудника в команде</td>
          {this.fillPersonsWeight()}
        </tr>

        {
          this.props.categories.map((el) => {
            return <WeakLinkTableRow
              key={el.id}
              data={el}
              assessments={this.props.data}
              parentIsOpen={true}
            />;
          })
        }
        </tbody>
      </Table>
    )
  }
}

class WeakLink extends React.Component {

  constructor(props) {
    super(props);

    this.successSelectPerson = this.successSelectPerson.bind(this);
    this.buildNewReport = this.buildNewReport.bind(this);
    this.buildTeamCompetenceReport = this.buildTeamCompetenceReport.bind(this);
    this.buildAdaptationReport = this.buildAdaptationReport.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
  }

  successSelectPerson() {
    this.props.getReportAction()
  }

  checkPermission() {
    if (!(BILLING_SERVICE_REPORT_WEAK_LINK in this.props.reports.list)) {
      this.props.showMainErrorAction('Отчет Слабое звено недоступен. Обратитесь в поддержку.');
      this.props.history.goBack();
    }

    if (this.props.location && this.props.location.state && this.props.location.state.build) {
      this.successSelectPerson();
    } else if (!this.props.report.isBuilt) {
      this.buildNewReport();
    }
  }

  componentDidMount() {

    setTimeout(this.checkPermission, 1)

  }

  buildNewReport() {
    this.props.reportIsBuiltClearAction();
    this.props.openSelectPersonPanelAction();
  }

  buildTeamCompetenceReport() {
    this.props.pushPersonIdsTeamCompetenceAction(this.props.report.selected);
    this.props.history.push("/reports/team_competence", {build: true});
  }

  buildAdaptationReport() {

  }

  render() {

    const {report, reports} = this.props;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>{reports.list[BILLING_SERVICE_REPORT_WEAK_LINK].name}</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button className="col cl-btn-def-1"
                      outline
                      onClick={report.isBuilt ? this.buildNewReport : this.successSelectPerson}
                      disabled={report.isBuilt ? false : !report.selected.length || report.isFetching}>
                {report.isBuilt ? "Построить новый отчет" : "Построить отчет"}
              </Button>
            </div>
          </div>
        </div>

        {/* select person for report panel */}
        <SelectPersonPanel
          togglePersonHandler={this.props.togglePersonAction}
          selected={this.props.report.selected}
          only_one={false}
          isOpen={!report.isBuilt}
        />


        <div className="rep-result">
          <ProgressLine height={"2px"} hidden={!report.isFetching}/>

          {
            report.error ?
              <div className="bg-danger text-white">{report.error || "Ошибка при формировании отчета"}</div>
              : null
          }

          {/**/}
          {
            report.isBuilt ?
              <WeakLinkTable
                categories={report.categories}
                data={report.data}
                persons_weights={report.persons_weights}
                skills_sums={report.skills_sums}
                persons_comps={report.persons_comps}
              /> :
              null
          }
        </div>

      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    report: store.report_weak_link,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showMainErrorAction: (message) => dispatch(setMainError(true, message)),
    getReportAction: () => dispatch(getReport()),

    togglePersonAction: (person_id) => dispatch(togglePerson(person_id)),

    openSelectPersonPanelAction: () => dispatch(openPanel()),

    reportIsBuiltClearAction: () => dispatch(reportIsBuiltClear()),

    pushPersonIdsTeamCompetenceAction: (pids) => dispatch(pushPersonIdsTeamCompetence(pids)),
    pushPersonIdsWeakLinkAction: (pids) => dispatch(pushPersonIdsWeakLink(pids))
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeakLink));