import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SET_REDIRECT_AFTER_LOGIN
} from "../actions/AuthAction";

export const initialState = {
  login: false,
  user: {
    email: null,
    id: null
  },

  redirect: null
};

export function authReducer(state = initialState, action) {

  switch (action.type) {

    case AUTH_LOGIN:
      return {...state, login: true, user: {...action.payload}};
    case AUTH_LOGOUT:
      return {...state, login: false, user: {email: null, id: null}};

    case SET_REDIRECT_AFTER_LOGIN:
      return {...state, redirect: action.payload};

    default:
      return state;
  }

}