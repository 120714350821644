import React from "react";
import './ErrorModalPopup.css'
import {Modal, ModalBody} from "reactstrap";
import connect from "react-redux/es/connect/connect";
import {setMainError} from "../actions/MainAction";


class GlobalErrorPopup extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.setMainErrorAction(false, null);
  }


  render() {
    const {global_error} = this.props;

    return (
      <Modal
        size="sm" className="position-absolute error-position"
        backdrop="static"
        fade={false}
        isOpen={global_error.show}
        toggle={this.toggle}>
        <ModalBody className="modal-body rounded bg-danger text-white error-text">
          <button type="button" className="close position-absolute error-close-button" onClick={this.toggle}>
            <span aria-hidden="true">&times;</span>
          </button>
          {global_error.message}
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = store => {
  return {
    global_error: store.main.global_error
  }
};


const mapDispatchToProps = dispatch => {
  return {
    setMainErrorAction: (show, message) => dispatch(setMainError(show, message))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalErrorPopup);