import {
  GET_PERSON_INFO_MODAL_ERROR,
  GET_PERSON_INFO_MODAL_REQUEST,
  GET_PERSON_INFO_MODAL_SUCCESS,
  PERSON_INFO_MODAL_OPEN,
  PERSON_INFO_MODAL_TOGGLE,
  PIM_GET_GRADE_ERROR,
  PIM_GET_GRADE_REQUEST,
  PIM_SET_GRADE_ERROR,
  PIM_SET_GRADE_REQUEST,
  PIM_SET_GRADE_SUCCESS,
  PIM_GET_GRADE_SUCCESS,
  PIM_GRADE_TOGGLE,
  PIM_GET_PROFESSION_ERROR,
  PIM_GET_PROFESSION_REQUEST,
  PIM_SET_PROFESSION_ERROR,
  PIM_SET_PROFESSION_REQUEST,
  PIM_SET_PROFESSION_SUCCESS,
  PIM_GET_PROFESSION_SUCCESS,
  PIM_PROFESSION_TOGGLE
} from "../actions/PersonInfoModalAction";

export const initialState = {
  personId: false,
  isOpen: false,
  isFetching: false,

  data: {},

  error: null,
  message: null,

  profession: {
    selected: {},
    list: [],
    isOpen: false,
    isFetching: false
  },

  grade: {
    selected: {},
    list: [],
    isOpen: false,
    isFetching: false
  },
};


export function personInfoModalReducer(state = initialState, action) {
  switch (action.type) {

    case PERSON_INFO_MODAL_OPEN:
      return {...state, personId: action.payload, isOpen: true, data: {}};
    case PERSON_INFO_MODAL_TOGGLE:
      return {...state, isOpen: !state.isOpen};

    case GET_PERSON_INFO_MODAL_REQUEST:
      return {...state, isFetching: true};
    case GET_PERSON_INFO_MODAL_SUCCESS:
      return {
        ...state, isFetching: false,
        data: action.payload,
        profession: {...state.profession, selected: action.payload.profession},
        grade: {...state.grade, selected: action.payload.grade},
      };
    case GET_PERSON_INFO_MODAL_ERROR:
      return {...state, isFetching: false, error: action.payload};

    case PIM_PROFESSION_TOGGLE:
      return {...state, profession: {...state.profession, isOpen: !state.profession.isOpen}};
    case PIM_GRADE_TOGGLE:
      return {...state, grade: {...state.grade, isOpen: !state.grade.isOpen}};


    case PIM_GET_PROFESSION_REQUEST:
      return {...state, profession: {...state.profession, isFetching: true}};
    case PIM_GET_PROFESSION_SUCCESS:
      return {...state, profession: {...state.profession, isFetching: false, list: action.payload}};
    case PIM_GET_PROFESSION_ERROR:
      return {...state, profession: {...state.profession, isFetching: false}};

    case PIM_GET_GRADE_REQUEST:
      return {...state, grade: {...state.grade, isFetching: true}};
    case PIM_GET_GRADE_SUCCESS:
      return {...state, grade: {...state.grade, isFetching: false, list: action.payload}};
    case PIM_GET_GRADE_ERROR:
      return {...state, grade: {...state.grade, isFetching: false}};

    case PIM_SET_PROFESSION_REQUEST:
      return {...state, isFetching: true};
    case PIM_SET_PROFESSION_SUCCESS:
      return {...state, isFetching: false, profession: {...state.profession, selected: action.payload}};
    case PIM_SET_PROFESSION_ERROR:
      return {...state, isFetching: false, error: action.payload};

    case PIM_SET_GRADE_REQUEST:
      return {...state, isFetching: true};
    case PIM_SET_GRADE_SUCCESS:
      return {...state, isFetching: false, grade: {...state.grade, selected: action.payload}};
    case PIM_SET_GRADE_ERROR:
      return {...state, isFetching: false, error: action.payload};

    default:
      return state;
  }
}