import React from "react";
import './CompetenceSkillsChart.css';
import {round2} from "../../../commons/Commons";
import SkillsChart from "../SkillsChart";

class CompetenceSkillsChart extends React.Component {

  constructor(props) {
    super(props);
    this.getSkillsCharts = this.getSkillsCharts.bind(this);
  }

  getSkillsCharts() {
    if (!this.props.data.child_skills) {
      return null;
    }

    return this.props.data.child_skills.map((ck_sk) => {
      return <SkillsChart
        key={ck_sk.id}
        assessment={round2(ck_sk.assessment_percent) / 10}
        name={ck_sk.name}
        percent={Math.round(ck_sk.assessment_percent)}
      />
    })
  }

  render() {
    return (
      <div className="row justify-content-center comp-charts">
        <div className="col-12 col-xl-6">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="align-middle text-center comp-chart-header">
                  <h5>{this.props.data.name}</h5>
                </div>
                {/*competence chart*/}
                <SkillsChart
                  assessment={round2(this.props.data.assessment_percent) / 10}
                  name={this.props.data.name}
                  percent={Math.round(this.props.data.assessment_percent)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col border-bottom">
                <p className="text-left">
                  <small className="text-muted">Навыки компетенции</small>
                </p>
                {
                  this.getSkillsCharts()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CompetenceSkillsChart;