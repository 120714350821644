import React from "react";


export default class ProgressLine extends React.Component {
  render() {
    return (
      <div style={{height: this.props.height}}>
        <div className={"loader"} hidden={this.props.hidden}/>
      </div>
    )
  }
}