import React, {Component, Fragment} from 'react';
import Navigation from "../components/Navigation";

import {withCookies} from "react-cookie"

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckSquare, faCircle, faDotCircle, faEdit, faEnvelopeOpen, faMinusSquare, faPlusSquare, faSquare, faTrashAlt, faUser} from '@fortawesome/free-regular-svg-icons'

import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faEllipsisH,
  faHatWizard,
  faInfoCircle,
  faPlus,
  faSignOutAlt,
  faSpinner,
  faStroopwafel,
  faUserPlus,
  faUsers,
  faCog,
  faTimesCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

import {connect} from 'react-redux';
import ErrorModalPopup from "../components/GlobalErrorPopup";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import PersonPanel from "./PersonPanel";
import InvitePanel from "./InvitePanel";
import ExpertPanel from "./ExpertPanel";
import TeamPanel from "./teams/TeamPanel";
import VacancyPanel from "./VacancyPanel";
import Login from "./Login";
import {setRedirectAfterLogin} from "../actions/AuthAction";
import CompetenceMap from "./reports/ByPerson/CompetenceMap";
import TeamCompetenceMap from "./reports/ByTeam/TeamCompetenceMap";
import WeakLink from "./reports/WeakLink/WeakLink";
import Adaptation from "./reports/Adaptation/Adaptation";
import TeamCompetence from "./reports/TeamCompetence/TeamCompetence";

library.add(
  faStroopwafel,
  faSignOutAlt,
  faUser,
  faUsers,
  faHatWizard,
  faEnvelopeOpen,
  faCaretDown,
  faEllipsisH,
  faUserPlus,
  faSpinner,
  faTrashAlt,
  faEdit,
  faPlus,
  faCaretUp,
  faCheck,
  faInfoCircle,
  faSquare,
  faCheckSquare,
  faCircle,
  faDotCircle,
  faMinusSquare,
  faPlusSquare,
  faCog,
  faTimesCircle,
  faCheckCircle
);


class Main extends Component {

  componentWillMount() {
    if (window.location.pathname !== '/') {
      this.props.setRedirectAfterLoginAction(window.location.pathname)
    }
  }

  render() {
    const {auth} = this.props;

    return (
      <BrowserRouter>
        <Fragment>
          {/*nav_header*/}
          <Navigation/>

          {/*global_error_popup*/}
          <ErrorModalPopup/>

          <div className="container cl-def-1" style={{paddingTop: "25px"}}>
            <Switch>

              <Route path="/invites" render={() => auth.login ? <InvitePanel/> : <Redirect to="/login"/>}/>
              <Route path="/experts" render={() => auth.login ? <ExpertPanel/> : <Redirect to="/login"/>}/>
              <Route path="/teams" render={() => auth.login ? <TeamPanel/> : <Redirect to="/login"/>}/>
              <Route path="/vacancies" render={() => auth.login ? <VacancyPanel/> : <Redirect to="/login"/>}/>

              <Route exact path="/reports/competence_map" render={() => auth.login ? <CompetenceMap/> : <Redirect to="/login"/>}/>
              <Route path="/reports/competence_map/:person_id([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})" render={(props) => auth.login ? <CompetenceMap {...props}/> : <Redirect to="/login"/>}/>
              <Route exact path="/reports/team_competence_map" render={() => auth.login ? <TeamCompetenceMap/> : <Redirect to="/login"/>}/>
              <Route exact path="/reports/team_competence" render={() => auth.login ? <TeamCompetence/> : <Redirect to="/login"/>}/>

              <Route exact path="/reports/adaptation" render={() => auth.login ? <Adaptation/> : <Redirect to="/login"/>}/>
              <Route exact path="/reports/weak_link" render={() => auth.login ? <WeakLink/> : <Redirect to="/login"/>}/>

              <Route exact path="/login" render={() => auth.login ? <Redirect to="/"/> : <Login/>}/>

              <Route path="/" render={() => auth.login ? <PersonPanel/> : <Redirect to="/login"/>}/>

            </Switch>
          </div>
        </Fragment>
      </BrowserRouter>
    );
  }
}

// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    auth: store.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setRedirectAfterLoginAction: (path) => dispatch(setRedirectAfterLogin(path)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Main));
