import {REPORT_ADAPT_ERROR, REPORT_ADAPT_IS_BUILT, REPORT_ADAPT_REQUEST, REPORT_ADAPT_SUCCESS, REPORT_ADAPT_TOGGLE_PERSON,} from "../../actions/reports/AdaptationAction";

export const initialState = {
  isBuilt: false,
  isFetching: false,

  selected: [],

  categories: [],
  data: [],
  skills_assessors: {},

  error: null,
  message: null
};


export function adaptationReducer(state = initialState, action) {

  switch (action.type) {
    case REPORT_ADAPT_REQUEST:
      return {...state, isFetching: true, isBuilt: false, error: null,};
    case REPORT_ADAPT_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        data: action.payload.data,
        skills_assessors: action.payload.skills_assessors,
        isFetching: false,
        isBuilt: true,
        error: null,
      };
    case REPORT_ADAPT_ERROR:
      return {...state, isFetching: false, isBuilt: false, error: action.payload};

    case REPORT_ADAPT_IS_BUILT:
      return {...state, isBuilt: action.payload, error: null,};

    case REPORT_ADAPT_TOGGLE_PERSON:
      return {
        ...state, selected: (function () {
          return (
            state.selected.includes(action.payload) ? [] : [action.payload]
          );
        })(),
      };

    default:
      return state;
  }
}