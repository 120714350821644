import React from "react";
import './AssessmentChart.css'

export default class AssessmentChart extends React.PureComponent {

  constructor(props) {
    super(props);
    this.getColor = this.getColor.bind(this);
  }

  getColor() {
    let val = parseInt(this.props.percent);
    if (val < 5) {
      return ['#cccccc', '#cccccc'];
    } else if (val >= 5 && val < 25) {
      return ['OrangeRed', '#ffdacc'];
    } else if (val >= 25 && val < 45) {
      return ['orange', '#ffedcc'];
    } else if (val >= 45 && val < 65) {
      return ['yellow', 'lightYellow'];
    } else if (val >= 65 && val < 85) {
      return ['LimeGreen', '#d6f5d6 '];
    }

    return ['LimeGreen', '#d6f5d6 '];
  }

  render() {
    return (
      <div className="assessment-block">
        <div className="assessment-progress"
             data-toggle="tooltip"
             data-placement="top"
             title={this.props.percent > -0.1 ? parseInt(this.props.percent) + "%" : "Без оценки"}>

        <span style={{width: (this.props.percent > -0.1 ? this.props.percent + "%" : "100%"), background: this.getColor()[0],}}>
          {this.props.percent > -0.1 ? "" : "Без оценки"}
        </span>
        </div>
        <span className="assessment-percent">{this.props.percent > -0.1 ? (parseInt(this.props.percent) + "%") : ""}</span>
      </div>
    )
  }
}