import {GET_INVITES_ERROR, GET_INVITES_REQUEST, GET_INVITES_SUCCESS} from "../actions/InvitePanelAction";

export const initialState = {
  list: [],
  isFetching: false,
  limit: 10,
  offset: 0,
  error: null,
  message: null
};

export function invitePanelReducer(state = initialState, action) {

  switch (action.type) {

    case GET_INVITES_REQUEST:
      return {...state, isFetching: true, error: null};
    case GET_INVITES_SUCCESS:
      return {...state, isFetching: false, error: null, list: action.payload};
    case GET_INVITES_ERROR:
      return {...state, isFetching: false, error: action.payload};

    default:
      return state;
  }
}