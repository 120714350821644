import React, {Fragment} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from "react-router-dom";

import {
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem
} from 'reactstrap'
import connect from "react-redux/es/connect/connect";

import './navigation.css'
import {getPersons} from "../actions/PersonPanelAction";
import {logoutAction} from "../actions/LoginAction";
import {getTeams} from "../actions/TeamPanelAction";
import {getVacancies} from "../actions/VacancyPanelAction";
import {getInvites} from "../actions/InvitePanelAction";
import {getExperts} from "../actions/ExpertPanelAction";
import {withCookies} from "react-cookie";
import {getBillingReports} from "../actions/MainAction";
import {
  BILLING_SERVICE_REPORT_ADAPTATION,
  BILLING_SERVICE_REPORT_BY_PERSON,
  BILLING_SERVICE_REPORT_BY_TEAM,
  BILLING_SERVICE_REPORT_TEAM_COMPETENCE,
  BILLING_SERVICE_REPORT_WEAK_LINK
} from "../commons/Settings";
import Spinner from "../commons/Spinner";


class ReportDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getReportsLinks = this.getReportsLinks.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  getReportsLinks() {
    let links = [];

    let reps = this.props.reports.list;

    if (BILLING_SERVICE_REPORT_BY_PERSON in reps) {
      links.push(<Link key={reps[BILLING_SERVICE_REPORT_BY_PERSON].id}
                       className="dropdown-item"
                       to="/reports/competence_map"
                       onClick={this.toggle}>
        {reps[BILLING_SERVICE_REPORT_BY_PERSON].name}
      </Link>);
    }
    if (BILLING_SERVICE_REPORT_BY_TEAM in reps) {
      links.push(<Link key={reps[BILLING_SERVICE_REPORT_BY_TEAM].id}
                       className="dropdown-item"
                       to="/reports/team_competence_map"
                       onClick={this.toggle}>
        {reps[BILLING_SERVICE_REPORT_BY_TEAM].name}
      </Link>);
    }
    if (BILLING_SERVICE_REPORT_TEAM_COMPETENCE in reps) {
      links.push(<Link key={reps[BILLING_SERVICE_REPORT_TEAM_COMPETENCE].id}
                       className="dropdown-item" to="/reports/team_competence" onClick={this.toggle}>
        {reps[BILLING_SERVICE_REPORT_TEAM_COMPETENCE].name}
      </Link>);
    }
    if (BILLING_SERVICE_REPORT_ADAPTATION in reps) {
      links.push(<Link key={reps[BILLING_SERVICE_REPORT_ADAPTATION].id}
                       className="dropdown-item" to="/reports/adaptation" onClick={this.toggle}>
        {reps[BILLING_SERVICE_REPORT_ADAPTATION].name}
      </Link>);
    }
    if (BILLING_SERVICE_REPORT_WEAK_LINK in reps) {
      links.push(<Link key={reps[BILLING_SERVICE_REPORT_WEAK_LINK].id}
                       className="dropdown-item" to="/reports/weak_link" onClick={this.toggle}>
        {reps[BILLING_SERVICE_REPORT_WEAK_LINK].name}
      </Link>);
    }
    return links;
  }

  render() {

    const {reports} = this.props;

    return (
      <Dropdown nav inNavbar isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle nav caret>
          Отчеты{" "}{reports.is_fetching ? <Spinner/> : null}
        </DropdownToggle>
        <DropdownMenu right>
          {this.getReportsLinks()}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

class PersonDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Dropdown nav inNavbar isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle nav caret>
          Сотрудники
        </DropdownToggle>
        <DropdownMenu right>
          <Link className="dropdown-item" to="/" onClick={this.toggle}>Список сотрудников</Link>
          <Link className="dropdown-item" to="/invites" onClick={this.toggle}>Приглашения</Link>

          {/*<Link className="dropdown-item" to="/experts" onClick={this.toggle}>Эксперты</Link>*/}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

class Navigation extends React.Component {

  constructor(props) {
    super(props);

    this.navToggleler = this.navToggleler.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  navToggleler() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {

    const {auth, reports} = this.props;

	//	Отчеты не загружены, а пользователь зашел. Отправим запрос
	if ( auth.login && ! reports.is_loaded && ! reports.is_fetching ){
		this.props.getBillingReportsAction();
	}

    return (
      <Navbar light expand="md" className="border-bottom custom-nav">
        <NavbarBrand className="mr-auto cl-def-1" href="/">
          <img src="https://api.cloveri.com/media/logo/logo_main.svg" width="160px" className="d-inline-block align-top" alt=""/>
        </NavbarBrand>

        <NavbarToggler className="mr-2" onClick={this.navToggleler}/>

        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar className="mr-auto">
            {auth.login ? (
                <Fragment>
                  {/*PersonPanel*/}
                  <PersonDropdown/>

                  {/*TeamPanel*/}
                  <NavItem><Link className="nav-link" to="/teams" onClick={this.props.getTeamsAction}>Команды</Link></NavItem>

                  {/*VacancyPanel*/}
                  <NavItem><Link className="nav-link" to="/vacancies" onClick={this.props.getVacanciesAction}>Вакансии</Link></NavItem>

                  {/*ReportPanel*/}
                  <ReportDropdown reports={reports}/>
                </Fragment>
              ) :
              null}
          </Nav>
          <Nav navbar>
            {
              auth.login ?
                <Fragment>
                  <NavItem className="navbar-text">
                    {auth.user.email}
                  </NavItem>
                  <NavItem>
                    <button className="btn btn-link cl-def-1" onClick={this.props.logoutAction}>
                      Выход <FontAwesomeIcon icon="sign-out-alt"/>
                    </button>
                  </NavItem>
                </Fragment> : null
            }
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

const mapStateToProps = store => {
  return {
    auth: store.auth,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logoutAction: () => dispatch(logoutAction()),

    getPersonsAction: () => dispatch(getPersons()),
    getTeamsAction: () => dispatch(getTeams()),
    getVacanciesAction: () => dispatch(getVacancies()),
    getInvitesAction: () => dispatch(getInvites()),
    getExpertsAction: () => dispatch(getExperts()),

    getBillingReportsAction: () => dispatch(getBillingReports()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Navigation));