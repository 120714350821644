import {CORP_GRADES_URL, CORP_PERSONS_LIST_URL, CORP_TEAMS_URL, PROFESSIONS_LIST_URL} from "../../commons/Routing";
import {get, post} from "../../commons/Commons";

export const SELECT_PERSON_PANEL_ERROR = "SELECT_PERSON_PANEL_ERROR";
export const SELECT_PERSON_PANEL_MESSAGE = "SELECT_PERSON_PANEL_MESSAGE";

export const SELECT_PERSON_PANEL_GET_PERSONS_REQUEST = "SELECT_PERSON_PANEL_GET_PERSONS_REQUEST";
export const SELECT_PERSON_PANEL_GET_PERSONS_SUCCESS = "SELECT_PERSON_PANEL_GET_PERSONS_SUCCESS";
export const SELECT_PERSON_PANEL_GET_PERSONS_ERROR = "SELECT_PERSON_PANEL_GET_PERSONS_ERROR";


export const SELECT_PERSON_PANEL_GET_PROFS_REQUEST = "SELECT_PERSON_PANEL_GET_PROFS_REQUEST";
export const SELECT_PERSON_PANEL_GET_PROFS_SUCCESS = "SELECT_PERSON_PANEL_GET_PROFS_SUCCESS";
export const SELECT_PERSON_PANEL_GET_PROFS_ERROR = "SELECT_PERSON_PANEL_GET_PROFS_ERROR";


export const SELECT_PERSON_PANEL_GET_TEAMS_REQUEST = "SELECT_PERSON_PANEL_GET_TEAMS_REQUEST";
export const SELECT_PERSON_PANEL_GET_TEAMS_SUCCESS = "SELECT_PERSON_PANEL_GET_TEAMS_SUCCESS";
export const SELECT_PERSON_PANEL_GET_TEAMS_ERROR = "SELECT_PERSON_PANEL_GET_TEAMS_ERROR";


export const SELECT_PERSON_PANEL_GET_GRADES_REQUEST = "SELECT_PERSON_PANEL_GET_GRADES_REQUEST";
export const SELECT_PERSON_PANEL_GET_GRADES_SUCCESS = "SELECT_PERSON_PANEL_GET_GRADES_SUCCESS";
export const SELECT_PERSON_PANEL_GET_GRADES_ERROR = "SELECT_PERSON_PANEL_GET_GRADES_ERROR";

export const SELECT_PERSON_PANEL_FILTER_SELECT_PROF = "SELECT_PERSON_PANEL_FILTER_SELECT_PROF";
export const SELECT_PERSON_PANEL_FILTER_SELECT_GRADE = "SELECT_PERSON_PANEL_FILTER_SELECT_GRADE";
export const SELECT_PERSON_PANEL_FILTER_SELECT_TEAM = "SELECT_PERSON_PANEL_FILTER_SELECT_TEAM";


const DEFAULT_PAGE_LIMIT = 10;

export function openPanel() {
  return dispatch => {
    dispatch(getPersons());
  };
}

export function getPersons(page) {

  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_GET_PERSONS_REQUEST,
      payload: null,
    });

    page = page || getStore().select_person_panel.page;

    let filter_store = getStore().select_person_panel.filter;
    let filter = {
      prof: filter_store.profession.selected ? filter_store.profession.selected.id : null,
      team: filter_store.team.selected ? filter_store.team.selected.id : null,
      grade: filter_store.grade.selected ? filter_store.grade.selected.id : null,
    };

    post(CORP_PERSONS_LIST_URL, {page: page, limit: DEFAULT_PAGE_LIMIT, filter: filter})
      .done((d) => {
        if (d.status === 'ok') {
          dispatch({
            type: SELECT_PERSON_PANEL_GET_PERSONS_SUCCESS,
            payload: d.data,
          })
        } else {
          dispatch({
            type: SELECT_PERSON_PANEL_GET_PERSONS_ERROR,
            payload: d.message,
          })
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: SELECT_PERSON_PANEL_GET_PERSONS_ERROR,
          payload: error,
        })
      })

  }
}

export function message(msg) {
  return {
    type: SELECT_PERSON_PANEL_MESSAGE,
    payload: msg,
  }
}

export function clearMessage() {
  return {
    type: SELECT_PERSON_PANEL_MESSAGE,
    payload: null,
  }
}

export function error(msg) {
  return {
    type: SELECT_PERSON_PANEL_ERROR,
    payload: msg,
  }
}

export function clearError() {
  return {
    type: SELECT_PERSON_PANEL_ERROR,
    payload: null,
  }
}

export function filterGetProfessions() {

  return (dispatch) => {
    dispatch({
      type: SELECT_PERSON_PANEL_GET_PROFS_REQUEST,
      payload: null,
    });

    get(PROFESSIONS_LIST_URL, {from_corp: 1})
      .done((d) => {
        dispatch({
          type: SELECT_PERSON_PANEL_GET_PROFS_SUCCESS,
          payload: d.results,
        });
      })
      .fail((jqXHR) => {
        dispatch({
          type: SELECT_PERSON_PANEL_GET_PROFS_ERROR,
          payload: jqXHR.responseText,
        });
      })
  }
}

export function filterGetTeams() {
  return dispatch => {

    dispatch({
      type: SELECT_PERSON_PANEL_GET_TEAMS_REQUEST,
      payload: null,
    });

    get(CORP_TEAMS_URL)
      .done((d) => {

        dispatch({
          type: SELECT_PERSON_PANEL_GET_TEAMS_SUCCESS,
          payload: d.results,
        })

      })
      .fail((jqXHR) => {
        dispatch({
          type: SELECT_PERSON_PANEL_GET_TEAMS_ERROR,
          payload: null,
        })
      })

  }
}

export function filterGetGrades() {
  return dispatch => {
    dispatch({
      type: SELECT_PERSON_PANEL_GET_GRADES_REQUEST,
      payload: null,
    });

    get(CORP_GRADES_URL)
      .done((d) => {
        dispatch({
          type: SELECT_PERSON_PANEL_GET_GRADES_SUCCESS,
          payload: d.results,
        });
      })
      .fail((jqXHR) => {
        dispatch({
          type: SELECT_PERSON_PANEL_GET_GRADES_ERROR,
          payload: jqXHR.responseText,
        });
      })
  }
}

export function filterSelectProfession(id, name) {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_PROF,
      payload: {id: id, name: name}
    });

    dispatch(getPersons());
  }
}

export function filterSelectGrade(id, name) {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_GRADE,
      payload: {id: id, name: name}
    });

    dispatch(getPersons());
  }
}

export function filterSelectTeam(id, name) {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_TEAM,
      payload: {id: id, name: name}
    });

    dispatch(getPersons());
  }
}

export function filterSelectProfessionClear() {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_PROF,
      payload: null
    });

    dispatch(getPersons());
  }
}

export function filterSelectGradeClear() {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_GRADE,
      payload: null
    });

    dispatch(getPersons());
  }
}

export function filterSelectTeamClear() {
  return (dispatch, getStore) => {
    dispatch({
      type: SELECT_PERSON_PANEL_FILTER_SELECT_TEAM,
      payload: null
    });

    dispatch(getPersons());
  }
}