import {GET_EXPERTS_ERROR, GET_EXPERTS_REQUEST, GET_EXPERTS_SUCCESS} from "../actions/ExpertPanelAction";

export const initialState = {
  list: [],
  isFetching: false,
  limit: 10,
  offset: 0,
  error: null,
  message: null
};

export function expertPanelReducer(state = initialState, action) {

  switch (action.type) {
    case GET_EXPERTS_REQUEST:
      return {...state, isFetching: true, error: false};
    case GET_EXPERTS_SUCCESS:
      return {...state, isFetching: false, error: false, list: action.payload};
    case GET_EXPERTS_ERROR:
      return {...state, isFetching: false, error: true};
    default:
      return state;
  }
}