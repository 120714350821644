import React from "react";
import {Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import connect from "react-redux/es/connect/connect";
import {
  getPersonInfoGrades,
  getPersonInfoModal,
  getPersonInfoProfessions,
  setNewGrade,
  setNewProfession,
  togglePersonInfoGrade,
  togglePersonInfoModal,
  togglePersonInfoProfession
} from "../actions/PersonInfoModalAction";
import Spinner from "../commons/Spinner";

import './PersonInfoModalForm.css'
import ProgressLine from "../commons/ProgressLine";


class SelectProfession extends React.Component {

  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this)
  }

  getItems() {
    return this.props.list.map((el) => {
      return <DropdownItem
        key={el.id}
        id={el.id}
        onClick={(e) => this.props.setNew(this.props.personId, e.currentTarget.id)}>
        {el.name}
      </DropdownItem>
    })
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle
          caret
          className={`col btn btn-sm ${this.props.name ? "btn-outline-secondary cl-btn-def-1" : "btn-outline-danger"}`}
          tag="button"
          onClick={() => {
            if (!this.props.isOpen) {
              this.props.getList()
            }
          }}>
          {this.props.name ? this.props.name : "профессия не указана"}
        </DropdownToggle>
        <DropdownMenu>
          {
            this.props.isFetching ? <Spinner/> : this.getItems()
          }
        </DropdownMenu>
      </Dropdown>
    )
  }
}

class SelectGrade extends React.Component {

  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this)
  }

  getItems() {
    return this.props.list.map((el) => {
      return <DropdownItem
        key={el.id}
        id={el.id}
        onClick={(e) => this.props.setNew(this.props.personId, e.currentTarget.id)}>
        {el.name}
      </DropdownItem>
    })
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle
          caret
          className={`col btn btn-sm ${this.props.name ? "btn-outline-secondary cl-btn-def-1" : "btn-outline-danger"}`}
          tag="button"
          onClick={() => {
            if (!this.props.isOpen) {
              this.props.getList()
            }
          }}>
          {this.props.name ? this.props.name : "grade не указан"}
        </DropdownToggle>
        <DropdownMenu>
          {
            this.props.isFetching ? <Spinner/> : this.getItems()
          }
        </DropdownMenu>
      </Dropdown>
    )
  }
}

class PersonInfoModalForm extends React.Component {

  render() {

    const {person} = this.props;

    return (
      <Modal size="lg" backdrop="static" fade={false} isOpen={person.isOpen} toggle={this.props.togglePersonInfoModalAction}>
        <ModalHeader toggle={this.props.togglePersonInfoModalAction}>
          Данные персоны {}
        </ModalHeader>
        <ModalBody>
          <ProgressLine height={"2px"} hidden={!person.isFetching}/>

          <Row form>
            <Col md={4}>
              <FormGroup className="form-group-border">
                <Label>Имя</Label>
                <div className="text-muted">{person.data.first_name} {person.data.last_name}</div>
                <div className="text-muted">&nbsp;</div>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-group-border">
                <Label>Регион</Label>
                <div className="text-muted">{person.data.region || "не указан"}</div>
                <div className="text-muted">&nbsp;</div>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-group-border">
                <Label>Контакты</Label>
                <div className="text-muted">{person.data.email || "<email не назначен>"}</div>
                <div className="text-muted">{person.data.phone}&nbsp;</div>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup className="form-group-border">
                <Label>Профессия</Label>
                <SelectProfession
                  personId={person.personId}
                  isOpen={person.profession.isOpen}
                  toggle={this.props.toggleProfessionAction}
                  isFetching={person.profession.isFetching}
                  name={person.profession.selected.name}
                  list={person.profession.list}
                  getList={this.props.getProfessionsAction}
                  setNew={this.props.setNewProfessionAction}
                />
                <small className="text-black-50">Изменить профессию</small>
              </FormGroup>

            </Col>
            <Col md={6}>
              <FormGroup className="form-group-border">
                <Label>Опыт</Label>
                <SelectGrade
                  personId={person.personId}
                  isOpen={person.grade.isOpen}
                  toggle={this.props.toggleGradeAction}
                  isFetching={person.grade.isFetching}
                  name={person.grade.selected.name}
                  list={person.grade.list}
                  getList={this.props.getGradesAction}
                  setNew={this.props.setNewGradeAction}
                />
                <small className="text-black-50">Указать опыт</small>
              </FormGroup>
            </Col>
          </Row>

          {/**/}
          <FormGroup className="form-group-border">
            <Label>Команды</Label>
            <Table borderless size="sm">
              <thead className="border-bottom">
              <tr>
                <th>Название команды</th>
                <th>Описание</th>
              </tr>
              </thead>
              <tbody>
              {
                person.data.teams ?
                  person.data.teams.map((el) => {
                    return <tr key={el.id}>
                      <td>{el.name}</td>
                      <td>{el.description}</td>
                    </tr>
                  }) : null
              }
              </tbody>
            </Table>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button className="cl-btn-def-1" onClick={this.props.togglePersonInfoModalAction}>
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    person: store.person_info_modal,
    global_error: store.main.global_error
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePersonInfoModalAction: () => dispatch(togglePersonInfoModal()),

    getPersonInfoModalAction: (id) => dispatch(getPersonInfoModal(id)),

    toggleProfessionAction: () => dispatch(togglePersonInfoProfession()),
    toggleGradeAction: () => dispatch(togglePersonInfoGrade()),

    getProfessionsAction: () => dispatch(getPersonInfoProfessions()),
    getGradesAction: () => dispatch(getPersonInfoGrades()),

    setNewGradeAction: (person_id, grade_id) => dispatch(setNewGrade(person_id, grade_id)),
    setNewProfessionAction: (person_id, prof_id) => dispatch(setNewProfession(person_id, prof_id)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonInfoModalForm);