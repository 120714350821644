import {post} from "../../commons/Commons";
import {CORP_REPORT_BY_TEAM_URL} from "../../commons/Routing";

export const REPORT_BY_TEAM_REQUEST = "REPORT_BY_TEAM_REQUEST";
export const REPORT_BY_TEAM_SUCCESS = "REPORT_BY_TEAM_SUCCESS";
export const REPORT_BY_TEAM_ERROR = "REPORT_BY_TEAM_ERROR";

export const REPORT_BY_TEAM_IS_BUILT = "REPORT_BY_TEAM_IS_BUILT";

export const REPORT_BY_TEAM_TOGGLE_PERSON = "REPORT_BY_TEAM_TOGGLE_PERSON";
export const REPORT_BY_TEAM_PUSH_PERSONS = "REPORT_BY_TEAM_PUSH_PERSONS";


export function togglePerson(person_id) {
  return {
    type: REPORT_BY_TEAM_TOGGLE_PERSON,
    payload: person_id
  }
}

export function reportIsBuilt() {
  return {
    type: REPORT_BY_TEAM_IS_BUILT,
    payload: true,
  }
}

export function reportIsBuiltClear() {
  return {
    type: REPORT_BY_TEAM_IS_BUILT,
    payload: false,
  }
}

export function getReportByTeam(persons) {

  return (dispatch, getStore) => {
    dispatch({
      type: REPORT_BY_TEAM_REQUEST,
      payload: null,
    });

    persons = persons || getStore().report_by_team.selected || [];

    post(CORP_REPORT_BY_TEAM_URL, {persons: persons})
      .done((d) => {

        if (d.status === 'ok') {
          dispatch({
            type: REPORT_BY_TEAM_SUCCESS,
            payload: d.data,
          });
        } else {
          dispatch({
            type: REPORT_BY_TEAM_ERROR,
            payload: null,
          });
        }
      })
      .fail((jqXHR) => {
        dispatch({
          type: REPORT_BY_TEAM_ERROR,
          payload: null,
        });
      })
  }

}

export function pushPersonIds(pids) {
  return {
    type: REPORT_BY_TEAM_PUSH_PERSONS,
    payload: pids
  }
}