import {
  REPORT_WEAK_LINK_REQUEST,
  REPORT_WEAK_LINK_SUCCESS,
  REPORT_WEAK_LINK_ERROR,
  REPORT_WEAK_LINK_IS_BUILT,
  REPORT_WEAK_LINK_TOGGLE_PERSON,
  REPORT_WEAK_LINK_PUSH_PERSONS,
} from "../../actions/reports/WeakLinkAction";

export const initialState = {
  isBuilt: false,
  isFetching: false,

  selected: [],

  categories: [],
  data: [],
  persons_weights: {},
  persons_comps: {},
  skills_sums: {},

  error: null,
  message: null
};


export function weakLinkReducer(state = initialState, action) {

  switch (action.type) {
    case REPORT_WEAK_LINK_REQUEST:
      return {...state, isFetching: true, isBuilt: false, error: null};
    case REPORT_WEAK_LINK_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        data: action.payload.data,
        persons_weights: action.payload.persons_weights,
        skills_sums: action.payload.skills_sums,
        persons_comps: action.payload.persons_comps,

        isFetching: false,
        isBuilt: true,
        error: null,
      };
    case REPORT_WEAK_LINK_ERROR:
      return {...state, isFetching: false, isBuilt: false, error: action.payload};

    case REPORT_WEAK_LINK_IS_BUILT:
      return {...state, isBuilt: action.payload, error: null,};

    case REPORT_WEAK_LINK_TOGGLE_PERSON:
      return {
        ...state, selected: (function () {
          return (
            state.selected.includes(action.payload) ?
              (function () {
                return state.selected.filter((el) => el !== action.payload)
              })() :
              (function () {
                let n = state.selected.slice();
                n.push(action.payload);
                return n
              })()
          );
        })(),
      };

    case REPORT_WEAK_LINK_PUSH_PERSONS:
      return {
        ...state, selected: action.payload
      };

    default:
      return state;
  }
}