import {
  SELECT_PERSON_PANEL_FILTER_SELECT_GRADE,
  SELECT_PERSON_PANEL_FILTER_SELECT_PROF,
  SELECT_PERSON_PANEL_FILTER_SELECT_TEAM,
  SELECT_PERSON_PANEL_GET_GRADES_ERROR,
  SELECT_PERSON_PANEL_GET_GRADES_REQUEST,
  SELECT_PERSON_PANEL_GET_GRADES_SUCCESS,
  SELECT_PERSON_PANEL_GET_PERSONS_ERROR,
  SELECT_PERSON_PANEL_GET_PERSONS_REQUEST,
  SELECT_PERSON_PANEL_GET_PERSONS_SUCCESS,
  SELECT_PERSON_PANEL_GET_PROFS_ERROR,
  SELECT_PERSON_PANEL_GET_PROFS_REQUEST,
  SELECT_PERSON_PANEL_GET_PROFS_SUCCESS,
  SELECT_PERSON_PANEL_GET_TEAMS_ERROR,
  SELECT_PERSON_PANEL_GET_TEAMS_REQUEST,
  SELECT_PERSON_PANEL_GET_TEAMS_SUCCESS
} from "../../actions/reports/SelectPersonPanelAction";

export const initialState = {

  list: [],
  count: 0,
  page: 1,

  selected: [],

  isFetching: false,
  error: null,
  message: null,

  filter: {
    team: {
      selected: null,
      list: [],
      isFetching: false,
    },
    profession: {
      selected: null,
      list: [],
      isFetching: false,
    },
    grade: {
      selected: null,
      list: [],
      isFetching: false,
    }
  }
};

export function selectPersonPanelReducer(state = initialState, action) {

  switch (action.type) {

    case SELECT_PERSON_PANEL_GET_PERSONS_REQUEST:
      return {...state, isFetching: true, error: null, message: null};
    case SELECT_PERSON_PANEL_GET_PERSONS_SUCCESS:
      return {
        ...state, isFetching: false, error: null, message: null,
        list: action.payload.list,
        count: action.payload.count,
        page: action.payload.page,
      };
    case SELECT_PERSON_PANEL_GET_PERSONS_ERROR:
      return {...state, isFetching: false, message: null, error: action.payload,};


    case SELECT_PERSON_PANEL_FILTER_SELECT_PROF:
      return {...state, filter: {...state.filter, profession: {...state.filter.profession, selected: action.payload}}};
    case SELECT_PERSON_PANEL_FILTER_SELECT_TEAM:
      return {...state, filter: {...state.filter, team: {...state.filter.team, selected: action.payload}}};
    case SELECT_PERSON_PANEL_FILTER_SELECT_GRADE:
      return {...state, error: action.payload, filter: {...state.filter, grade: {...state.filter.grade, selected: action.payload}}};

    case SELECT_PERSON_PANEL_GET_PROFS_REQUEST:
      return {...state, filter: {...state.filter, profession: {...state.filter.profession, isFetching: true}}};
    case SELECT_PERSON_PANEL_GET_PROFS_SUCCESS:
      return {...state, filter: {...state.filter, profession: {...state.filter.profession, isFetching: false, list: action.payload}}};
    case SELECT_PERSON_PANEL_GET_PROFS_ERROR:
      return {...state, error: action.payload, filter: {...state.filter, profession: {...state.filter.profession, isFetching: false}}};

    case SELECT_PERSON_PANEL_GET_TEAMS_REQUEST:
      return {...state, filter: {...state.filter, team: {...state.filter.team, isFetching: true}}};
    case SELECT_PERSON_PANEL_GET_TEAMS_SUCCESS:
      return {...state, filter: {...state.filter, team: {...state.filter.team, isFetching: false, list: action.payload}}};
    case SELECT_PERSON_PANEL_GET_TEAMS_ERROR:
      return {...state, error: action.payload, filter: {...state.filter, team: {...state.filter.team, isFetching: false}}};

    case SELECT_PERSON_PANEL_GET_GRADES_REQUEST:
      return {...state, filter: {...state.filter, grade: {...state.filter.grade, isFetching: true}}};
    case SELECT_PERSON_PANEL_GET_GRADES_SUCCESS:
      return {...state, filter: {...state.filter, grade: {...state.filter.grade, isFetching: false, list: action.payload}}};
    case SELECT_PERSON_PANEL_GET_GRADES_ERROR:
      return {...state, error: action.payload, filter: {...state.filter, grade: {...state.filter.grade, isFetching: false}}};

    default:
      return {...state}
  }
}