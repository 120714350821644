import {
  VACANCY_EDIT_ADD_SKILL_ADD,
  VACANCY_EDIT_ADD_SKILL_SELECT,
  VACANCY_EDIT_ADD_SKILL_TOGGLE,
  VACANCY_EDIT_ADD_SKILL_TOGGLE_SELECT,
  VACANCY_EDIT_CHANGE_DESC,
  VACANCY_EDIT_CHANGE_FROM,
  VACANCY_EDIT_CHANGE_NAME,
  VACANCY_EDIT_CHANGE_SKILLS,
  VACANCY_EDIT_CHANGE_TO,
  VACANCY_EDIT_CLOSE,
  VACANCY_EDIT_DATA_ERROR,
  VACANCY_EDIT_DATA_SUCCESS,
  VACANCY_EDIT_PROF_COMPS,
  VACANCY_EDIT_SELECT_PROFESSION,
  VACANCY_EDIT_SELECT_SKILL_GRADE,
  VACANCY_EDIT_TOGGLE,
  VACANCY_EDIT_TOGGLE_PROFESSION,
  VACANCY_EDIT_TOGGLE_SKILL_GRADE,
} from "../actions/VacancyEditFormAction";

export const initialState = {
  isOpen: false,
  isFetching: false,

  id: null,
  name: null,
  short_description: null,
  profession: { // object
    id: null,
    name: null,
  },

  profession_isOpen: false,
  addSkill: {
    isOpen: false,
    skill_id: null,
    skill_name: null,
    select_isOpen: false,
  },

  salary_from: null,
  salary_to: null,

  skills: null, // list

  error: null,
  prof_comps: [],
};

export function vacancyEditFormReducer(state = initialState, action) {

  switch (action.type) {

    case VACANCY_EDIT_TOGGLE:
      return {...state, isOpen: !state.isOpen};

    case VACANCY_EDIT_CLOSE:
      return {...initialState, isOpen: false};

    case VACANCY_EDIT_TOGGLE_PROFESSION:
      return {...state, professionIsOpen: !state.professionIsOpen};

    case VACANCY_EDIT_SELECT_PROFESSION:
      return {
        ...state,
        professionIsOpen: false,
        profession: {
          ...state.profession,
          id: action.payload.id,
          name: action.payload.name,
        }
      };

    case VACANCY_EDIT_DATA_SUCCESS:
      return {...state, ...action.payload, isOpen: true,};

    case VACANCY_EDIT_DATA_ERROR:
      return {...state, error: action.payload, isOpen: true,};

    case VACANCY_EDIT_CHANGE_NAME:
      return {...state, name: action.payload, error: null};

    case VACANCY_EDIT_CHANGE_DESC:
      return {...state, short_description: action.payload, error: null};

    case VACANCY_EDIT_CHANGE_FROM:
      return {...state, salary_from: action.payload, error: null};

    case VACANCY_EDIT_CHANGE_TO:
      return {...state, salary_to: action.payload, error: null};

    case VACANCY_EDIT_CHANGE_SKILLS:
      return {...state, skills: action.payload, error: null};

    case VACANCY_EDIT_TOGGLE_SKILL_GRADE:
      return {...state, skills: action.payload,};

    case VACANCY_EDIT_SELECT_SKILL_GRADE:
      return {...state, skills: action.payload,};

    case VACANCY_EDIT_ADD_SKILL_TOGGLE:
      return {...state, addSkill: {...state.addSkill, isOpen: !state.addSkill.isOpen}};

    case VACANCY_EDIT_ADD_SKILL_SELECT:
      return {
        ...state, addSkill: {
          ...state.addSkill,
          skill_id: action.payload.skill_id,
          skill_name: action.payload.skill_name
        }
      };

    case VACANCY_EDIT_ADD_SKILL_ADD:
      return {
        ...state, addSkill: {
          isOpen: false,
          skill_id: null,
          skill_name: null,
          select_isOpen: false,
        }
      };

    case VACANCY_EDIT_ADD_SKILL_TOGGLE_SELECT:
      return {...state, addSkill: {...state.addSkill, select_isOpen: !state.addSkill.select_isOpen}};

    case VACANCY_EDIT_PROF_COMPS:
      return {...state, prof_comps: action.payload};

    default:
      return state;
  }
}