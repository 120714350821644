import React from "react";
import connect from "react-redux/es/connect/connect";
import {getReportByTeam, reportIsBuiltClear, togglePerson} from "../../../actions/reports/TeamCompetenceMapAction";
import Spinner from "../../../commons/Spinner";
import {Button} from "reactstrap";
import SelectPersonPanel from "../SelectPersonPanel";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HighchartsMore from "highcharts/highcharts-more";
import {openPanel} from "../../../actions/reports/SelectPersonPanelAction";
import '../report.css';
import TeamSkillsCharts from "./TeamSkillsCharts";
import {withRouter} from "react-router";
import {BILLING_SERVICE_REPORT_BY_TEAM} from "../../../commons/Settings";
import {setMainError} from "../../../actions/MainAction";

HighchartsMore(Highcharts);

class TeamCompetenceMap extends React.Component {

  constructor(props) {
    super(props);

    this.successSelectPerson = this.successSelectPerson.bind(this);
    this.buildNewReport = this.buildNewReport.bind(this);
    this.getTeamCompetenceSkillChart = this.getTeamCompetenceSkillChart.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
  }

  getChartCategories() {

    if (this.props.report.categories) {
      return this.props.report.categories.map((cat) => {
        return cat.name
      })
    }

    return [];
  }

  getChartSeries() {
    if (this.props.report.series) {
      return this.props.report.series;
    }

    return [];
  }

  getChartOptions() {
    return {
      chart: {
        polar: true,
        backgroundColor: '#ffffff',
        type: 'line',
        marginTop: 50,
        marginBottom: 100
      },

      colors: ["#1c9dd9", "#ff8562", "#2b908f", "#f45b5b", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#91e8e1"],

      title: {
        text: '',
        style: {
          fontFamily: 'Rubik',
          fontSize: '1rem',
          color: '#2e3192',
          fontWeight: '300'
        }
      },

      subtitle: {
        text: '',
        style: {
          fontFamily: 'Rubik',
          fontSize: '2rem',
          color: '#2e3192',
          fontWeight: '300'

        }
      },

      pane: {
        size: '100%'
      },

      xAxis: {
        categories: this.getChartCategories(),
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {distance: 20},
        gridLineColor: '#ccc'
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 10,
        gridLineColor: '#ccc'
      },

      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>'
      },

      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 15,
        y: 0,

        itemStyle: {
          fontSize: '14px',
          lineHeight: '1',
          fontWeight: '300',
          fontFamily: "Rubik",
          letterSpacing: '0.5px'
        },

        title: {
          text: '',
          style: {
            fontStyle: 'italic',
            fontSize: '10px',
            color: '#666'
          }
        }
      },

      navigation: {buttonOptions: {enabled: false}},
      credits: {enabled: false},
      plotOptions: {series: {marker: {enabled: false}}},

      series: this.getChartSeries(),

      responsive: {
        rules: [{
          condition: {maxWidth: 900},
          chartOptions: {
            chart: {style: {backgroundColor: '#000000'}},
            xAxis: {labels: {style: {display: 'none'}}},
            yAxis: {labels: {style: {display: 'none'}}},
            legend: {title: {text: ''}},
            pane: {size: '100%'}
          }
        }]
      }
    };
  }

  successSelectPerson() {
    this.props.getReportByTeamAction()
  }

  checkPermission() {
    if (!(BILLING_SERVICE_REPORT_BY_TEAM in this.props.reports.list)) {
      this.props.showMainErrorAction('Отчет Компетенции команды недоступен. Обратитесь в поддержку.');
      this.props.history.goBack();
    }

    if (this.props.location && this.props.location.state && this.props.location.state.build) {
      this.successSelectPerson();
    } else if (!this.props.report.isBuilt) {
      this.buildNewReport();
    }
  }

  componentDidMount() {

    setTimeout(this.checkPermission, 1)

  }

  buildNewReport() {
    this.props.reportIsBuiltClearAction();
    this.props.openSelectPersonPanelAction();
  }

  getTeamCompetenceSkillChart() {
    if (this.props.report.data && this.props.report.data.length) {
      return this.props.report.data.map((pass) => {
        return <TeamSkillsCharts
          key={pass.person_id}
          data={pass}
        />
      })
    }
  }

  render() {

    const {report, reports} = this.props;

    return (
      <React.Fragment>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>{reports.list[BILLING_SERVICE_REPORT_BY_TEAM] ? reports.list[BILLING_SERVICE_REPORT_BY_TEAM].name : ""}</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              {
                report.isBuilt ?
                  <Button className="col cl-btn-def-1"
                          outline
                          onClick={this.buildNewReport}>
                    Построить новый отчет
                  </Button> :
                  <Button className="col cl-btn-def-1"
                          outline
                          onClick={this.successSelectPerson}
                          disabled={!report.selected.length}>
                    Построить отчет
                  </Button>
              }
            </div>
          </div>
        </div>

        {/* select person for report panel */}
        <SelectPersonPanel
          togglePersonHandler={this.props.togglePersonAction}
          selected={this.props.report.selected}
          only_one={false}
          isOpen={!report.isBuilt}
        />

        <div className="rep-result">
          {report.isFetching ? <h3><Spinner/></h3> : null}

          {
            report.error ?
              <div className="bg-danger text-white">{report.error || "Ошибка при формировании отчета"}</div>
              : null
          }

          <div>
            {/*main chart*/}
            <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} oneToOne={true}/>

            {/**/}
            {this.getTeamCompetenceSkillChart()}
          </div>
        </div>


      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    report: store.report_by_team,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showMainErrorAction: (message) => dispatch(setMainError(true, message)),
    getReportByTeamAction: () => dispatch(getReportByTeam()),
    togglePersonAction: (person_id) => dispatch(togglePerson(person_id)),

    openSelectPersonPanelAction: () => dispatch(openPanel()),

    reportIsBuiltClearAction: () => dispatch(reportIsBuiltClear()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamCompetenceMap));