import {get} from "../commons/Commons";

import {
  CORP_VACANCY_URL,
  getCorpVacancyUrl
} from "../commons/Routing";

export const GET_VACANCIES_REQUEST = "GET_VACANCIES_REQUEST";
export const GET_VACANCIES_SUCCESS = "GET_VACANCIES_SUCCESS";
export const GET_VACANCIES_ERROR = "GET_VACANCIES_ERROR";

export const VACANCY_TOGGLE_REMOVE = "VACANCY_TOGGLE_REMOVE";

const DEFAULT_PAGE_LIMIT = 10;

export function getVacancies(page) {
  return (dispatch, getStore) => {

    dispatch({
      type: GET_VACANCIES_REQUEST,
      payload: null,
    });

    page = page || getStore().vacancy.page;

    get(CORP_VACANCY_URL, {page: page, limit: DEFAULT_PAGE_LIMIT})
      .done((d) => {
        dispatch({
          type: GET_VACANCIES_SUCCESS,
          payload: {
            list: d.results,
            page: Number(page),
            count: Math.ceil(d.count / DEFAULT_PAGE_LIMIT),
          },
        });
      })
      .fail((jqXHR) => {
        dispatch({
          type: GET_VACANCIES_ERROR,
          payload: jqXHR.responseText,
        })
      })
  }
}

export function toggleRemoveVacancy() {
  return {
    type: VACANCY_TOGGLE_REMOVE,
    payload: null,
  }
}

export function toggleVacancy() {
  return {
    type: VACANCY_TOGGLE_REMOVE,
    payload: null,
  }
}