import connect from "react-redux/es/connect/connect";
import React from "react";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {
  addSkillAdd,
  addSkillSelect,
  addSkillSelectToggle,
  addSkillToggle,
  changeDesc,
  changeFrom,
  changeGradeRequiredSkill,
  changeName,
  changeTo,
  close,
  removeSkill,
  save,
  selectGradeSkill,
  selectProfession,
  toggleGradeSkill,
  toggleProfession,
} from "../actions/VacancyEditFormAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class DropdownSelector extends React.PureComponent {

  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this)
  }

  getItems() {
    return this.props.list.map((el) => {
      return <DropdownItem
        key={el.id}
        id={el.id}
        name={el.name}
        onClick={(e) => this.props.selectAction(e.currentTarget.id, e.currentTarget.name)}>
        {el.name}
      </DropdownItem>
    })
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle caret
                        className={`col btn btn-sm ${this.props.name ? "btn-outline-secondary cl-btn-def-1" : "btn-outline-danger"}`}
                        tag="button">
          {this.props.name ? this.props.name : this.empty_text}
        </DropdownToggle>
        <DropdownMenu>{this.getItems()}</DropdownMenu>
      </Dropdown>
    )
  }
}

class SelectProfession extends DropdownSelector {
  empty_text = "профессия не указана";
}

class SelectCompetence extends DropdownSelector {
  empty_text = "выбрать компетенцию";

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle caret
                        className={`btn btn-sm ${this.props.name ? "btn-outline-secondary cl-btn-def-1" : "btn-outline-danger"}`}
                        tag="button">
          {this.props.name ? this.props.name : this.empty_text}
        </DropdownToggle>
        <DropdownMenu>{this.getItems()}</DropdownMenu>
      </Dropdown>
    )
  }
}

class SelectGrade extends DropdownSelector {
  empty_text = "уровень не указан";

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onclick = this.onclick.bind(this);
  }

  toggle(e) {
    this.props.toggle(this.props.owner_id)
  }

  onclick(e) {
    this.props.selectAction(this.props.owner_id, e.currentTarget.id, e.currentTarget.name)
  }

  getItems() {
    return this.props.list.map((el) => {
      return <DropdownItem
        key={el.id}
        id={el.id}
        name={el.name}
        owner_id={this.props.owner_id}
        onClick={this.onclick}>
        {el.name}
      </DropdownItem>
    })
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.toggle}>
        <DropdownToggle caret
                        className={`col btn btn-sm ${this.props.name ? "btn-outline-secondary cl-btn-def-1" : "btn-outline-danger"}`}
                        tag="button">
          {this.props.name ? this.props.name : this.empty_text}
        </DropdownToggle>
        <DropdownMenu>{this.getItems()}</DropdownMenu>
      </Dropdown>
    )
  }
}

class SkillsTableRow extends React.PureComponent {

  constructor(props) {
    super(props);
    this.loadGradeRequired = this.loadGradeRequired.bind(this);
  }

  loadGradeRequired() {
    return this.props.grade_required.map((gl) => {
      return <option key={gl.id} value={gl.id}>
        {gl.description}
      </option>
    })
  }

  render() {
    return (
      <tr>
        {/*навык*/}
        <td>{this.props.name}</td>
        {/*grade*/}
        <td><SelectGrade
          isOpen={this.props.grade_isOpen}
          list={this.props.grades_list}

          toggle={this.props.toggleGradeAction}
          selectAction={this.props.selectGradeAction}

          owner_id={this.props.id}
          name={this.props.grade_name}
        /></td>
        {/*обязательный*/}
        <td>
          <select className="form-control cl-btn-def-1"
                  style={{"height": "30px"}}
                  value={this.props.required}
                  onChange={(e) => {
                    this.props.changeGradeRequiredSkillAction(this.props.id, e.currentTarget.value);
                  }}>
            {this.loadGradeRequired()}
          </select>
        </td>
        {/*опции*/}
        <td>
          <Button size={"sm"} className="cl-btn-def-1" onClick={(e) => {
            this.props.removeSkillAction(this.props.id)
          }}>
            <FontAwesomeIcon icon={['far', "trash-alt"]}/>
          </Button>
        </td>
      </tr>
    )
  }
}

class SkillsTable extends React.PureComponent {

  constructor(props) {
    super(props);
    this.loadSkills = this.loadSkills.bind(this);
  }

  loadSkills() {
    let skills = this.props.skills || {};
    return Object.keys(skills).map((key) => {

      let skill = skills[key];
      return <SkillsTableRow
        key={key}
        id={key}
        name={skill.name}
        grade_id={skill.grade_id}
        grade_name={skill.grade_name}
        grades_list={this.props.grades_list}
        grade_isOpen={skill.grade_isOpen}
        required={skill.required}
        grade_required={this.props.grade_required}

        toggleGradeAction={this.props.toggleGradeAction}
        selectGradeAction={this.props.selectGradeAction}
        removeSkillAction={this.props.removeSkillAction}
        changeGradeRequiredSkillAction={this.props.changeGradeRequiredSkillAction}
      />
    })
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
          <tr>
            <th scope="col">навык</th>
            <th scope="col">уровень</th>
            <th scope="col">обязательный</th>
          </tr>
          </thead>
          <tbody>
          {/**/}
          {this.loadSkills()}
          </tbody>
        </table>
      </div>
    )
  }
}

class VacancyEditForm extends React.PureComponent {
  changeField = (e) => this.props.changeFieldAction(e.currentTarget.field, e.currentTarget.value);

  render() {
    const {data, professions, grades, grade_required, prof_comps} = this.props;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.closeAction} size="lg" backdrop="static" fade={false}>
        <ModalHeader toggle={this.props.closeAction}>
          {`${data.id ? "Изменить" : "Добавить"} вакансию`}
        </ModalHeader>
        <ModalBody>
          <div hidden={!this.props.error} className="alert alert-danger">
            {this.props.error}
          </div>
          <div className="form-group" hidden={!data.id}>
            <label className="">id</label>
            <input className="form-control form-control-sm" value={data.id || ""}/>
            <div className={`${data.is_active ? "text-success" : "text-danger"}`}>
              Вакансия включена :
              {data.is_active ? <FontAwesomeIcon icon="check-circle"/> : <FontAwesomeIcon icon="times-circle"/>}
            </div>
            <div className={`${data.is_valid ? "text-success" : "text-danger"}`}>
              Вакансия валидна :
              {data.is_valid ? <FontAwesomeIcon icon="check-circle"/> : <FontAwesomeIcon icon="times-circle"/>}
            </div>
          </div>
          <div className="form-group">
            <label className="">Наименование</label>
            <input className={`form-control form-control-sm ${data.name ? "" : "border-danger"}`}
                   value={data.name || ""}
                   onChange={(e) => {
                     this.props.changeNameAction(e.currentTarget.value)
                   }}
            />
            {data.name ? <span/> : <small className="text-black-50">Укажите название</small>}
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="">Уровень зп от</label>
                <input type="number"
                       className={`form-control form-control-sm ${data.salary_from ? "" : "border-danger"}`}
                       value={data.salary_from || ""}
                       onChange={(e) => {
                         this.props.changeFromAction(e.currentTarget.value)
                       }}
                />
                {data.name ? <span/> : <small className="text-black-50">Укажите уровень з/п от</small>}
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="">Уровень зп до</label>
                <input type="number"
                       className={`form-control form-control-sm ${data.salary_to ? "" : "border-danger"}`}
                       value={data.salary_to || ""}
                       onChange={(e) => {
                         this.props.changeToAction(e.currentTarget.value)
                       }}
                />
                {data.name ? <span/> : <small className="text-black-50">Укажите уровень з/п до</small>}
              </div>
            </div>
          </div>
          {/*description*/}
          <div className="form-group">
            <label className="">Описание</label>
            <input className="form-control form-control-sm" value={data.short_description || ""} onChange={(e) => {
              this.props.changeDescAction(e.currentTarget.value)
            }}/>
          </div>
          {/*profession*/}
          <div className="form-group">
            <label className="">Профессия</label>
            <div hidden={data.id}>
              <SelectProfession
                isOpen={data.professionIsOpen}
                list={professions.list}

                toggle={this.props.toggleProfessionAction}
                selectAction={this.props.selectProfessionAction}

                owner_id={data.id}
                name={data.profession.name}
              />
              <small className="text-black-50">Выбрать профессию</small>
            </div>
            <div hidden={!data.id}>
              <input className="form-control form-control-sm" value={data.profession.name}/>
            </div>
          </div>
          <div className="form-group" hidden={data.profession.name ? "" : "hidden"}>
            <Button
              className={"col cl-btn-def-1"}
              size={"sm"}
              color="secondary"
              outline
              onClick={this.props.addSkillToggleAction}>
              <FontAwesomeIcon icon="plus"/> Добавить навык
            </Button>
            {/*collapse*/}
            <Collapse isOpen={data.addSkill.isOpen}>
              <div className="form-group" style={{'marginTop': '10px'}}>
                <label className="">Добавить навык в вакансию</label>
                <div className="input-group input-group-sm mb-3">
                  <SelectCompetence
                    isOpen={data.addSkill.select_isOpen}
                    list={prof_comps}

                    toggle={this.props.addSkillSelectToggleAction}
                    selectAction={this.props.addSkillSelectAction}

                    owner_id={data.id}
                    name={data.addSkill.skill_name}
                  />
                  <div className="input-group-append">
                    <Button
                      className={`${data.addSkill.skill_name ? "cl-btn-def-1" : "btn-outline-danger"}`}
                      outline
                      sie={"sm"}
                      onClick={this.props.addSkillAddAction}
                      disabled={!data.addSkill.skill_name}
                    >
                      Добавить
                    </Button>
                  </div>
                </div>
                <small className="text-black-50">выберите навык</small>
              </div>
            </Collapse>

            {/**/}
            <SkillsTable
              skills={data.skills}
              grades_list={grades.list}
              grade_required={grade_required}
              toggleGradeAction={this.props.toggleSkillGradeAction}
              selectGradeAction={this.props.selectSkillGradeAction}
              removeSkillAction={this.props.removeSkillAction}
              changeGradeRequiredSkillAction={this.props.changeGradeRequiredSkillAction}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cl-btn-def-1" onClick={this.props.closeAction}>
            Отмена
          </Button>
          <Button className="cl-btn-def-1" onClick={this.props.saveAction}>
            {data.id ? "Сохранить" : "Добавить"}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = store => {
  return {
    data: store.vacancy_edit,
    error: store.vacancy_edit.error,
    prof_comps: store.vacancy_edit.prof_comps,
    global_error: store.main.global_error,

    professions: store.main.professions,
    grades: store.main.grades,
    competences: store.main.competences,

    grade_required: store.main.grade_required,
  }
};

const mapDispatchToProps = dispatch => {
  return {

    changeNameAction: (field, value) => dispatch(changeName(field, value)),
    changeDescAction: (field, value) => dispatch(changeDesc(field, value)),
    changeFromAction: (field, value) => dispatch(changeFrom(field, value)),
    changeToAction: (field, value) => dispatch(changeTo(field, value)),

    closeAction: () => dispatch(close()),
    saveAction: () => dispatch(save()),

    toggleProfessionAction: () => dispatch(toggleProfession()),
    selectProfessionAction: (id, name) => dispatch(selectProfession(id, name)),

    toggleSkillGradeAction: (owner_id) => dispatch(toggleGradeSkill(owner_id)),
    selectSkillGradeAction: (owner_id, grade_id, name) => dispatch(selectGradeSkill(owner_id, grade_id, name)),

    changeGradeRequiredSkillAction: (owner_id, required) => dispatch(changeGradeRequiredSkill(owner_id, required)),

    addSkillToggleAction: () => dispatch(addSkillToggle()),
    addSkillSelectAction: (id, name) => dispatch(addSkillSelect(id, name)),
    addSkillSelectToggleAction: (id, name) => dispatch(addSkillSelectToggle(id, name)),
    addSkillAddAction: () => dispatch(addSkillAdd()),

    removeSkillAction: (skill_id) => dispatch(removeSkill(skill_id)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacancyEditForm);
