import React from "react";
import SelectPersonPanel from "../SelectPersonPanel";
import ProgressLine from "../../../commons/ProgressLine";
import {getReport, reportIsBuiltClear, togglePerson} from "../../../actions/reports/AdaptationAction";
import {openPanel} from "../../../actions/reports/SelectPersonPanelAction";
import connect from "react-redux/es/connect/connect";
import {Button, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AssessmentChart from "../AssessmentChart";
import '../report.css'
import {Link} from "react-router-dom";
import {pushPersonIds as pushPersonIdsTeamCompetence} from "../../../actions/reports/TeamCompetenceAction";
import {pushPersonIds as pushPersonIdsWeakLink} from "../../../actions/reports/WeakLinkAction";
import {withRouter} from "react-router";
import './Adaptation.css';
import {BILLING_SERVICE_REPORT_ADAPTATION} from "../../../commons/Settings";
import {setMainError} from "../../../actions/MainAction";


class AdaptationCompetenceRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getPersonAssessments = this.getPersonAssessments.bind(this);
    this.getLevelPrefix = this.getLevelPrefix.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  getPersonAssessments() {
    let skill_id = this.props.data.id;

    let result = [];
    // eslint-disable-next-line
    this.props.assessments.slice(0, 1).map((el) => {
      let ass = el.assessments.find(_e => _e.skill_id === skill_id);
      let assessment = ass && ![undefined, null].includes(ass.assessment) ? ass.assessment : 0;

      result.push(<td className="col-min-150" key={el.person_id.slice(0, 8) + '_' + skill_id.slice(-12) + "_" + el.person_id.slice(-12)}>
        {
          assessment >= 0.84 ?
            <strong className="text-success">Наставник не требуется</strong> : (
              (this.props.skills_assessors && this.props.skills_assessors[skill_id] && this.props.skills_assessors[skill_id].length) ?
                this.props.skills_assessors[skill_id].map((_pid) => {

                  let p = this.props.assessments.find(d => d.person_id === _pid);

                  if (!p) {
                    return ""
                  }

                  return <span><Link key={_pid} to={"/reports/competence_map/" + _pid} target="_blank">
                    {p.last_name + " " + p.first_name}
                  </Link><br/></span>

                }) :
                <strong className="text-danger">Нет наставника</strong>
            )
        }
      </td>)
    });

    // eslint-disable-next-line
    this.props.assessments.map((el, index) => {
      let ass = el.assessments.find(_e => _e.skill_id === skill_id);
      result.push(
        <td className={`col-min-150 col-adaptation-p${index}`} key={skill_id.slice(-12) + "_" + el.person_id.slice(-12)}>
          <AssessmentChart percent={(ass && ![undefined, null].includes(ass.assessment)) ? 100 * ass.assessment : -1}/>
        </td>
      )
    });

    return result;
  }

  getLevelPrefix() {
    let pref = "";
    for (let i = 0; i < this.props.data.level; i++) {
      pref += "\u00A0\u00A0"
    }

    return pref;
  }

  render() {
    return (
      <React.Fragment>
        <tr onClick={this.toggle} hidden={!!!this.props.parentIsOpen}>
          <td className="col-max-250">
            <span>{this.getLevelPrefix()}</span>
            {
              !!!this.props.data.skills.length || (this.props.data.level > 2)
                ? null :
                <FontAwesomeIcon icon={['far', `${this.state.isOpen ? "minus-square" : "plus-square"}`]}/>
            }
            <span>{"  "}</span>
            {this.props.data.name}
          </td>
          {this.getPersonAssessments()}
        </tr>

        {
          !!this.props.data.skills.length && (this.props.data.level > 2) && this.state.isOpen
            ? null :
            <React.Fragment>
              {
                this.props.data.skills.map((ch) => {
                  return <AdaptationCompetenceRow
                    key={ch.id}
                    data={ch}
                    assessments={this.props.assessments}
                    skills_assessors={this.props.skills_assessors}
                    parentIsOpen={this.state.isOpen && this.props.parentIsOpen}
                  />;
                })
              }
            </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

class AdaptationCompetenceTableHeader extends React.PureComponent {
  render() {
    return (
      <thead className="">
      <tr>
        {/**/}
        <th scope={"col"} className="col-max-250">Навыки</th>
        <th scope={"col"} className="col-max-250">Наставники</th>
        {/*named cols*/}
        {
          this.props.data.map(function (el, index) {
            return <th scope={"col"}
                       key={el.person_id}
                       className={`col-min-150 col-adaptation-p${index}`}
            >
              <Link to={"/reports/competence_map/" + el.person_id} target="_blank">
                {el.last_name + " " + el.first_name}
              </Link>
              <br/>
              <small>{el.profession.name}</small>
              <br/>
              <small>{el.grade.name}</small>
            </th>
          })
        }
      </tr>
      </thead>
    )
  }
}

class AdaptationCompetenceTable extends React.PureComponent {

  render() {
    return (
      <Table size={"sm"} hover responsive>
        <AdaptationCompetenceTableHeader data={this.props.data}/>
        <tbody>
        {
          this.props.categories.map((el) => {
            return <AdaptationCompetenceRow
              key={el.id}
              data={el}
              assessments={this.props.data}
              skills_assessors={this.props.skills_assessors}
              parentIsOpen={true}
            />;
          })
        }
        </tbody>
      </Table>
    )
  }
}

class Adaptation extends React.Component {


  constructor(props) {
    super(props);

    this.successSelectPerson = this.successSelectPerson.bind(this);
    this.buildNewReport = this.buildNewReport.bind(this);
    this.buildTeamCompetenceReport = this.buildTeamCompetenceReport.bind(this);
    this.buildWeakLinkReport = this.buildWeakLinkReport.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
  }

  successSelectPerson() {
    this.props.getReportAction()
  }

  checkPermission() {
    if (!(BILLING_SERVICE_REPORT_ADAPTATION in this.props.reports.list)) {
      this.props.showMainErrorAction('Отчет Адаптация недоступен. Обратитесь в поддержку.');
      this.props.history.goBack();
    }

    if (!this.props.report.isBuilt) {
      this.buildNewReport();
    }
  }

  componentDidMount() {

    setTimeout(this.checkPermission, 1);

  }

  buildNewReport() {
    this.props.reportIsBuiltClearAction();
    this.props.openSelectPersonPanelAction();
  }

  buildTeamCompetenceReport() {
    let pids = this.props.report.data.map((el) => {
      return el.person_id
    });
    this.props.pushPersonIdsTeamCompetenceAction(pids);
    this.props.history.push("/reports/team_competence", {build: true});
  }

  buildWeakLinkReport() {
    let pids = this.props.report.data.map((el) => {
      return el.person_id
    });
    this.props.pushPersonIdsWeakLinkAction(pids);
    this.props.history.push("/reports/weak_link", {build: true});
  }

  render() {

    const {report, reports} = this.props;

    return (
      <React.Fragment>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>{reports.list[BILLING_SERVICE_REPORT_ADAPTATION] ? reports.list[BILLING_SERVICE_REPORT_ADAPTATION].name : ""}</h2>
            {
              report.isBuilt ? (
                <React.Fragment>
                  <h3>{report.data[0].last_name + " " + report.data[0].first_name}</h3>
                  <h5 className="text-secondary">
                    {`${report.data[0].profession.name} ${report.data[0].grade && report.data[0].grade.name ? "(" + report.data[0].grade.name + ")" : ""}`}
                  </h5>
                </React.Fragment>
              ) : null
            }
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button className="col cl-btn-def-1"
                      outline
                      onClick={report.isBuilt ? this.buildNewReport : this.successSelectPerson}
                      disabled={report.isBuilt ? false : !report.selected.length || report.isFetching}>
                {report.isBuilt ? "Построить новый отчет" : "Построить отчет"}
              </Button>
            </div>
          </div>
        </div>

        {/* select person for report panel */}
        <SelectPersonPanel
          togglePersonHandler={this.props.togglePersonAction}
          selected={this.props.report.selected}
          only_one={true}
          isOpen={!report.isBuilt}
        />

        <div className="rep-result">
          <ProgressLine height={"2px"} hidden={!report.isFetching}/>

          {
            report.error ?
              <div className="bg-danger text-white">{report.error || "Ошибка при формировании отчета"}</div>
              : null
          }

          {/**/}
          {
            report.isBuilt ? <AdaptationCompetenceTable
              categories={report.categories}
              data={report.data}
              skills_assessors={report.skills_assessors}
            /> : null
          }
        </div>


      </React.Fragment>
    )
  }
}

const mapStateToProps = store => {
  return {
    report: store.report_adaptation,
    reports: store.main.billing.reports,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showMainErrorAction: (message) => dispatch(setMainError(true, message)),
    getReportAction: () => dispatch(getReport()),
    togglePersonAction: (person_id) => dispatch(togglePerson(person_id)),
    openSelectPersonPanelAction: () => dispatch(openPanel()),
    reportIsBuiltClearAction: () => dispatch(reportIsBuiltClear()),
    pushPersonIdsTeamCompetenceAction: (pids) => dispatch(pushPersonIdsTeamCompetence(pids)),
    pushPersonIdsWeakLinkAction: (pids) => dispatch(pushPersonIdsWeakLink(pids))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Adaptation));
