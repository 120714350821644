import {post} from "../../commons/Commons";
import {CORP_REPORT_BY_PERSON_URL} from "../../commons/Routing";

export const REPORT_BY_PERSON_REQUEST = "REPORT_BY_PERSON_REQUEST";
export const REPORT_BY_PERSON_SUCCESS = "REPORT_BY_PERSON_SUCCESS";
export const REPORT_BY_PERSON_ERROR = "REPORT_BY_PERSON_ERROR";

export const REPORT_BY_PERSON_TOGGLE_PERSON = "REPORT_BY_PERSON_TOGGLE_PERSON";

export const REPORT_ERROR = 'REPORT_ERROR';
export const REPORT_ERROR_CLEAR = 'REPORT_ERROR_CLEAR';

export const REPORT_IS_BUILT = 'REPORT_IS_BUILT';

export function togglePerson(person_id) {
  return {
    type: REPORT_BY_PERSON_TOGGLE_PERSON,
    payload: person_id,
  }
}

export function reportIsBuilt() {
  return {
    type: REPORT_IS_BUILT,
    payload: true,
  }
}

export function reportIsBuiltClear() {
  return {
    type: REPORT_IS_BUILT,
    payload: false,
  }
}

export function reportError(msg) {
  return {
    type: REPORT_ERROR,
    payload: msg
  }
}

export function reportErrorClear(msg) {
  return {
    type: REPORT_ERROR_CLEAR,
    payload: null
  }
}

export function getReportByPerson(pid) {

  return (dispatch, getStore) => {
    dispatch({
      type: REPORT_BY_PERSON_REQUEST,
      payload: null,
    });

    pid = pid || (getStore().report_by_person.selected ? getStore().report_by_person.selected[0] : null);

    post(CORP_REPORT_BY_PERSON_URL, {person_id: pid})
      .done((d) => {

        if (d.status === 'ok') {
          dispatch({
            type: REPORT_BY_PERSON_SUCCESS,
            payload: d.data,
          });
        }
        else {
          dispatch({
            type: REPORT_BY_PERSON_ERROR,
            payload: d.message,
          });
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: REPORT_BY_PERSON_ERROR,
          payload: error,
        });
      })
  }

}