import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import connect from "react-redux/es/connect/connect";
import {getVacancies, toggleRemoveVacancy, toggleVacancy,} from "../actions/VacancyPanelAction";
import {toggle as toggleEditForm,} from "../actions/VacancyEditFormAction";
import {loadMainCompetences, loadMainGrades, loadMainProfessions,} from "../actions/MainAction"
import {Button, Table} from "reactstrap";
import ProgressLine from "../commons/ProgressLine";
import PaginationNav from "../components/PaginationNav";
import VacancyEditForm from "./VacancyEditForm";

import './PersonPanel.css';

class RemoveVacancyDialog extends React.PureComponent {
  render() {
    return (
      <div>

      </div>
    )
  }
}

const VacancyTableHeader = () => {
  return (
    <tr className="border-bottom">
      <th scope="col">Вакансия</th>
      <th scope="col">Профессия</th>
      <th scope="col">Уровень зп от</th>
      <th scope="col">Уровень зп до</th>
      <th scope="col">Вакансия включена</th>
      <th scope="col">Вакансия валидная</th>
    </tr>
  )
};

class VacancyTableRow extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {

    const {data} = this.props;

    return (
      <tr className="border-bottom">
        <td className="align-middle">
          <button
            style={{margin: "0 5px 0 5px", padding: 0}}
            className="btn btn-link cl-def-1"
            onClick={() => this.props.editAction(this.props.data.id)}
          >
            {data.name}
          </button>
          {
            !data.name ? <FontAwesomeIcon icon="info-circle"/> : null
          }
        </td>
        <td className="align-middle">{data.profession.name}</td>
        <td className="align-middle">{data.salary_from}</td>
        <td className="align-middle">{data.salary_to}</td>
        <td className={`align-middle text-center ${data.is_active ? "text-success" : "text-danger"}`}>
          {
            data.is_active ? <FontAwesomeIcon icon="check-circle"/> : <FontAwesomeIcon icon="times-circle"/>
          }
        </td>
        <td className={`align-middle text-center ${data.is_valid ? "text-success" : "text-danger"}`}>
          {
            data.is_valid ? <FontAwesomeIcon icon="check-circle"/> : <FontAwesomeIcon icon="times-circle"/>
          }
        </td>
      </tr>
    )
  }

}

class VacancyPanel extends React.PureComponent {

  loadVacanciesTable() {
    let result = [];
    for (const vacancy of this.props.vacancy.list) {
      result.push(<VacancyTableRow
        key={vacancy.id}
        data={vacancy}
        editAction={(e) => {
          this.props.toggleEditVacancyFormAction(vacancy.id)
        }}
        removeAction={this.props.toggleRemoveVacancyAction}
      />);
    }
    return result;
  };

  componentDidMount() {
    this.props.getVacanciesAction();

    //load professions, grades
    this.props.loadMainProfessionsAction();
    this.props.loadMainGradesAction();
    this.props.loadMainCompetencesAction();
  }

  render() {

    const {vacancy, vacancy_edit, professions, grades} = this.props;

    return (
      <div className="dashboard-panel">

        <div className="form-row">
          <div className="col-12 col-md-6">
            <h2>Вакансии</h2>
          </div>
          <div className="col-12 col-md-6">
            <div className="float-md-right">
              <Button
                className={"col cl-btn-def-1"}
                color="secondary"
                outline
                onClick={() => {
                  this.props.toggleEditVacancyFormAction(null)
                }}>
                <FontAwesomeIcon icon="plus"/> Добавить вакансию
              </Button>
            </div>
          </div>
        </div>

        {/*edit/add vacancy dialog*/}
        <VacancyEditForm isOpen={vacancy_edit.isOpen}/>

        {/*remove vacancy dialog*/}
        <RemoveVacancyDialog
          isOpen={vacancy.remove.toggle}
          toggle={this.props.toggleRemoveVacancyAction}
          personName={vacancy.remove.name}
          successHandler={this.props.removeVacancyAction}
        />

        {/* main table */}
        <div className="">
          {/**/}
          <ProgressLine height={"2px"} hidden={!vacancy.isFetching}/>

          {/**/}
          <Table className="panel-table" size="sm" responsive={true} borderless={true} hover={true}>
            <thead className="">
            <VacancyTableHeader/>
            </thead>
            <tbody>
            {this.loadVacanciesTable()}
            </tbody>
          </Table>

          {/**/}
          <PaginationNav page={vacancy.page} count={vacancy.count} getPage={this.props.getVacanciesAction}/>
        </div>
      </div>
    )
  }

}

// приклеиваем данные из store
const mapStateToProps = store => {
  return {
    vacancy: store.vacancy,
    vacancy_edit: store.vacancy_edit,
    global_error: store.main.global_error,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getVacanciesAction: (page) => dispatch(getVacancies(page)),

    toggleEditVacancyFormAction: (id) => dispatch(toggleEditForm(id)),
    toggleRemoveVacancyAction: (id, name) => dispatch(toggleRemoveVacancy(id, name)),
    removeVacancyAction: () => dispatch(toggleVacancy()),

    loadMainProfessionsAction: () => dispatch(loadMainProfessions()),
    loadMainGradesAction: () => dispatch(loadMainGrades()),
    loadMainCompetencesAction: () => dispatch(loadMainCompetences()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacancyPanel);
