import * as React from "react";
import {Button, Table} from "reactstrap";
import {openPersonInfoModal} from "../../actions/PersonInfoModalAction";
import {connect} from "react-redux";
import {get, post} from "../../commons/Commons";
import {CORP_TEAMS_MEMBERS_URL, getCorpTeamUrl} from "../../commons/Routing";
import {addMemberOpen, addTeamOpen, forUpdateDel, removeTeamMember, removeTeamOpen} from "../../actions/TeamPanelAction";
import './TeamComponent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgressLine from "../../commons/ProgressLine";
import {withRouter} from "react-router";
import {pushPersonIds as pushPersonIdsTeamCompetenceMap} from "../../actions/reports/TeamCompetenceMapAction";
import {pushPersonIds as pushPersonIdsTeamCompetence} from "../../actions/reports/TeamCompetenceAction";
import {pushPersonIds as pushPersonIdsWeakLink} from "../../actions/reports/WeakLinkAction";
import {filterSelectTeam} from "../../actions/reports/SelectPersonPanelAction";
import {BILLING_SERVICE_REPORT_BY_TEAM, BILLING_SERVICE_REPORT_TEAM_COMPETENCE, BILLING_SERVICE_REPORT_WEAK_LINK} from "../../commons/Settings";

class TeamMemberRow extends React.PureComponent {

  constructor(props) {
    super(props);
    this.deleteMember = this.deleteMember.bind(this);
    this.personInfo = this.personInfo.bind(this);
  }

  deleteMember() {
    this.props.deleteMember(this.props.data.id);
  }

  personInfo() {
    this.props.personInfoAction(this.props.data.id);
  }

  render() {
    return (
      <tr>
        <td className="" onClick={this.personInfo}>
          <button
            style={{margin: "0 5px 0 5px", padding: 0}}
            className="btn btn-link cl-def-1"
            onClick={this.personInfo}>
            {this.props.data.last_name + " " + this.props.data.first_name}
          </button>
        </td>
        <td className="">{this.props.data.email}</td>
        <td className="">{this.props.data.profession_name}</td>
        <td className="">{this.props.data.grade_name}</td>
        <td className="">
          <button data-toggle="tooltip" data-placement="top" title="Удалить из команды"
                  style={{margin: "0 5px 0 5px", padding: 0}}
                  className="btn btn-link cl-def-1"
                  onClick={this.deleteMember}>
            <FontAwesomeIcon icon={['far', "trash-alt"]}/>
          </button>
        </td>
      </tr>
    )
  }
}

class TeamMembersTable extends React.PureComponent {

  teamMembers() {
    return this.props.members.map((el) => {
      return <TeamMemberRow key={el.id} data={el} deleteMember={this.props.deleteMember} personInfoAction={this.props.personInfoAction}/>
    })
  }

  render() {
    return (
      <Table size={"sm"} borderless={true}>
        <thead>
        <tr>
          <th scope="col">Фамилия Имя</th>
          <th scope="col">email</th>
          <th scope="col">Профессия</th>
          <th scope="col">Опыт</th>
          <th scope="col"/>
        </tr>
        </thead>
        <tbody>
        {this.teamMembers()}
        </tbody>
      </Table>
    )
  }
}

class TeamComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      description: null,
      members: [],
      isFetching: false,
      error: null
    };


    this.loadTeamMembers = this.loadTeamMembers.bind(this);
    this.getTeamData = this.getTeamData.bind(this);
    this.editTeam = this.editTeam.bind(this);
    this.removeTeam = this.removeTeam.bind(this);
    this.addMember = this.addMember.bind(this);
    this.reportTeamCompetenceMap = this.reportTeamCompetenceMap.bind(this);
    this.reportTeamCompetence = this.reportTeamCompetence.bind(this);
    this.reportWeakLink = this.reportWeakLink.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.updateTeamData = this.updateTeamData.bind(this);
  }

  componentDidMount() {

  }

  updateTeamData() {
    this.props.forUpdateDelAction(this.props.teamId);
    setTimeout(() => {
      this.loadTeamMembers();
      this.getTeamData();
    }, 1);

    return (
      <div/>
    )
  }

  editTeam() {
    this.props.addTeamOpenAction(this.props.teamId)
  }

  removeTeam() {
    this.props.removeTeamOpenAction(this.props.teamId, this.state.name)
  }

  addMember() {
    this.props.addMemberOpenAction(this.props.teamId)
  }

  getTeamData() {
    this.setState({isFetching: true});

    get(getCorpTeamUrl(this.props.teamId))
      .done((result) => {
        this.setState({
          name: result.name,
          description: result.description,
          isFetching: false
        });
      })
      .fail((jqXHR) => {
        this.setState({isFetching: false, error: jqXHR.responseText});

        this.props.forUpdateDelAction(this.props.teamId);
      })
  }

  loadTeamMembers() {
    post(CORP_TEAMS_MEMBERS_URL, {team_id: this.props.teamId})
      .done((result) => {
        if (result.status === 'ok') {
          this.setState({
            members: result.data,
          })
        } else {
          this.setState({
            error: result.message
          })
        }
      })
      .fail((jqXHR) => {
        this.setState({
          error: jqXHR.responseText,
        })
      })
  }

  reportTeamCompetenceMap() {
    let pids = this.state.members.map((el) => {
      return el.id;
    });

    this.props.filterSelectTeamAction(this.props.teamId, this.state.name);

    this.props.pushPersonIdsTeamCompetenceMapAction(pids);
    this.props.history.push("/reports/team_competence_map", {build: true});
  }

  reportTeamCompetence() {
    let pids = this.state.members.map((el) => {
      return el.id;
    });

    this.props.filterSelectTeamAction(this.props.teamId, this.state.name);

    this.props.pushPersonIdsTeamCompetenceAction(pids);
    this.props.history.push("/reports/team_competence", {build: true});
  }

  reportWeakLink() {
    let pids = this.state.members.map((el) => {
      return el.id;
    });

    this.props.filterSelectTeamAction(this.props.teamId, this.state.name);

    this.props.pushPersonIdsWeakLinkAction(pids);
    this.props.history.push("/reports/weak_link", {build: true});
  }

  deleteMember(personId) {
    this.props.removeMemberAction(this.props.teamId, personId);
  }

  render() {

    const {reports} = this.props;

    return (
      <div className="team-component">
        <div className={"row"}>
          <div className={"col mr-auto text-md-center"}>
            <h3 className="cl-def-1">
              {this.state.name}
            </h3>
          </div>
          <div className={"col-auto"}>
            <button title="Пригласить в команду" className="btn btn-link cl-def-1" onClick={this.addMember}><FontAwesomeIcon icon="user-plus"/></button>
            <button title="Изменить команду" className="btn btn-link cl-def-1" onClick={this.editTeam}><FontAwesomeIcon icon="cog"/></button>
            <button title="Удалить команду" className="btn btn-link cl-def-1" onClick={this.removeTeam}><FontAwesomeIcon icon={['far', "trash-alt"]}/></button>
          </div>
        </div>
        <ProgressLine height={"2px"} hidden={!this.state.isFetching}/>

        <h4 className="text-secondary">{this.state.description ? this.state.description : "12"}</h4>

        <div className="bg-danger text-white" hidden={!this.state.error}>{this.state.error}</div>

        <div className="report-btn-panel">
          <div className="row">
            <div className={"col mr-auto"}>
              {" "}
            </div>
            <div className={"col-auto"}>
              {" "}
              {
                reports.list && reports.list[BILLING_SERVICE_REPORT_BY_TEAM] ?
                  <Button size={"sm"} className="cl-btn-def-1" onClick={this.reportTeamCompetenceMap}>
                    {reports.list[BILLING_SERVICE_REPORT_BY_TEAM] ? reports.list[BILLING_SERVICE_REPORT_BY_TEAM].name : ""}
                  </Button>
                  : null
              }
              {" "}
              {
                reports.list && reports.list[BILLING_SERVICE_REPORT_TEAM_COMPETENCE] ?
                  <Button size={"sm"} className="cl-btn-def-1" onClick={this.reportTeamCompetence}>
                    {reports.list[BILLING_SERVICE_REPORT_TEAM_COMPETENCE] ? reports.list[BILLING_SERVICE_REPORT_TEAM_COMPETENCE].name : ""}
                  </Button>
                  : null
              }
              {" "}
              {
                reports.list && reports.list[BILLING_SERVICE_REPORT_WEAK_LINK] ?
                  <Button size={"sm"} className="cl-btn-def-1" onClick={this.reportWeakLink}>
                    {reports.list[BILLING_SERVICE_REPORT_WEAK_LINK] ? reports.list[BILLING_SERVICE_REPORT_WEAK_LINK].name : ""}
                  </Button>
                  : null
              }
            </div>
          </div>
        </div>

        {this.props.team.forUpdate.includes(this.props.teamId) ? this.updateTeamData() : null}

        <TeamMembersTable
          members={this.state.members}
          isFetching={this.state.isFetching}
          deleteMember={this.deleteMember}
          personInfoAction={this.props.openPersonInfoModalAction}
        />
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    team: store.team,
    reports: store.main.billing.reports,

    editTeam: store.team.editTeam,
    addMember: store.team.addMember,

    global_error: store.main.global_error
  }
};

const mapDispatchToProps = dispatch => {
  return {
    openPersonInfoModalAction: (id) => dispatch(openPersonInfoModal(id)),

    removeTeamOpenAction: (id, name) => dispatch(removeTeamOpen(id, name)),
    addMemberOpenAction: (id) => dispatch(addMemberOpen(id)),
    addTeamOpenAction: (id) => dispatch(addTeamOpen(id)),
    removeMemberAction: (teamId, personId) => dispatch(removeTeamMember(teamId, personId)),


    pushPersonIdsTeamCompetenceMapAction: (pids) => dispatch(pushPersonIdsTeamCompetenceMap(pids)),
    pushPersonIdsTeamCompetenceAction: (pids) => dispatch(pushPersonIdsTeamCompetence(pids)),
    pushPersonIdsWeakLinkAction: (pids) => dispatch(pushPersonIdsWeakLink(pids)),

    forUpdateDelAction: (id) => dispatch(forUpdateDel(id)),

    filterSelectTeamAction: (id, name) => dispatch(filterSelectTeam(id, name)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamComponent));