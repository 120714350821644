import {post} from "../commons/Commons";
import {CORP_EXPERT_LIST_URL} from "../commons/Routing";

export const GET_EXPERTS_REQUEST = "GET_EXPERTS_REQUEST";
export const GET_EXPERTS_SUCCESS = "GET_EXPERTS_SUCCESS";
export const GET_EXPERTS_ERROR = "GET_EXPERTS_ERROR";

export function getExperts() {
  return dispatch => {

    dispatch({
      type: GET_EXPERTS_REQUEST,
      payload: null,
    });

    post(CORP_EXPERT_LIST_URL)
      .done((d) => {

        if (d.status === 'ok') {
          dispatch({
            type: GET_EXPERTS_SUCCESS,
            payload: d.data,
          })
        } else {
          dispatch({
            type: GET_EXPERTS_ERROR,
            payload: d.message,
          })
        }
      })
      .fail((jqXHR) => {
        let error = jqXHR.responseText;
        if (jqXHR.responseJSON && jqXHR.responseJSON.detail) {
          error = jqXHR.responseJSON.detail;
        }

        dispatch({
          type: GET_EXPERTS_ERROR,
          payload: error,
        })
      })

  }
}